<template>
  <div class="text-center mb-lg-2 border border-dark rounded">
    <b-row class="bg-primary text-white font-weight-bold pt-2 pb-2 m-0"
      ><b-col
        >{{ $t('currentstatus.title')
        }}<span v-b-tooltip.hover="tooltipData" class="float-right"
          ><b-icon-info-circle></b-icon-info-circle></span></b-col
    ></b-row>
    <b-row class="bg-light pt-3 pb-3 m-0">
      <b-col>
        <strong>{{ formatNumber(data.intActiveCount) }}</strong>
        {{ $t('currentstatus.active_listings') }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    data: { required: true },
  },
  data() {
    return {
      title: 'Current Status',
      tooltipData: {
        title:
          '<p>Active Listings → Total number of active listings for the selected dealer.</p>',
        html: true,
        placement: 'right',
        variant: 'secondary',
      },
    };
  },
  methods: {
    formatNumber: value => parseInt(value).toLocaleString(),
  },
};
</script>
