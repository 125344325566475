<template>
  <nav>
    <b-row class="pt-3">
      <b-col xs="12" md="6" lg="4">
        <typeahead
          label="Make"
          :selectedItem="selectedManufacturer"
          :items="mfsWithModels"
          :min-query-length="0"
          :show-item-id="true"
          :disable-input="disableInput"
          item-id-key="intID"
          @set-query="handleSetManufacturer"
          @clear-query="handleClearManufacturer"
        />
      </b-col>
      <b-col xs="12" md="6" lg="4">
        <typeahead
          label="Type"
          :items="activeTypes"
          :selectedItem="selectedType"
          :show-item-id="true"
          item-id-key="intID"
          :disable-input="disableInput"
          @set-query="handleSetType"
          @clear-query="handleClearType"
          :min-query-length="0"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" md="12" lg="4">
        <typeahead
          label="Category"
          :items="categories"
          :selectedItem="selectedCategory"
          :show-item-id="true"
          item-id-key="intID"
          item-label-key="text"
          :disable-input="disableInput"
          @set-query="handleSetCategory"
          @clear-query="handleClearCategory"
          :min-query-length="0"
        />
      </b-col>
      <b-col xs="12" md="12" lg="6">
        <b-input-group prepend="Model">
          <b-form-input
            v-model="nameSearch"
            :state="validNameSearch"
            debounce="500"
            :disabled="disableInput"
          ></b-form-input>
          <template v-slot:append>
            <b-button
              variant="success"
              @click="$emit('name-set', nameSearch)"
              :disabled="!validNameSearch"
            >
              <b-icon-search />
            </b-button>
            <b-button
              variant="danger"
              @click="$emit('name-clear')"
              :disabled="!validNameSearch"
            >
              Clear
            </b-button>
          </template>
        </b-input-group>
      </b-col>
      <b-col xs="12" md="2" lg="2">
        <b-form-checkbox
          id="checkbox-1"
          v-model="exactMatch"
          name="checkbox-1"
          value="1"
          unchecked-value="0"
          @change="handleExactMatch"
        >
          Exact Match
        </b-form-checkbox>
      </b-col>
      <b-col
        xs="12"
        lg="6"
        class="d-flex justify-content-lg-end justify-content-md-start"
      >
        <slot name="paginator" />
      </b-col>
    </b-row>
  </nav>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Typeahead from '../Typeahead';
import { config } from '@/common/config';

export default {
  name: 'DatatableFilters',
  components: {
    Typeahead,
  },
  props: {
    initialNameSearch: {
      type: String,
      default: null,
    },
    selectedType: {
      type: Object,
      default: null,
    },
    selectedManufacturer: {
      type: Object,
      default: null,
    },
    selectedCategory: {
      type: Object,
      default: null,
    },
    initialExactMatch: {
      type: Number,
      default: 0,
    },
    disableInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nameSearch: this.initialNameSearch,
      exactMatch: this.initialExactMatch,
      categories: [],
    };
  },
  mounted() {
    this.populateCategories();
  },
  watch: {
    initialNameSearch(val) {
      this.nameSearch = val;
    },
    initialExactMatch(val) {
      this.exactMatch = val;
    },
  },
  methods: {
    ...mapActions('models', {
      loadCategories: 'loadCategories',
    }),
    handleClearManufacturer() {
      this.$emit('mf-clear');
    },
    handleSetManufacturer(mf) {
      this.$emit('mf-set', mf);
    },
    handleClearType() {
      this.$emit('type-clear');
    },
    handleSetType(type) {
      this.$emit('type-set', type);
    },
    handleClearCategory() {
      this.$emit('cat-clear');
    },
    handleSetCategory(type) {
      this.$emit('cat-set', type);
    },
    handleExactMatch(checked) {
      this.$emit('exact-match-set', parseInt(checked));
    },
    async populateCategories() {
      if (!this.categoriesLoaded) {
        await this.loadCategories();
      }
      this.categories = this.availableCategories.map(cat => {
        const category = cat.strCategory.trim();
        const subcategory = cat.strSubcategory.trim();
        const text = `${category} › ${subcategory}`;
        return {
          id: cat.intID,
          text,
          ...cat,
        };
      });

      this.$emit('cat-map-set', this.categories);
    },
  },
  computed: {
    ...mapGetters('models', {
      getManufacturersKeyedById: 'getManufacturersKeyedById',
      getTypesKeyedById: 'getTypesKeyedById',
      getTypesSorted: 'getTypesSorted',
      getMfsSorted: 'getMfsSorted',
      getManufacturerById: 'getManufacturerById',
      getTypeById: 'getTypeById',
    }),
    ...mapState('models', {
      availableCategories: state => state.categories,
      categoriesLoaded: state => state.categoriesLoaded,
    }),
    mfsWithModels() {
      return this.getMfsSorted.filter(mf => mf.models_likely_merges_count > 0);
    },
    activeTypes() {
      return this.getTypesSorted.filter(
        t => t.alias_models_count + t.canonical_models_count > 0,
      );
    },
    validNameSearch() {
      if (this.nameSearch === null || this.nameSearch === '') {
        return null;
      }
      const validRegex = config.modelName.regex;
      return validRegex.test(this.nameSearch);
    },
  },
};
</script>
