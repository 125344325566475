<template>
  <b-container fluid="true">
    <b-row>
      <section class="d-flex w-100 flex-wrap">
        <div class="mr-2 my-2 flex-sm-fill flex-md-fill">
          <typeahead
            label="Make"
            :selectedItem="selectedMf"
            :items="activeMfs"
            :show-item-id="true"
            item-id-key="intID"
            :min-query-length="0"
            :disable-input="!renderTable"
            @set-query="setMf"
            @clear-query="clearMf"
            class="mb-0"
          />
        </div>
        <div class="mr-2 my-2 flex-sm-fill flex-md-fill">
          <typeahead
            label="Type"
            :selectedItem="selectedType"
            :items="activeTypes"
            :min-query-length="0"
            :show-item-id="true"
            :disable-input="!renderTable"
            item-id-key="intID"
            @set-query="setType"
            @clear-query="clearType"
            class="mb-0"
          />
        </div>
        <div class="mr-2 my-2 flex-sm-fill flex-md-fill">
          <typeahead
            label="Client"
            :selectedItem="selectedClient"
            :items="activeClients"
            :min-query-length="0"
            :show-item-id="true"
            :disable-input="!renderTable"
            item-id-key="intID"
            @set-query="setClient"
            @clear-query="clearClient"
            class="mb-0"
          />
        </div>
        <div class="mr-2 my-2 flex-sm-fill flex-md-fill">
          <typeahead
            label="Rep"
            :selectedItem="selectedRep"
            :items="activeReps"
            :min-query-length="0"
            :show-item-id="true"
            :disable-input="!renderTable"
            item-id-key="intID"
            @set-query="setRep"
            @clear-query="clearRep"
            class="mb-0"
            :label-formatter="repLabelFormatter"
          />
        </div>
        <div class="mr-2 my-2 flex-sm-fill flex-md-fill">
          <b-input-group prepend="Model">
            <b-form-input
              v-model="form.nameSearch"
              debounce="500"
              :state="validNameSearch"
              :disabled="!renderTable"
            ></b-form-input>
            <template v-slot:append>
              <b-button
                variant="success"
                @click="setModelSearch"
                :disabled="!validNameSearch"
              >
                <b-icon-search />
              </b-button>
              <b-button
                variant="danger"
                @click="clearModelSearch"
                :disabled="!validNameSearch"
              >
                Clear
              </b-button>
            </template>
          </b-input-group>
        </div>
        <div class="mr-2 my-2 flex-sm-fill flex-md-fill">
          <b-form-checkbox
            id="checkbox-1"
            v-model="exactMatch"
            name="checkbox-1"
            value="1"
            unchecked-value="0"
          >
            Exact Match
          </b-form-checkbox>
        </div>
      </section>
    </b-row>
    <b-row>
      <section class="d-flex justify-content-between w-100 flex-wrap">
        <div class="d-flex justify-content-start">
          <div class="mr-2 my-2">
            <b-input-group>
              <b-button @click="refreshTable">
                <b-icon-arrow-clockwise class="mr-1" />
              </b-button>
            </b-input-group>
          </div>
          <div class="mr-2 my-2">
            <b-input-group>
              <b-button @click="clearFilters" variant="danger">
                <b-icon-x class="mr-1" />
                All
              </b-button>
            </b-input-group>
          </div>
          <div class="mr-2 my-2">
            <b-input-group prepend="Listings:">
              <b-form-select v-model="filter.active" :options="activeStates" />
            </b-input-group>
          </div>
          <div class="mr-2 my-2">
            <b-input-group prepend="Ignored:">
              <b-form-select
                v-model="filter.ignoredState"
                :options="ignoredStates"
              />
            </b-input-group>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <div class="mr-2 my-2">
            <b-button-toolbar class="mb-3">
              <b-input-group prepend="Sort:">
                <b-form-select
                  v-model="selectedSort"
                  :options="sortOptionValues"
                />
              </b-input-group>
            </b-button-toolbar>
          </div>
          <div class="my-2">
            <b-button-toolbar class="mb-3">
              <b-pagination
                class="mb-0"
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
              ></b-pagination>
            </b-button-toolbar>
          </div>
        </div>
      </section>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-content-left">
        <div class="mr-2 my-2">
          <b-input-group>
            <b-button
              v-b-tooltip.hover
              title="Select one or more to ignore them"
              :disabled="isSelectedRowsValid"
              style="height: 40px"
              :hidden="this.filter.ignoredState === 'only'"
              @click="handleIgnoreOrRestoreMultipleListings('ignore')"
            >
              <b-icon-eye-slash class="mr-2" />
              Ignore
            </b-button>
          </b-input-group>
        </div>
        <div class="mr-2 my-2">
          <b-input-group>
            <b-button
              v-b-tooltip.hover
              title="Select one or more to un-ignore them"
              :disabled="isSelectedRowsValid"
              style="height: 40px"
              :hidden="this.filter.ignoredState === 'hide'"
              @click="handleIgnoreOrRestoreMultipleListings('restore')"
            >
              <b-icon-eye class="mr-2" />
              Restore
            </b-button>
          </b-input-group>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-table
        v-if="renderTable"
        ref="table"
        small
        :fields="this.fields"
        :items="dataProvider"
        :busy.sync="isBusy"
        :filter="tableFilter"
        :current-page="currentPage"
        :per-page="perPage"
        :selectable="true"
        select-mode="range"
        @row-selected="onTableRowsSelected"
        show-empty
        hover
        @refreshed="handleTableRefresh"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner small class="align-middle mr-2"></b-spinner>
            <strong class="align-middle">Loading...</strong>
          </div>
        </template>
        <template #cell(url)="data">
          <div class="listing-image">
            <img :src="data.value" />
          </div>
        </template>
        <template #cell(intID)="data">
          <listing-id-cell-contents
            :listing="data.item"
            @ignore-listing="handleIgnoreListing(data.value)"
            @restore-listing="handleRestoreListing(data.value)"
          />
        </template>
        <template #cell(strModel)="data">
          <div class="text-truncate" style="max-width: 10em">
            <span v-if="data.value">{{ data.value }}</span>
            <span v-else class="text-danger font-italic">(No Value)</span>
          </div>
        </template>
        <template #cell(strManufacturer)="data">
          <div>
            <b-link
              @click.prevent="
                setFilterMfType({ mfId: data.item.intManufacturerID })
              "
              href="#"
              v-if="data.value"
              :title="`ID: ${data.item.intManufacturerID}`"
              >{{ data.value }}
            </b-link>
            <span v-else class="text-danger font-italic">(No Value)</span>
            <span class="mx-2">›</span>
            <b-link
              @click.prevent="
                setFilterMfType({ typeId: data.item.intAgTypeID })
              "
              href="#"
              v-if="data.item.strAgType"
              :title="`ID: ${data.item.intAgTypeID}`"
              >{{ data.item.strAgType }}
            </b-link>
            <span v-else class="text-danger font-italic">(No Value)</span>

            <b-link
              v-if="data.item.intManufacturerID && data.item.intAgTypeID"
              @click.prevent="
                setFilterMfType({
                  mfId: data.item.intManufacturerID,
                  typeId: data.item.intAgTypeID,
                })
              "
              class="text-muted small ml-2 border-primary d-inline-block"
              title="Filter by both"
            >
              <b-icon-filter />
            </b-link>
          </div>
          <div class="small text-muted" style="line-height: 1.2">
            Updated: {{ dateFormat(data.item.dtLastModifiedDate) }} ({{
              data.item.strLastUserName
            }})
          </div>
        </template>
        <template #cell(strClientName)="data">
          <div class="text-truncate" style="max-width: 14em">
            <div :id="generateClientPopoverID(data.item)">
              <a
                href="#"
                @click.prevent="
                  setFilterMfType({ clientId: data.item.intClientID })
                "
              >
                {{ data.value }}
              </a>
            </div>
            <b-popover
              :target="generateClientPopoverID(data.item)"
              placement="top"
              triggers="hover"
            >
              <template v-slot:title>
                <span :title="`ID: ${data.item.intClientID}`">
                  {{ data.item.strClientName }}
                </span>
              </template>
              <div>
                <dl>
                  <dt>Location:</dt>
                  <dd :title="data.item.intLocationID">
                    {{ data.item.strLocationName }}
                  </dd>
                  <dt>Sales Rep:</dt>
                  <dd :title="data.item.strRepUserName">
                    {{
                      `${data.item.strRepFirstName} ${data.item.strRepLastName}`
                    }}
                  </dd>
                </dl>
              </div>
            </b-popover>
            <div class="small text-muted">
              <span class="mr-2"> Rep: {{ data.item.strRepUserName }} </span>
            </div>
          </div>
        </template>
      </b-table>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-content-end pt-3">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import Typeahead from '@/components/Typeahead';
import ListingIdCellContents from '@/components/UnmatchedListings/ListingIDCellContents';

import api from '@/api';
import { config } from '@/common/config';
import axios from 'axios';

const DEFAULT_PER_PAGE = 50;
const DEFAULT_SELECTED_TYPE = { name: null };
const DEFAULT_SELECTED_MF = { name: null };
const DEFAULT_SELECTED_CLIENT = { name: null };
const DEFAULT_SELECTED_REP = { name: null };

const IGNORED_HIDE = 'hide';
const IGNORED_SHOW = 'show';
const IGNORED_ONLY = 'only';

export default {
  name: 'Datatable',
  components: {
    Typeahead,
    ListingIdCellContents,
  },
  data: () => ({
    selectedMf: DEFAULT_SELECTED_MF,
    selectedType: DEFAULT_SELECTED_TYPE,
    selectedClient: DEFAULT_SELECTED_CLIENT,
    selectedRep: DEFAULT_SELECTED_REP,
    exactMatch: 0,
    form: {
      nameSearch: null,
    },
    selectedItems: [],
    isBusy: false,
    renderTable: false,
    activeStates: [
      { value: null, text: 'All' },
      { value: 1, text: 'Only Active' },
      { value: 0, text: 'Only Archived' },
    ],
    ignoredStates: [
      { value: IGNORED_HIDE, text: 'Hide' },
      { value: IGNORED_SHOW, text: 'Show' },
      { value: IGNORED_ONLY, text: 'Only' },
    ],
    selectedSort: '-dtLastModifiedDate',
    sortOptions: [
      { label: 'ID', param: 'intID' },
      { label: 'Date Last Modified', param: 'dtLastModifiedDate' },
      { label: 'Manufacturer Name', param: 'strManufacturer' },
      { label: 'Type Name', param: 'strAgType' },
      { label: 'Model Name', param: 'strModelAsciiLower' },
      { label: 'Client Name', param: 'strClientName' },
    ],
    filter: {
      active: 1,
      ignoredState: IGNORED_HIDE,
    },
    currentPage: 1,
    perPage: DEFAULT_PER_PAGE,
    totalItems: 0,
    fields: [
      { key: 'url', label: 'Image' },
      { key: 'intID', label: 'ID' },
      { key: 'strManufacturer', label: 'Manufacturer › Type' },
      { key: 'strModel', label: 'Model' },
      { key: 'strClientName', label: 'Client' },
    ],
    query: {
      mfId: null,
      typeId: null,
      nameSearch: null,
    },
  }),
  async created() {
    await this.loadMakesTypesClients();
    if (this.$route.query.page) {
      this.currentPage = this.$route.query.page;
    }
    this.query = {
      ...this.query,
      ...this.$route.query,
    };
    const { mfId, typeId, clientId, repId, nameSearch, exactMatch } =
      this.query;
    const noItem = { name: null };
    this.selectedMf = this.mfById(mfId) || noItem;
    this.selectedType = this.typeById(typeId) || noItem;
    this.selectedClient = this.clientById(clientId) || noItem;
    this.selectedRep = this.repById(repId) || noItem;
    this.form.nameSearch = nameSearch || null;
    this.exactMatch = exactMatch || 0;
    this.renderTable = true;
  },
  watch: {
    activeState() {
      this.refreshTable();
    },
    ignoredState() {
      this.refreshTable();
    },
    selectedSort() {
      this.refreshTable();
    },
    query: {
      handler(query) {
        Object.keys(query).forEach(key => {
          if (query[key] === undefined || query[key] === null) {
            delete query[key];
          }
        });
        const route = { query };
        this.$router.push(route).catch(() => {});
      },
      deep: true,
    },
    $route: 'routeUpdated',
    currentPage(page) {
      this.$router
        .push({
          query: { ...this.$route.query, page },
        })
        .catch(() => {});
    },
  },
  computed: {
    ...mapState('unmatchedModels', {
      manufacturers: state => state.manufacturers,
      types: state => state.types,
      clients: state => state.clients,
      reps: state => state.reps,
    }),
    ...mapGetters('unmatchedModels', {
      mfById: 'getManufacturerById',
      typeById: 'getTypeById',
      clientById: 'getClientById',
      repById: 'getRepById',
      getTypesSorted: 'getTypesSorted',
      getMfsSorted: 'getMfsSorted',
      getClientsSorted: 'getClientsSorted',
      getRepsSorted: 'getRepsSorted',
    }),
    validNameSearch() {
      if (this.form.nameSearch === null || this.form.nameSearch === '') {
        return null;
      }

      const validRegex = config.modelName.regex;
      return validRegex.test(this.form.nameSearch);
    },
    activeMfs() {
      return this.getMfsSorted.filter(m => m.blnActive);
    },
    activeTypes() {
      return this.getTypesSorted;
    },
    activeClients() {
      return this.getClientsSorted;
    },
    activeReps() {
      return this.getRepsSorted;
    },
    sortOptionValues() {
      const options = [];
      this.sortOptions.forEach(option => {
        options.push({ value: option.param, text: `${option.label} ↑` });
        options.push({ value: '-' + option.param, text: `${option.label} ↓` });
      });
      return options;
    },
    tableFilter() {
      const filter = {};
      if (this.activeState.value !== null) {
        filter['blnIsActive'] = this.activeState.value;
      }
      if (this.selectedMf) {
        filter['intManufacturerID'] = this.selectedMf.id;
      }
      if (this.selectedType) {
        filter['intAgTypeID'] = this.selectedType.id;
      }
      if (this.selectedClient) {
        filter['intClientID'] = this.selectedClient.id;
      }
      if (this.selectedRep) {
        filter['intRepID'] = this.selectedRep.id;
      }
      if (this.query.nameSearch) {
        filter['strModel'] = this.query.nameSearch;
      }
      if (this.filter.ignoredState) {
        filter['ignored'] = this.filter.ignoredState;
      }
      return filter;
    },
    activeState() {
      return this.activeStates.find(s => s.value === this.filter.active);
    },
    selectedValidMfTypes() {
      if (!this.selectedMfTypesValid) {
        return {};
      }
      const { intManufacturerID, intAgTypeID } = this.selectedItems[0];
      return { mf: intManufacturerID, type: intAgTypeID };
    },
    selectedMfTypes() {
      const mfTypes = {};
      this.selectedItems.forEach(item => {
        if (item.intManufacturerID && item.intAgTypeID) {
          const key = `${item.strManufacturer}__${item.strAgType}`;
          mfTypes[key] =
            typeof mfTypes[key] === 'undefined' ? 1 : mfTypes[key] + 1;
        }
      });
      return mfTypes;
    },
    selectedMfTypesValid() {
      return Object.keys(this.selectedMfTypes).length === 1;
    },
    isSelectedRowsValid() {
      return this.selectedItems.length === 0;
    },
  },
  methods: {
    ...mapActions('unmatchedModels', {
      loadManufacturers: 'loadManufacturers',
      loadTypes: 'loadTypes',
      loadClients: 'loadClients',
      loadReps: 'loadReps',
    }),
    handleTableRefresh() {
      if (this.$route.query.page) {
        this.currentPage = parseInt(this.$route.query.page);
      }
    },
    refreshTable() {
      this.$refs.table.refresh();
    },
    resetPagination() {
      this.currentPage = 1;
    },
    async loadMakesTypesClients() {
      await Promise.all([
        this.loadManufacturers(),
        this.loadTypes(),
        this.loadClients(),
        this.loadReps(),
      ]);
    },
    onTableRowsSelected(items) {
      this.selectedItems = items.map(item => {
        const {
          intID,
          strModel,
          intManufacturerID,
          strManufacturer,
          intAgTypeID,
          strAgType,
        } = item;
        return {
          isCanonical: false,
          isAlias: false,
          intID,
          strModel,
          intManufacturerID,
          strManufacturer,
          intAgTypeID,
          strAgType,
        };
      });
      this.$emit('select-rows', this.selectedItems);
    },
    setFilterMfType({ mfId = null, typeId = null, clientId = null }) {
      if (null !== mfId) {
        this.setMf(this.mfById(mfId));
      }
      if (null !== typeId) {
        this.setType(this.typeById(typeId));
      }
      if (null !== clientId) {
        this.setClient(this.clientById(clientId));
      }
    },
    setMf(mf) {
      this.query = {
        ...this.query,
        mfId: mf.id,
      };
      this.selectedMf = mf;
      this.$emit('select-mf', mf);
      this.resetPagination();
      this.refreshTable();
    },
    clearMf() {
      this.query = { ...this.query, mfId: null };
      this.setMf(DEFAULT_SELECTED_MF);
      this.resetPagination();
      this.refreshTable();
    },
    setType(type) {
      this.query = {
        ...this.query,
        typeId: type.id,
      };
      this.selectedType = type;
      this.$emit('select-type', type);
      this.resetPagination();
      this.refreshTable();
    },
    clearType() {
      this.query = { ...this.query, typeId: null };
      this.setType(DEFAULT_SELECTED_TYPE);
      this.resetPagination();
      this.refreshTable();
    },
    setClient(client) {
      this.query = {
        ...this.query,
        clientId: client.id,
      };
      this.selectedClient = client;
      this.$emit('select-client', client);
      this.resetPagination();
      this.refreshTable();
    },
    clearClient() {
      this.query = { ...this.query, clientId: null };
      this.setClient(DEFAULT_SELECTED_CLIENT);
      this.resetPagination();
      this.refreshTable();
    },
    clearIgnored() {
      this.filter.ignoredState = IGNORED_HIDE;
    },
    setRep(rep) {
      this.query = {
        ...this.query,
        repId: rep.id,
      };
      this.selectedRep = rep;
      this.$emit('select-rep', rep);
      this.resetPagination();
      this.refreshTable();
    },
    clearRep() {
      this.query = { ...this.query, repId: null };
      this.setRep(DEFAULT_SELECTED_REP);
      this.resetPagination();
      this.refreshTable();
    },
    repLabelFormatter(rep) {
      return `${rep.strLastnameFirstname} (# Clients: ${rep.intClientCount}, ID: ${rep.intID})`;
    },
    clearActive() {
      this.filter.active = 1;
    },
    clearFilters() {
      this.clearMf();
      this.clearType();
      this.clearClient();
      this.clearModelSearch();
      this.clearActive();
      this.clearIgnored();
    },
    setModelSearch() {
      this.query = {
        ...this.query,
        nameSearch: this.form.nameSearch,
        exactMatch: this.exactMatch === '1' ? this.exactMatch : null,
      };
      this.resetPagination();
      this.refreshTable();
    },
    clearModelSearch() {
      this.form.nameSearch = null;
      this.exactMatch = 0;
      this.query = { ...this.query, nameSearch: null, exactMatch: null };
      this.resetPagination();
      this.refreshTable();
    },
    routeUpdated() {
      this.$refs.table.refresh();
    },
    handleIgnoreOrRestoreMultipleListings(action) {
      let selListingsArr = [];

      this.selectedItems.forEach(item => {
        selListingsArr.push(item.intID);
      });

      if (action === 'ignore') {
        this.handleIgnoreListing(selListingsArr);
      } else if (action === 'restore') {
        this.handleRestoreListing(selListingsArr);
      }
    },
    async handleIgnoreListing(id) {
      try {
        const result = await api.ignoreListing(id);
        if (result.status === 'success') {
          this.refreshTable();
        }
      } catch (err) {
        console.error(err);
      }
    },
    async handleRestoreListing(id) {
      try {
        const result = await api.restoreListing(id);
        if (result.status === 'success') {
          this.refreshTable();
        }
      } catch (err) {
        console.error(err);
      }
    },
    generateClientPopoverID: listing =>
      `listing-${listing.intID}-client-name-${listing.intClientID}`,
    dateFormat(date = null) {
      return null !== date ? new Date(date).toLocaleString('en-CA') : 'Never';
    },
    async dataProvider(ctx) {
      this.isBusy = true;

      await Promise.all([
        this.loadManufacturers(),
        this.loadTypes(),
        this.loadClients(),
      ]);

      const page = this.$route.query.page || ctx.currentPage;
      const exactMatch = parseInt(this.exactMatch);

      const params = {
        size: ctx.perPage,
        page,
        exactMatch,
      };

      try {
        const listings = await api.getUnmatchedListings(
          ctx.filter,
          [this.selectedSort],
          params,
        );

        this.totalItems = listings.meta.total;
        this.$emit('total-items-updated', this.totalItems);
        this.isBusy = false;

        return listings.data.map(item => ({ ...item, selected: false }));
      } catch (err) {
        this.isBusy = false;
        this.currentPage = 1;
        this.perPage = DEFAULT_PER_PAGE;
        this.totalItems = 0;

        if (axios.isCancel(err)) {
          this.refreshTable();
        }

        return [];
      }
    },
  },
};
</script>

<style>
.listing-image {
  background-image: url(/images/no-image.png);
  aspect-ratio: 1.3333333333;
  background-color: #d9d9d9;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 150px;
}

.listing-image img {
  aspect-ratio: 1.3333333333;
  margin: 0 auto;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
}
</style>
