<template>
  <div>
    <b-row class="text-light bg-dark p-2 font-weight-bold" align-v="center">
      <b-col class="align-middle text-center" align-v="center">{{
        clientName
      }}</b-col>
    </b-row>
    <b-row>
      <b-col class="p-0 first-col">
        <left-column></left-column>
      </b-col>
      <b-col class="p-0">
        <top-five-listings></top-five-listings>
        <daily-detail-views></daily-detail-views>
        <location-totals></location-totals>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TopFiveListings from '@/components/Dashboard/TopFiveListings';
import DailyDetailViews from '@/components/Dashboard/DailyDetailViews';
import LocationTotals from '@/components/Dashboard/LocationTotals';
import LeftColumn from '@/components/Dashboard/LeftColumn';
import { mapState } from 'vuex';

export default {
  name: 'NewDashboard',
  components: {
    TopFiveListings,
    DailyDetailViews,
    LocationTotals,
    LeftColumn,
  },
  computed: {
    ...mapState('client', ['clientName', 'clientLogoUrl']),
  },
};
</script>

<style lang="scss">
.first-col {
  width: 300px !important;
  max-width: 300px !important;
}
.font-12 {
  font-size: 12px;
}
</style>
