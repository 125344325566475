<template>
  <div class="sticky-top">
    <h4>Merge Models</h4>
    <section v-if="mergedModel">
      <p class="mb-2"><strong>Merge Results</strong></p>
      <b-list-group>
        <b-list-group-item>
          <model-summary-card
            :model="mergedModel"
            @cancel-click="$emit('click-parent')"
          >
            <template #badge>
              <h6>
                <b-badge variant="success">Merge Result</b-badge>
              </h6>
            </template>
          </model-summary-card>
        </b-list-group-item>
        <b-list-group-item class="text-right" v-if="children.length">
          <b-button
            @click.prevent="$emit('click-parent')"
            type="reset"
            size="sm"
            variant="danger"
            class="mr-2"
          >
            <b-icon icon="x" />
            Cancel
          </b-button>
          <b-button
            @click.prevent="handleExecuteMerge"
            type="submit"
            size="sm"
            variant="success"
          >
            <b-icon icon="check" />
            Merge Models
          </b-button>
        </b-list-group-item>
      </b-list-group>
    </section>
    <section v-if="children" class="mt-3">
      <p class="mb-2"><strong>To Be Merged</strong></p>
      <b-list-group>
        <b-list-group-item>
          <model-summary-card
            :model="parent"
            @cancel-click="$emit('click-parent')"
          >
            <template #badge>
              <h6>
                <b-badge variant="primary">Parent</b-badge>
              </h6>
            </template>
          </model-summary-card>
        </b-list-group-item>
        <b-list-group-item v-for="(m, i) in children" :key="i">
          <model-summary-card
            :model="m"
            @cancel-click="$emit('click-child', m)"
          >
            <template #badge>
              <h6>
                <b-badge variant="secondary">Child</b-badge>
              </h6>
            </template>
          </model-summary-card>
        </b-list-group-item>
      </b-list-group>
    </section>
  </div>
</template>

<script>
import merge from 'deepmerge';
import ModelSummaryCard from './ModelSummaryCard';
import api from '@/api';

export default {
  name: 'MergeToParentSidebar',
  components: {
    ModelSummaryCard,
  },
  props: {
    parent: {
      type: Object,
      default: null,
    },
    children: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    async handleExecuteMerge() {
      const parentId = this.parent.intID;
      const childIds = this.children.map(c => c.intID);
      const merged = await api.mergeModels(parentId, childIds);
      this.$emit('merge-success', merged);
    },
  },
  computed: {
    mergedModel() {
      const merged = { ...this.parent };

      merged.categories = merge.all([
        ...this.children.map(c => c.categories),
        this.parent.categories,
      ]);
      merged.alias_patterns = merge.all([
        ...this.children.map(c => c.alias_patterns),
        this.parent.alias_patterns,
      ]);
      merged.type_aliases = merge.all([
        ...this.children.map(c => c.type_aliases),
        this.parent.type_aliases,
      ]);
      this.children.forEach(m => {
        merged.alias_patterns.push({ pattern: m.strName });
        merged.type_aliases.push({ intTypeID: m.intTypeID });
      });

      ['intListingsActive', 'intListingsTotal', 'intListingsArchived'].forEach(
        p => {
          merged[p] += this.children.reduce((acc, cv) => acc + cv[p], 0);
        },
      );
      return merged;
    },
  },
};
</script>
