<template>
  <div class="container">
    <b-container v-if="model">
      <b-row class="mb-2">
        <b-button
          @click.prevent="$router.back()"
          variant="outline-primary"
          size="sm"
        >
          ← Back to Models
        </b-button>
      </b-row>
      <b-row>
        <div class="w-100 d-flex justify-content-between">
          <div>
            <h4 v-if="mf && type">
              {{ mf ? mf.name : '' }}
              <span class="mx-1">›</span>
              {{ type ? type.name : '' }}
            </h4>
            <h2>{{ model ? model.strName : '' }}</h2>

            <b-button-toolbar v-if="model">
              <b-button @click="editMode = !editMode" class="mr-2"
                >Edit</b-button
              >
              <model-delete
                :model="model"
                @model-deleted="handleModelDelete"
                classes="mr-2"
              />
            </b-button-toolbar>
          </div>
          <div v-if="model.type_aliases.length">
            <h6>Type Aliases</h6>
            <ul>
              <li :key="i" v-for="(a, i) in model.type_aliases">
                {{ typeById(a.intTypeID).strType }}
                ({{ a.intTypeID }})
              </li>
            </ul>
          </div>
          <div>
            <h6>Categories</h6>
            <ul>
              <li :key="c.id" v-for="c in model.categories">
                {{ c.en.category }}
                <span class="mx-1">›</span>
                {{ c.en.subcategory }}
              </li>
            </ul>
          </div>
        </div>
      </b-row>
      <hr />
      <b-row>
        <b-col xs="12" lg="3">
          <h6>Aliases</h6>
          <b-list-group>
            <b-list-group-item :key="a.id" v-for="a in model.alias_patterns">
              <span class="text-monospace small">{{ a.pattern }}</span>
            </b-list-group-item>
            <!--
            <b-list-group-item href="#" class="font-weight-bold">
              <b-icon-plus class="mr-1" /> Create New
            </b-list-group-item>
            -->
          </b-list-group>
        </b-col>
        <b-col xs="12" lg="9">
          <model-listing-datatable
            :model="model"
            v-show="!editMode"
            ref="model-listing-datatable"
          />
          <section v-if="editMode">
            <h4>Edit Model Details</h4>
            <model-form
              :key="formRevision"
              :model="model"
              class="mb-4"
              @submit="handleEditSubmit"
              @cancel="editMode = false"
            />
          </section>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import api from '@/api';
import { mapGetters, mapState, mapActions } from 'vuex';
import ModelListingDatatable from '@/components/ModelListingDatatable';
import ModelForm from '@/components/ModelDetails/ModelForm';
import ModelDelete from '@/components/ModelDetails/ModelDelete';

export default {
  name: 'ModelDetails',
  components: {
    ModelListingDatatable,
    ModelForm,
    ModelDelete,
  },
  data() {
    return {
      editMode: false,
      model: null,
      mf: null,
      type: null,
      formRevision: 0,
    };
  },
  props: {
    modelId: Number,
  },
  async mounted() {
    await Promise.all([this.loadManufacturers(), this.loadTypes()]);
    try {
      await this.loadModel(this.modelId);
    } catch (err) {
      console.log('error', err);
      await this.$router.push({ name: 'models' });
    }
  },
  computed: {
    ...mapState('models', {
      manufacturers: state => state.manufacturers,
      types: state => state.types,
    }),
    ...mapGetters('models', {
      mfById: 'getManufacturerById',
      typeById: 'getTypeById',
    }),
    manufacturer() {
      if (!this.model) {
        return null;
      }
      return this.mfById(this.model.intManufacturerID);
    },
  },
  watch: {
    model() {
      this.type = this.typeById(this.model.intTypeID);
      this.mf = this.mfById(this.model.intManufacturerID);
    },
  },
  methods: {
    ...mapActions('models', {
      loadManufacturers: 'loadManufacturers',
      loadTypes: 'loadTypes',
    }),
    handleModelDelete(response) {
      console.log('Model deleted', response);
      this.$router.push({
        name: 'models',
        params: {
          mfId: `${this.model.intManufacturerID}`,
        },
      });
    },
    refreshDatatable() {
      this.$refs['model-listing-datatable'].refreshTable();
    },
    handleEditSubmit(model) {
      console.log(model);
      this.editMode = false;
      this.formRevision += 1;
      this.model = model;
      this.refreshDatatable();
    },
    async loadModel(modelId) {
      return api.getModel(modelId).then(model => {
        this.model = model;
      });
    },
  },
};
</script>

<style scoped></style>
