<template>
  <div>
    <b-form-group
      class="mt-2"
      description="Aliases will be lowercased and accents converted automatically"
    >
      <b-list-group>
        <b-list-group-item
          :key="i"
          v-for="(a, i) in currentAliases"
          class="d-flex align-items-center"
        >
          <template v-if="!a.deleted">
            <span class="flex-grow-1 text-monospace small">
              {{ a.pattern }}
            </span>
            <span class="flex-grow-0 float-right small muted">
              <span v-if="a.id == null" class="mr-2">(New)</span>
              <b-button size="sm" @click.prevent="dropAlias(i)">
                Delete
              </b-button>
            </span>
          </template>
          <template v-else>
            <span
              class="flex-grow-1 text-monospace small"
              style="text-decoration: line-through"
            >
              {{ a.pattern }}
            </span>
            <span class="flex-grow-0 float-right small muted">
              <span v-if="a.id == null" class="mr-2">(New)</span>
              <b-button size="sm" @click.prevent="restoreAlias(i)">
                Restore
              </b-button>
            </span>
          </template>
        </b-list-group-item>
      </b-list-group>
      <b-input-group>
        <b-form-input
          id="input_new_alias"
          v-model="newAlias"
          type="text"
          placeholder="Add alias..."
          :formatter="aliasFormatter"
          @keyup.enter.prevent="addAlias"
        ></b-form-input>
        <template #append>
          <b-button @click.prevent="addAlias">Add</b-button>
        </template>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
import foldToAscii from 'fold-to-ascii';

export default {
  name: 'ModelAliasSelector',
  props: {
    existingAliases: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentAliases: [],
      newAlias: null,
    };
  },
  mounted() {
    this.currentAliases = this.existingAliases.map(a => {
      return { ...a, deleted: false };
    });
  },
  watch: {
    currentAliases(aliases) {
      this.$emit('aliases-updated', aliases);
    },
  },
  methods: {
    addAlias() {
      this.currentAliases.push({ pattern: this.newAlias });
    },
    restoreAlias(index) {
      this.currentAliases.splice(index, 1, {
        ...this.currentAliases[index],
        deleted: false,
      });
    },
    dropAlias(index) {
      if (this.currentAliases[index].id == null) {
        this.currentAliases.splice(index, 1);
      } else {
        this.currentAliases.splice(index, 1, {
          ...this.currentAliases[index],
          deleted: true,
        });
      }
    },
    aliasFormatter(value) {
      return foldToAscii
        .foldReplacing(value.toLowerCase())
        .replace(/\s+/g, ' ');
    },
  },
};
</script>
