<template>
  <b-container fluid="true">
    <b-row>
      <h4>
        Unmatched Listings
        <span v-if="table.totalItems !== null" class="small text-muted ml-3">
          {{ table.totalItems }} pending
        </span>
      </h4>
    </b-row>
    <b-row>
      <b-col md="8">
        <datatable
          @select-rows="handleRowsSelected"
          @select-mf="handleMfSelected"
          @select-type="handleTypeSelected"
          @total-items-updated="handleTableItemCountUpdated"
          ref="datatable"
        />
      </b-col>
      <b-col md="4">
        <sidebar
          @update-table="handleTableUpdate"
          v-if="isSelectedMfTypeValid"
          :selected-models="distinctSelectedModels"
          :selected-mf="selectedValidMfTypes.mf || null"
          :selected-type="selectedValidMfTypes.type || null"
        />
        <p v-else>
          Select one or more rows with matching Manufacturer and Type to
          continue...
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import Datatable from '@/components/UnmatchedListings/Datatable';
import Sidebar from '@/components/UnmatchedListings/Sidebar';
import api from '@/api';

const DEFAULT_SELECTED_TYPE = { name: null };
const DEFAULT_SELECTED_MF = { name: null };

export default {
  name: 'UnmatchedListings',
  components: {
    Datatable,
    Sidebar,
  },
  data: () => ({
    table: {
      selectedMf: DEFAULT_SELECTED_MF,
      selectedType: DEFAULT_SELECTED_TYPE,
      selectedItems: [],
      totalItems: null,
    },
    sidebar: {
      mfId: null,
      typeId: null,
      modelName: '',
      matchedModels: [],
      selectedModelTable: [],
      createCanonicalForm: {
        canonicalName: '',
      },
    },
  }),
  computed: {
    ...mapState('unmatchedModels', {
      manufacturers: state => state.manufacturers,
      types: state => state.types,
      clients: state => state.clients,
    }),
    ...mapGetters('unmatchedModels', {
      mfById: 'getManufacturerById',
      typeById: 'getTypeById',
      clientById: 'getClientById',
    }),
    selectedValidMfTypes() {
      if (!this.isSelectedMfTypeValid) {
        return {};
      }
      const { intManufacturerID, intAgTypeID } = this.table.selectedItems[0];
      return {
        mf: this.mfById(intManufacturerID),
        type: this.typeById(intAgTypeID),
      };
    },
    selectedMfTypes() {
      const mfTypes = {};
      this.table.selectedItems.forEach(item => {
        const mf = item.strManufacturer;
        const type = item.strAgType;
        const model = item.strModel;
        if (mf && type) {
          const key = `${mf}__${type}`;
          mfTypes[key] = { ...mfTypes[key], [model]: true };
        }
      });
      return mfTypes;
    },
    isSelectedMfTypeValid() {
      return Object.keys(this.selectedMfTypes).length === 1;
    },
    distinctSelectedModels() {
      const models = [];
      Object.keys(this.selectedMfTypes).forEach(mfTypeKey => {
        Object.keys(this.selectedMfTypes[mfTypeKey]).forEach(model => {
          console.log(mfTypeKey, model);
          models.push({
            model,
          });
        });
      });
      return models;
    },
  },
  methods: {
    handleTableItemCountUpdated(newCount) {
      this.table.totalItems = newCount;
    },
    handleTableUpdate() {
      this.$refs.datatable.refreshTable();
    },
    handleRowsSelected(rows) {
      this.table.selectedItems = rows;
    },
    handleMfSelected(mf) {
      this.table.selectedMf = mf;
    },
    handleTypeSelected(type) {
      this.table.selectedType = type;
    },
    async loadSidebarModels() {
      this.sidebar.matchedModels = [];
      const item = this.table.selectedItems[0];
      console.log('sel items, item', this.table.selectedItems, item);
      const models = await api.getModels(
        {},
        item.intManufacturerID,
        item.intAgTypeID,
      );
      this.sidebar.matchedModels = models.data;
    },
    async setSidebar(listing) {
      this.sidebar.matchedModels = [];
      this.sidebar.mfId = listing.intManufacturerID;
      this.sidebar.typeId = listing.intAgTypeID;
      this.sidebar.matchedModels = await api.getModels(
        {},
        this.sidebar.mfId,
        this.sidebar.typeId,
      );
    },
  },
};
</script>
