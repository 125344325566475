const nameSorter = (a, b) => {
  const aLower = a.name.toLowerCase();
  const bLower = b.name.toLowerCase();
  if (aLower < bLower) {
    return -1;
  }
  if (aLower > bLower) {
    return 1;
  }
  return 0;
};

const keyById = (data, idKey = 'id') =>
  data.reduce((acc, cur) => {
    return { ...acc, [cur[idKey]]: cur };
  }, {});

export { nameSorter, keyById };
