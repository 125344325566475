<template>
  <div :class="classes">
    <div class="d-flex justify-content-between align-items-start">
      <div>
        <div v-html="`${model.strManufacturer} › ${model.strType}`" />
        <h6>
          {{ model.strName }}
        </h6>
        <slot name="badge"></slot>
      </div>
      <b-button
        size="sm"
        variant="outline-primary"
        @click="$emit('cancel-click')"
      >
        Cancel
      </b-button>
    </div>

    <section class="mt-2">
      <div class="font-weight-bold">Listings</div>
      {{ model.intListingsTotal }}
      <span class="small text-muted ml-1 text-nowrap">
        ({{ model.intListingsActive }} / {{ model.intListingsArchived }})
      </span>
    </section>

    <section v-if="model.categories.length" class="mt-2">
      <div class="font-weight-bold">Categories</div>
      <ul class="pl-4 mb-0">
        <li
          v-for="(c, i) in model.categories"
          :key="i"
          v-html="`${c.strCategory} › ${c.strSubcategory}`"
        />
      </ul>
    </section>

    <div class="flex-column align-items-start mt-1">
      <div class="d-flex">
        <section v-if="model.alias_patterns.length" class="flex-fill">
          <div class="font-weight-bold">Alias Patterns</div>
          <ul class="pl-4 mb-0">
            <li v-for="(p, i) in model.alias_patterns" :key="i">
              <code v-html="p.pattern" />
            </li>
          </ul>
        </section>
        <section v-if="model.type_aliases.length" class="flex-fill">
          <div class="font-weight-bold">Type Aliases</div>
          <ul class="pl-4 mb-0">
            <li v-for="(t, i) in model.type_aliases" :key="i">
              {{ getTypeById(t.intTypeID).strType || 'N/A' }}
              <span class="text-muted small">({{ t.intTypeID }})</span>
            </li>
          </ul>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ModelSummaryCard',
  props: {
    model: Object,
    classes: {
      type: [String, Array],
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('models', {
      getManufacturerById: 'getManufacturerById',
      getTypeById: 'getTypeById',
    }),
  },
};
</script>
