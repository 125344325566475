<template>
  <div>
    <b-list-group>
      <b-list-group-item
        :key="i"
        v-for="(a, i) in currentTypeAliases"
        class="d-flex align-items-center"
      >
        <span class="flex-grow-1 small">
          {{ a.strType }}
          <span class="text-muted">{{ getListItemDetails(a) }}</span>
        </span>
        <span class="flex-grow-0 float-right small muted">
          <b-button size="sm" @click.prevent="removeTypeAlias(a.intID)">
            Remove
          </b-button>
        </span>
      </b-list-group-item>
    </b-list-group>
    <b-input-group>
      <b-form-select
        id="select_type_alias"
        :options="aliasTypesOptions"
        v-model="selectedType"
        @change="addTypeAlias"
      >
        <template #first>
          <b-form-select-option value="" disabled>
            -- Select a Type --
          </b-form-select-option>
        </template>
      </b-form-select>
    </b-input-group>
  </div>
</template>

<script>
import stringSimilarity from 'string-similarity';
export default {
  name: 'TypeAliasSelector',
  props: {
    // a map of Type objects keyed by ID
    availableAliases: {
      type: Object,
      required: true,
    },
    parentAliasId: {
      type: Number,
      default: 0,
    },
    currentAliasIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedType: '',
    };
  },
  methods: {
    addTypeAlias(a) {
      console.log('adding type alias', a, this.currentAliasIds);
      this.$emit('change', Array.from(new Set([...this.currentAliasIds, a])));
      this.selectedType = '';
    },
    removeTypeAlias(a) {
      console.log(a, this.currentAliasIds);
      const newAliases = this.currentAliasIds.filter(id => {
        console.log(`comparing type ${a} to existing ${id}`);
        return a !== id;
      });
      console.log(newAliases);

      this.$emit('change', newAliases);
      this.selectedType = '';
    },
    getListItemDetails(item) {
      const status = item.intIndustryID === null ? 'inactive' : 'active';
      return `(ID: ${item.intID}, status: ${status})`;
    },
  },
  computed: {
    selectedParentType() {
      return this.parentAliasId
        ? this.availableAliases[this.parentAliasId]
        : null;
    },
    currentTypeAliases() {
      return this.currentAliasIds
        .map(id => {
          return this.availableAliases[id] || null;
        })
        .filter(t => null !== t && t !== this.parentAliasId);
    },
    availableAliasesArray() {
      return Object.values(this.availableAliases);
    },
    typeOptions() {
      const options = this.availableAliasesArray.filter(t => {
        return !(
          !t.strType ||
          t.intID === this.parentAliasId ||
          this.currentAliasIds.includes(t.intID)
        );
      });
      return options.map(t => {
        const base = {
          html: `${t.strType}&emsp;&emsp;&emsp;${this.getListItemDetails(t)}`,
          value: t.intID,
          _score: 1, // set all scores to 1 by default
        };
        if (
          null !== this.selectedParentType &&
          this.selectedParentType.strType
        ) {
          base._score = stringSimilarity.compareTwoStrings(
            this.selectedParentType.strType,
            t.strType,
          );
        }
        return base;
      });
    },
    typeOptionsTop() {
      return [...this.typeOptions]
        .filter(o => o._score >= 0.33)
        .sort((a, b) => {
          if (a._score > b._score) return -1;
          if (a._score < b._score) return 1;
          return 0;
        });
    },
    aliasTypesOptions() {
      if (
        null === this.selectedParentType ||
        !this.selectedParentType.strType
      ) {
        return this.typeOptions;
      }
      return [
        { label: 'Most Similar:', options: this.typeOptionsTop },
        { label: 'All Types:', options: this.typeOptions },
      ];
    },
  },
};
</script>
