<template>
  <b-container class="sticky-top pt-2">
    <b-row>
      <h5>{{ mfTypeHeader }}</h5>

      <b-alert
        show
        variant="warning"
        v-if="selectedType.intIndustryID === null"
      >
        <strong>Warning: The selected type is not active</strong>
        <div class="small">
          Recommend editing the listing to change its type
        </div>
      </b-alert>

      <b-table
        v-model="selectedModelTable"
        :items="selectedModels"
        :fields="[{ key: 'model' }]"
        show-empty
      >
      </b-table>
    </b-row>

    <b-row v-if="!createForm.active && !createForm.loading">
      <b-button @click.prevent="loadCreateForm">
        Create/Assign Canonical
      </b-button>
    </b-row>
    <b-row v-else-if="!createForm.active && createForm.loading">
      <div class="align-content-center mx-auto">
        <b-spinner small label="Loading..." class="mr-2" variant="success" />
        Loading...
      </div>
    </b-row>
    <b-row v-else>
      <b-card no-body title="foobar" class="w-100">
        <b-tabs card>
          <b-tab
            title="Add to Canonical"
            active
            v-if="createForm.canonicals.length"
          >
            <sidebar-add-to-existing
              @update-table="updateTable"
              :canonicals="createForm.canonicals"
              :selected-models="selectedModelTable"
            />
          </b-tab>
          <b-tab title="Create New">
            <sidebar-create-new
              @update-table="updateTable"
              :mf-id="selectedMf.id"
              :mf="selectedMf"
              :type="selectedType"
              :selected-models="selectedModelTable"
            />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-row>
    <b-row>
      <hr class="my-3 w-100" />

      <dl class="small">
        <dt>Need some Help?</dt>
        <dd>Message in the ETL Meetings channel on MS Teams</dd>
      </dl>
    </b-row>
  </b-container>
</template>

<script>
import SidebarCreateNew from '@/components/UnmatchedListings/SidebarCreateNew';
import SidebarAddToExisting from '@/components/UnmatchedListings/SidebarAddToExisting';
import api from '@/api';

export default {
  name: 'Sidebar',
  components: {
    SidebarAddToExisting,
    SidebarCreateNew,
  },
  props: {
    selectedModels: Array,
    selectedMf: {
      type: Object,
      default: null,
    },
    selectedType: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    selectedModelTable: [],
    createForm: {
      loading: false,
      active: false,
      canonicals: [],
      selected: null,
    },
  }),
  watch: {
    selectedMf(current, prev) {
      if (current.id !== prev.id) {
        this.resetCreateForm();
      }
    },
    selectedType(current, prev) {
      if (current.id !== prev.id) {
        this.resetCreateForm();
      }
    },
  },
  computed: {
    mfTypeHeader() {
      if (this.selectedMf.name && this.selectedType.name) {
        return `${this.selectedMf.name} › ${this.selectedType.name}`;
      } else {
        return '';
      }
    },
  },
  methods: {
    updateTable() {
      this.$emit('update-table');
    },
    resetCreateForm() {
      this.createForm = {
        loading: false,
        active: false,
        canonicals: [],
      };
    },
    async loadCreateForm() {
      this.createForm.loading = true;
      const models = await api.getModels(
        {
          intManufacturerID: this.selectedMf.id,
          intTypeID: this.selectedType.id,
        },
        ['strNameAsciiLower'],
        { size: 500 },
      );
      this.createForm.canonicals = models.data;
      this.createForm.active = true;
      this.createForm.loading = false;
    },
  },
};
</script>
