<template>
  <section>
    <b-row>
      <b-col xs="12" lg="6">
        <h6>
          Matching Listings
          <span class="small text-muted ml-2">{{ listingCounts }}</span>
        </h6>
      </b-col>
      <b-col xs="12" lg="3">
        <b-input-group size="sm" class="mb-2" prepend="Show:">
          <b-form-select v-model="filter.active" :options="activeStates" />
        </b-input-group>
      </b-col>
      <b-table
        :items="itemsProvider"
        :fields="fields"
        :busy.sync="busy"
        :current-page="currentPage"
        :per-page="perPage"
        show-empty
        ref="table"
      >
        <template #cell(intID)="data">
          <span class="text-nowrap">
            <b-icon
              icon="check-circle-fill"
              variant="success"
              title="Active"
              v-if="data.item.blnIsActive"
            />
            <b-icon icon="x-circle" variant="danger" title="Archived" v-else />
            <b-link
              class="ml-2"
              :href="`https://www.agdealer.com/${data.value}`"
              target="_blank"
              >{{ data.value }}
            </b-link>
          </span>
        </template>
        <template #cell(memOptions2)="data">
          <span
            class="d-inline-block text-truncate"
            style="max-width: 25em"
            :title="data.value"
            >{{ data.value }}</span
          >
        </template>
      </b-table>
    </b-row>
    <b-row class="d-flex justify-content-end" v-if="totalListings > perPage">
      <b-pagination
        class=""
        v-model="currentPage"
        :total-rows="totalListings"
        :per-page="perPage"
        first-text="First"
        prev-text="Prev"
        next-text="Next"
        last-text="Last"
        ref="topPaginator"
      />
    </b-row>
  </section>
</template>

<script>
import api from '@/api';

export default {
  name: 'ModelListingDatatable',
  props: {
    model: Object,
    perPage: {
      type: Number,
      default: 50,
    },
  },
  data() {
    return {
      busy: false,
      currentPage: 1,
      listings: [],
      totalListings: 0,
      fields: [
        {
          key: 'intID',
          label: 'ID',
        },
        {
          key: 'strAgType',
          label: 'Type',
        },
        {
          key: 'strModel',
          label: 'Model',
        },
        {
          key: 'memOptions2',
          label: 'Description',
        },
      ],
      activeStates: [
        { value: null, text: 'All' },
        { value: 1, text: 'Only Active' },
        { value: 0, text: 'Only Archived' },
      ],
      filter: {
        active: 1,
      },
    };
  },
  computed: {
    modelId() {
      return this.model.intID;
    },
    listingCounts() {
      return `Total: ${this.model.intListingsTotal} (Active: ${this.model.intListingsActive}, Archived: ${this.model.intListingsArchived})`;
    },
    activeState() {
      return this.activeStates.find(s => s.value === this.filter.active);
    },
  },
  methods: {
    refreshTable() {
      this.$refs.table.refresh();
    },
    async itemsProvider(ctx) {
      this.busy = true;

      const showActive = this.filter.active;

      const listings = await api.getListingsByModelId(this.modelId, {
        page: ctx.currentPage,
        size: ctx.perPage,
        active: showActive,
      });
      this.listings = listings.data;
      this.totalListings = listings.meta.total;
      this.busy = false;
      return listings.data;
    },
  },
  watch: {
    activeState() {
      this.refreshTable();
    },
  },
};
</script>
