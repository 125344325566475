<template>
  <div class="text-nowrap">
    <b-button
      v-if="!parent || isParent"
      @click.prevent="$emit('click-parent')"
      :pressed="isParent"
      variant="outline-primary"
      size="sm"
    >
      <b-icon-folder-plus class="mr-1" />
      Parent
    </b-button>
    <!--
    <b-button
      v-if="!parent"
      @click.prevent="$emit('click-new')"
      variant="outline-primary"
      size="sm"
      class="ml-2"
    >
      <b-icon-plus-square class="mr-1" />
      New
    </b-button>
    -->
    <b-button
      v-if="parent && !isParent"
      @click.prevent="$emit('click-child')"
      :pressed="isChild"
      variant="outline-primary"
      size="sm"
    >
      <b-icon class="mr-1" :icon="isChild ? 'check' : 'plus'" />
      Merge
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'DatatableMergeButtons',
  props: {
    currentItem: Object,
    parent: {
      type: Object,
      default: null,
    },
    childIds: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isParent() {
      return (
        true === (this.parent && this.parent.intID === this.currentItem.intID)
      );
    },
    isChild() {
      return this.childIds.includes(this.currentItem.intID);
    },
  },
};
</script>
