<template>
  <b-form
    @submit.stop.prevent="handleSubmit"
    @reset.stop.prevent="handleCancel"
  >
    <b-overlay :show="isSubmitting">
      <b-container>
        <b-alert
          :show="errors.length > 0"
          dismissible
          variant="danger"
          @dismissed="errors = []"
        >
          <b>Please correct the following error(s):</b>
          <ul>
            <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
          </ul>
        </b-alert>

        <category-typehead
          :init-selected-item="selectedCategory"
          :init-items="categories"
          @set-item="handleSelectCategory"
        />

        <b-form-input
          id="subcategory_en"
          v-model="subCategory"
          type="text"
          debounce="700"
          autocomplete="off"
          placeholder="Enter SubCategory"
        ></b-form-input>

        <b-form-input
          id="subcategory_fr"
          v-model="subCategoryF"
          type="text"
          debounce="700"
          autocomplete="off"
          placeholder="Enter French text for SubCategory"
          class="mt-3"
        ></b-form-input>

        <small class="text-muted"
          >If empty, then English text used as French text.</small
        >

        <div class="mt-3 mx-auto" style="width: 200px">
          <b-button @click.prevent.stop="handleSubmit" class="mr-2">
            Save
          </b-button>
          <b-button type="reset" variant="danger">Cancel</b-button>
        </div>
      </b-container>
    </b-overlay>
  </b-form>
</template>

<script>
import CategoryTypehead from '@/components/Typeaheads/CategoryTypehead';
import api from '@/api';

export default {
  name: 'SubCategoriesForm',
  components: {
    CategoryTypehead,
  },
  data() {
    return {
      isSubmitting: false,
      selectedCategory: { name: '' },
      subCategory: null,
      subCategoryF: null,
      errors: [],
    };
  },
  props: {
    createOrEditMode: {
      type: String,
      default: 'edit',
      validator: value => ['edit', 'create'].indexOf(value) !== -1,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  computed: {
    formData() {
      return {
        category: this.selectedCategory.strCategory,
        categoryF: this.selectedCategory.strCategoryF,
        subCategory: this.subCategory,
        subCategoryF: this.subCategoryF ?? this.subCategory,
      };
    },
  },
  methods: {
    handleSelectCategory(cat) {
      this.selectedCategory = cat ?? { name: '' };
    },
    validateForm() {
      if (this.formData.category && this.formData.subCategory) {
        return true;
      }

      this.errors = [];

      if (!this.formData.category) {
        this.errors.push('Category required.');
      }

      if (!this.formData.subCategory) {
        this.errors.push('SubCategory required.');
      }

      return false;
    },
    async handleSubmit() {
      this.isSubmitting = true;

      if (!this.validateForm()) {
        this.isSubmitting = false;
        return false;
      }

      // need to handle update vs. create here
      try {
        let response = { status: 'unsubmitted' };
        if (this.createOrEditMode === 'create') {
          response = await api.createNewModelSubCategory(this.formData);
        } else {
          // need to add the logic for update
        }

        if (response.status === 'success') {
          this.$emit('submit', response.model);
          this.resetData();
        }
      } catch (err) {
        let message = err.response.data.message || 'An unknown error occurred';

        if (typeof message === 'object') {
          message = JSON.stringify(err.response.data.message);
        }

        window.alert(message);
      }
      this.isSubmitting = false;
    },
    resetData() {
      this.selectedCategory = { name: '' };
      this.subCategory = '';
      this.subCategoryF = '';
      this.errors = [];
    },
    handleCancel() {
      this.resetData();
      this.$emit('cancel');
    },
  },
};
</script>

<style>
.error {
  color: indianred;
  font-size: medium;
}
</style>
