<template>
  <b-container>
    <b-form @submit.prevent="doLogin">
      <b-alert variant="danger" :show="!!error">
        {{ error }}
      </b-alert>
      <b-form-group id="username" label="Username" label-for="username-input">
        <b-form-input
          id="username-input"
          v-model="form.username"
          type="text"
          autocomplete="current-username"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="password" label="Password" label-for="password-input">
        <b-form-input
          id="password-input"
          v-model="form.password"
          type="password"
          autocomplete="current-password"
          required
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary">Log In</b-button>
    </b-form>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      form: {
        username: null,
        password: null,
      },
      error: null,
    };
  },
  computed: {
    ...mapState('user', {
      user: state => state.user,
    }),
  },
  methods: {
    ...mapActions('user', {
      processLogin: 'processLogin',
    }),
    async doLogin() {
      try {
        await this.processLogin(this.form);
        this.error = null;
        // added to redirect to previous URL after login
        await this.$router.replace(this.$route.query.from);
        //await this.$router.push({ name: 'dashboard' });
      } catch (err) {
        console.error('invalid login', err.response.data);
        if (err.response.status === 422) {
          this.error = err.response.data;
        }
      }
    },
  },
};
</script>
