<template>
  <b-form
    @submit.stop.prevent="handleSubmit"
    @reset.stop.prevent="handleCancel"
  >
    <b-overlay :show="isSubmitting">
      <b-container fluid>
        <b-form-group label="Manufacturer">
          <div
            v-if="createOrEditMode !== 'create' && selectedMf && mfDisplayMode"
          >
            <h5>
              {{ selectedMf.strManufacturer }}
              <b-button
                variant="link"
                size="sm"
                class="ml-3 px-0 py-0 align-text-bottom"
                @click.prevent="mfDisplayMode = false"
              >
                Change
              </b-button>
            </h5>
          </div>
          <manufacturer-typeahead
            v-else
            :init-selected-item="selectedMf"
            :init-items="getMfsSorted"
            @set-item="handleSelectMf"
          />
        </b-form-group>

        <hr />

        <b-form-group label="Type and Type Aliases">
          <div
            v-if="
              createOrEditMode !== 'create' && selectedType && typeDisplayMode
            "
          >
            <h5>
              {{ selectedType.strType }}
              <b-button
                variant="link"
                size="sm"
                class="ml-3 px-0 py-0 align-text-bottom"
                @click.prevent="typeDisplayMode = false"
              >
                Change
              </b-button>
            </h5>
            <div v-if="typeAliases.length" class="small">
              <label class="font-weight-bold">Type Aliases:</label>
              {{ selectedAliasTypesNames.join(', ') }}
            </div>
          </div>
          <div v-else>
            <type-typeahead
              :init-selected-item="selectedType"
              :init-items="getTypesSorted"
              @set-item="handleSelectType"
            />

            <details class="mb-2" open>
              <summary>
                Type Aliases
                <small class="ml-2 text-muted">
                  Additional types for which this model should be matched
                </small>
              </summary>
              <type-alias-selector
                :available-aliases="getTypesKeyedById"
                :current-alias-ids="typeAliasIds"
                :parent-alias-id="selectedType.intID || 0"
                @change="updateTypeAliases"
              />
            </details>
          </div>
        </b-form-group>

        <hr />

        <b-form-group
          label="Canonical Model Name and Aliases"
          label-for="input_canonical"
        >
          <b-form-input
            id="input_canonical"
            v-model="canonical"
            type="text"
            required
            debounce="700"
            autocomplete="off"
            :state="canonicalValid"
            :formatter="modelFormatter"
          ></b-form-input>
          <small class="text-muted">The canonical name, always UPPERCASE</small>
          <details class="mt-2" open>
            <summary>
              Model Name Aliases
              <small class="ml-2 text-muted">
                Additional model names that should be matched to this canonical
              </small>
            </summary>
            <model-alias-selector
              :existing-aliases="aliases"
              @aliases-updated="handleAliasesUpdated"
            />
          </details>
        </b-form-group>

        <hr />

        <b-form-group label="Categories">
          <b-list-group v-if="categories">
            <b-list-group-item
              button
              @click="unselectCategory(c.id)"
              v-for="c in categories"
              :key="c.id"
            >
              <span class="mr-3"><b-icon-check-circle-fill /></span>
              {{ c.en.category }}
              <span class="mx-1">›</span>
              {{ c.en.subcategory }}
            </b-list-group-item>
          </b-list-group>

          <typeahead
            ref="catTypeahead"
            label="Add..."
            :items="unselectedFormattedCategories"
            :disable-append="true"
            @set-query="selectCategoryTypeahead"
            item-label-key="text"
            :min-query-length="0"
          />

          <suggested-categories-picker
            :available-categories="unselectedFormattedCategories"
            :compare-value="selectedType.strType || ''"
            @item-click="selectCategoryTypeahead"
          />
        </b-form-group>

        <template v-if="useOwnButtons">
          <b-button @click.prevent.stop="handleSubmit" class="mr-2">
            Save
          </b-button>
          <b-button type="reset" variant="danger">Cancel</b-button>
        </template>
      </b-container>
    </b-overlay>
  </b-form>
</template>

<script>
import Typeahead from '@/components/Typeahead';
import { mapActions, mapState, mapGetters } from 'vuex';
import api from '@/api';
import ManufacturerTypeahead from '@/components/Typeaheads/ManufacturerTypeahead';
import TypeTypeahead from '@/components/Typeaheads/TypeTypeahead';
import TypeAliasSelector from '@/components/TypeAliasSelector';
import ModelAliasSelector from '@/components/ModelAliasSelector';
import SuggestedCategoriesPicker from '@/components/SuggestedCategoriesPicker';

export default {
  name: 'ModelForm',
  components: {
    SuggestedCategoriesPicker,
    ModelAliasSelector,
    TypeAliasSelector,
    TypeTypeahead,
    ManufacturerTypeahead,
    Typeahead,
  },
  data() {
    return {
      isSubmitting: false,
      mfDisplayMode: true,
      typeDisplayMode: true,
      formModel: Object.assign({}, this.model),
      canonical: `${this.model.strName}`,
      canonicalDirty: false,
      canonicalValid: null,
      aliases: [...this.model.alias_patterns],
      typeAliases: [...this.model.type_aliases],
      typeAliasIds: this.model.type_aliases.map(ta => ta.intTypeID),
      categories: [...this.model.categories],
      selectedMf: null,
      selectedType: { strType: '', intID: 0 },
    };
  },
  props: {
    createOrEditMode: {
      type: String,
      default: 'edit',
      validator: value => ['edit', 'create'].indexOf(value) !== -1,
    },
    useOwnButtons: {
      type: Boolean,
      default: true,
    },
    model: {
      type: Object,
      default: () => ({
        strName: '',
        alias_patterns: [],
        categories: [],
        type_aliases: [],
      }),
    },
  },
  async created() {
    this.handleSelectMf(this.getMfById(this.model.intManufacturerID));
    this.handleSelectType(this.getTypeById(this.model.intTypeID));
    await this.loadCategories();
  },
  computed: {
    ...mapState('models', {
      availableCategories: state => state.categories,
      categoriesLoaded: state => state.categoriesLoaded,
    }),
    ...mapGetters('models', {
      catById: 'getCatById',
      getTypeById: 'getTypeById',
      getTypesKeyedById: 'getTypesKeyedById',
      getTypesSorted: 'getTypesSorted',
      getMfById: 'getManufacturerById',
      getMfsSorted: 'getMfsSorted',
    }),
    selectedAliasTypes() {
      return this.typeAliasIds.map(ta => this.getTypeById(ta));
    },
    selectedAliasTypesNames() {
      return this.selectedAliasTypes.map(type => type.strType);
    },
    formData() {
      return {
        mfId: this.selectedMf.intID,
        typeId: this.selectedType.intID,
        canonical: this.canonical,
        aliases: this.aliases
          .filter(m => m.deleted !== true)
          .map(m => m.pattern),
        categoryIds: this.categories.map(c => c.intID),
        typeAliasIds: this.typeAliasIds,
      };
    },
    selectedCategoryIds() {
      return this.categories.map(c => {
        return c.id;
      });
    },
    unselectedFormattedCategories() {
      return this.formattedCategories.filter(c => {
        return this.selectedCategoryIds.indexOf(c.id) < 0;
      });
    },
    formattedCategories() {
      return this.availableCategories.map(cat => {
        const category = cat.strCategory.trim();
        const subcategory = cat.strSubcategory.trim();
        const text = `${category} › ${subcategory}`;
        return {
          id: cat.intID,
          text,
        };
      });
    },
  },
  watch: {
    canonical() {
      this.canonicalValid = null;
      this.checkCanonical();
    },
  },
  methods: {
    modelFormatter: value => value.toUpperCase(),
    async checkCanonical() {
      console.log('checking model value');
      const { intManufacturerID, intTypeID, intID } = this.model;
      const filter = {
        strName: this.canonical.trim(),
        intManufacturerID,
        intTypeID,
      };
      const results = await api.getModels(filter);
      const existing = results.data.filter(r => {
        return r.strName === this.canonical && r.intID !== intID;
      });
      if (existing.length) {
        this.canonicalValid = false;
        window.alert('A model already exists with this name');
      } else {
        this.canonicalValid = true;
      }
      this.canonicalDirty = false;
    },
    ...mapActions('models', {
      loadCategories: 'loadCategories',
    }),
    handleAliasesUpdated(aliases) {
      this.aliases = aliases;
    },
    updateTypeAliases(aliasIds) {
      this.typeAliasIds = aliasIds;
    },
    addTypeAlias(aliasId) {
      if (!this.typeAliasIds.includes(aliasId)) {
        this.typeAliasIds.push(aliasId);
      }
    },
    unselectCategory(id) {
      this.categories = this.categories.filter(c => {
        return c.intID !== id;
      });
    },
    selectCategoryTypeahead(cat) {
      this.categories = [...this.categories, this.catById(cat.id)];
      this.$refs.catTypeahead.clear();
    },
    async handleSubmit() {
      this.isSubmitting = true;
      // need to handle update vs. create here
      try {
        let response = { status: 'unsubmitted' };
        if (this.createOrEditMode === 'create') {
          response = await api.createNewCanonical(this.formData);
        } else {
          response = await api.updateCanonical(
            this.formModel.intID,
            this.formData,
          );
        }

        if (response.status === 'success' && response.model) {
          this.$emit('submit', response.model);
          this.resetData();
        }
      } catch (err) {
        console.error(err, err.response.data);
        window.alert(err.response.data.message || 'An unknown error occurred');
      }
      this.isSubmitting = false;
    },
    handleSelectMf(mf) {
      this.selectedMf = mf === null ? { name: '' } : mf;
    },
    handleSelectType(type) {
      if (
        typeof this.selectedType.intID !== 'undefined' &&
        this.selectedType.intID !== 0 &&
        this.selectedType.intID !== type.intID
      ) {
        const prevSelectedType = this.selectedType;
        this.addTypeAlias(prevSelectedType.intID);
      }
      this.selectedType = type === null ? { name: '' } : type;
    },
    resetData() {
      this.canonical = `${this.model.strName}`;
      this.aliases = [...this.model.alias_patterns];
      this.categories = [...this.model.categories];
      this.newAlias = null;
    },
    handleCancel() {
      console.log('cancelling form');
      this.resetData();
      this.$emit('cancel');
    },
  },
};
</script>
