<template>
  <typeahead
    v-if="items.length"
    :label="label"
    :selected-item="initSelectedItem"
    :items="items"
    :item-id-key="itemIdKey"
    @set-query="handleSetItem"
    @clear-query="handleClearItem"
    :min-query-length="0"
    :show-item-id="true"
  />
</template>

<script>
import Typeahead from '@/components/Typeahead';
import { mapGetters } from 'vuex';

export default {
  name: 'ManufacturerTypeahead',
  components: { Typeahead },
  props: {
    label: {
      type: String,
      default: 'Make',
    },
    initItems: {
      type: Array,
      default: () => [],
    },
    initSelectedItem: {
      type: Object,
      default: () => ({ name: null, intID: 0 }),
    },
    itemIdKey: {
      type: String,
      default: 'intID',
    },
  },
  data() {
    return {
      items: [...this.initItems],
    };
  },
  created() {
    if (!this.items.length) {
      this.items = this.getMfsSorted();
    }
  },
  computed: {
    ...mapGetters('models', {
      getManufacturersKeyedById: 'getManufacturersKeyedById',
      getMfsSorted: 'getMfsSorted',
    }),
  },
  methods: {
    handleSetItem(item) {
      this.$emit('set-item', item);
    },
    handleClearItem() {
      this.$emit('set-item', null);
    },
  },
};
</script>
