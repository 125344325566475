<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <b-input-group>
          <b-form-input
            v-model="filter"
            type="search"
            :placeholder="$t('alllistings.type_to_filter')"
            debounce="500"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">{{
              $t('alllistings.clear')
            }}</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col>
        <b-input-group prepend="Location:">
          <b-form-select
            v-model="location"
            :options="locations"
            value-field="locationId"
            text-field="locationName"
          >
          </b-form-select>
        </b-input-group>
      </b-col>
      <b-col>
        <b-input-group :prepend="$t('alllistings.listings')">
          <b-form-select v-model="active">
            <b-form-select-option value="0">{{
              $t('alllistings.all')
            }}</b-form-select-option>
            <b-form-select-option value="1">{{
              $t('alllistings.only_active')
            }}</b-form-select-option>
            <b-form-select-option value="2">{{
              $t('alllistings.only_archived')
            }}</b-form-select-option>
          </b-form-select>
        </b-input-group>
      </b-col>
      <b-col>
        <b-button @click="refineReport">{{
          $t('alllistings.refine_report')
        }}</b-button>
      </b-col>
      <b-col class="text-right">
        <download-report></download-report>
      </b-col>
    </b-row>
    <b-row class="mr-1 ml-1 mb-3 p-2 bg-dark text-white rounded">
      <b-col
        >{{ $t('totalsrow.total_rows') }}:
        <strong>{{ formatNumber(tableTotals.totalRows) }}</strong></b-col
      >
      <b-col
        >{{ $t('totalsrow.total_detail_page_views') }}:
        <strong>{{ formatNumber(tableTotals.detailViewTotal) }}</strong></b-col
      >
      <b-col md="auto"
        >{{ $t('totalsrow.total_search_page_views') }}:
        <strong>{{ formatNumber(tableTotals.srpViewTotal) }}</strong></b-col
      >
      <b-col
        >{{ $t('totalsrow.total_email_leads') }}:
        <strong>{{ formatNumber(tableTotals.emailLeadsTotal) }}</strong></b-col
      >
      <b-col
        >{{ $t('totalsrow.total_phone_clicks') }}:
        <strong>{{ formatNumber(tableTotals.phoneCallLeadsTotal) }}</strong>
        <span v-b-tooltip.hover="tooltipRowData" class="float-right"
          ><b-icon-info-circle></b-icon-info-circle></span
      ></b-col>
    </b-row>
    <b-row>
      <b-col>
        <span v-b-tooltip.hover="tooltipData" class="float-right"
          ><b-icon-info-circle></b-icon-info-circle
        ></span>
      </b-col>
    </b-row>
    <b-table
      responsive
      sticky-header="1000px"
      striped
      hover
      no-provider-sorting
      no-provider-filtering
      sort-icon-left
      :fields="fields"
      :items="items"
      :filter="filter"
      :busy="isBusy"
      :per-page="perPage"
      :current-page="currentPage"
      ref="table"
      show-empty
    >
      <template #empty="scope">
        <p class="text-center font-weight-bold text-danger">
          {{ scope.emptyText }}
        </p>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(intListingID)="data">
        <a
          v-if="!data.item.blnArchive"
          :href="`https://www.agdealer.com/${data.value}`"
          target="_blank"
          rel="external"
          >{{ data.value }}</a
        >
        <span v-else class="text-nowrap">
          {{ data.value }}
        </span>
        <div class="small text-muted">
          {{ data.item.strLocationName }}
        </div>
      </template>
      <template #cell(intPrice)="data">${{ data.value }}</template>
      <template #cell(dblAverageOfPrice)="data">${{ data.value }} </template>
      <template #cell(blnArchive)="data">
        <b-icon-x-circle
          v-if="data.item.blnArchive"
          title="Archived"
          class="ml-2 small"
        />
        <b-icon-check-circle
          variant="success"
          v-else
          title="Archived"
          class="ml-2 small"
        />
      </template>
      <template #cell(strBeginDate)="data">
        <span :title="data.value" class="text-nowrap">
          {{ dateMedium(data.value) }}
        </span>
      </template>
      <template #head()="data">
        <span>{{ $t('alllistings.' + data.label) }}</span>
      </template>
      <template #head(intSrpViews)="data">
        <span
          >{{ $t('alllistings.' + data.label) }}
          <b-icon-info-circle id="sprViews"></b-icon-info-circle>
          <b-tooltip target="sprViews" triggers="hover"
            >Number of search page views for this listing.</b-tooltip
          ></span
        >
      </template>
      <template #head(intDetailViews)="data">
        <span
          >{{ $t('alllistings.' + data.label) }}
          <b-icon-info-circle id="detailViews"></b-icon-info-circle>
          <b-tooltip target="detailViews" triggers="hover"
            >Number of detail views for this listing.</b-tooltip
          ></span
        >
      </template>
    </b-table>
    <b-row>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from '../../../api/index';
import dateFormat from 'dateformat';
import DownloadReport from '../../DownloadReport';
import { mapState } from 'vuex';

export default {
  name: 'ByListingDatatable',
  components: { DownloadReport },
  data() {
    return {
      fields: [
        {
          key: 'blnArchive',
          label: 'listing_status',
        },
        {
          key: 'intListingID',
          label: 'agDealer_#',
          sortable: true,
          thClass: 'header-width',
        },
        {
          key: 'intStockNumber',
          label: 'stock_#',
          sortable: true,
        },
        { key: 'strSerial', label: 'serial', sortable: true },
        {
          key: 'intYear',
          label: 'year',
          sortable: true,
          formatter: this.formatNumber,
        },
        {
          key: 'strManufacturerName',
          label: 'manufacturer_name',
          sortable: true,
        },
        {
          key: 'strModelName',
          label: 'model_name',
          sortable: true,
        },
        {
          key: 'strTypeName',
          label: 'type_name',
          sortable: true,
        },
        {
          key: 'intPrice',
          label: 'price',
          sortable: true,
          formatter: this.formatNumber,
        },
        { key: 'intImages', label: 'images', sortable: true },
        { key: 'strBeginDate', label: 'date_created' },
        {
          key: 'dtArchiveDate',
          label: 'date_archived',
          formatter: this.dateMedium,
        },
        {
          key: 'intDaysListed',
          label: 'days_listed',
          sortable: true,
          formatter: this.formatNumber,
        },
        {
          key: 'intSrpViews',
          label: 'search_page_views',
          sortable: true,
          formatter: this.formatCount,
        },
        {
          key: 'dtLastSrpView',
          label: 'search_page_last_viewed',
          sortable: true,
          formatter: this.formatDate,
        },
        {
          key: 'intDetailViews',
          label: 'detail_page_views',
          sortable: true,
          formatter: this.formatCount,
        },
        {
          key: 'dtLastDetailView',
          label: 'detail_last_viewed',
          sortable: true,
          formatter: this.formatDate,
        },
        {
          key: 'intEmailLeads',
          label: 'email_leads',
          sortable: true,
          formatter: this.formatCount,
        },
        {
          key: 'intPhoneCallLeads',
          label: 'phone_clicks',
          sortable: true,
          formatter: this.formatCount,
        },
      ],
      isBusy: false,
      items: [],
      allItems: [],
      tableTotals: {},
      perPage: 100,
      currentPage: 1,
      filter: null,
      active: 1,
      locations: [],
      location: -1,
      activeStates: [
        { value: 0, text: 'All' },
        { value: 1, text: 'Only Active' },
        { value: 2, text: 'Only Archived' },
      ],
      tooltipData: {
        title:
          '<p>AgDealer # → AgDealer listing number.</p><p>Stock # → Dealer’s stock number.</p><p>Serial # → Serial number for the listing.</p><p>Year → Year of the listing item.</p><p>Manufacturer → Manufacturer for the listing item.</p><p>Model Name → Model name of the listing item.</p><p>Type Name → Type of the listing item.</p><p>Price → Price of the listing item.</p><p>Images → Number of images for the listings item.</p><p>Dated Created → The date the listing item was created.</p><p>Days listed → Number of days the item has been listed for.</p><p>Search Page Views → Number of search page views for this listing.</p><p>Search Page Last Viewed → Most recent date the search page was viewed for this listing.</p><p>Detail Page Views  → Number of detail views for this listing.</p><p>Detail Last Viewed → Most recent date the detail page was viewed for this listing.</p><p>Email Leads → Number of email leads for this listing.</p><p>Phone Clicks → Number of phone clicks for this listing.</p>',
        html: true,
        placement: 'right',
        variant: 'secondary',
        customClass: 'wideToolTip',
      },
      tooltipRowData: {
        title:
          '<p>Total Rows → Total number of report rows for the client and date selected.</p><p>Total Detail Page Views → Summary of the detail page views for the client and date selected.</p><p>Total Search Page Views → Summary of search page views for the client and date selected.</p><p>Total Email Leads → Summary of email leads  for the client and date selected.</p><p>Total Phone Clicks → Summary of phone clicks for the client and date selected.</p>',
        html: true,
        placement: 'right',
        variant: 'secondary',
        customClass: 'mediumToolTip',
      },
    };
  },
  computed: {
    ...mapState('client', [
      'startDate',
      'endDate',
      'clientId',
      'selectedLanguage',
    ]),
    rows() {
      return this.items.length;
    },
  },
  methods: {
    formatNumber: value => (value ? parseInt(value) : 0),
    formatCount: value => (value ? parseInt(value).toLocaleString() : 0),
    formatDate: value =>
      value
        ? new Intl.DateTimeFormat('en-ca', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
          }).format(new Date(value))
        : 'Never',
    async itemsProvider() {
      if (this.clientId != null) {
        this.isBusy = true;
        const items = await api.getReportsClientByListing({
          intClientID: this.clientId,
          dtStartDate: this.startDate,
          dtEndDate: this.endDate,
          selectedLanguage: this.selectedLanguage,
        });

        this.generateLocationList(items.additional.clientLocations);

        if (undefined !== items.data) {
          this.items = items.data;
        } else {
          this.items = items;
        }
        this.allItems = this.items;
        this.firstLoad = false;
        this.isBusy = false;
        this.disabled = true;
        this.calculateTableTotals();
        this.refineReport();
        return this.items;
      }
    },
    calculateTableTotals() {
      this.tableTotals.totalRows = this.items.length;
      this.tableTotals.detailViewTotal = this.items.reduce(
        (prev, cur) => prev + parseInt(cur.intDetailViews),
        0,
      );
      this.tableTotals.srpViewTotal = this.items.reduce(
        (prev, cur) => prev + parseInt(cur.intSrpViews),
        0,
      );
      this.tableTotals.emailLeadsTotal = this.items.reduce(
        (prev, cur) => prev + parseInt(cur.intEmailLeads),
        0,
      );
      this.tableTotals.phoneCallLeadsTotal = this.items.reduce(
        (prev, cur) => prev + parseInt(cur.intPhoneCallLeads),
        0,
      );
    },
    dateMedium(date) {
      if (date) {
        return dateFormat(new Date(date), 'mediumDate');
      }
    },
    generateLocationList(clientLocations) {
      this.locations = clientLocations.map(locationItem => {
        return {
          locationId: locationItem.intID,
          locationName: locationItem.strLocationName,
        };
      });
      this.locations.unshift({
        locationId: -1,
        locationName: this.$t('reportsDetails.listings'),
      });
    },
    refineReport() {
      let activeOptions = ['all', false, true];
      if (this.active > 0 || this.active != null) {
        if (this.active > 0) {
          this.items = this.allItems.filter(
            i => i.blnArchive == activeOptions[this.active],
          );
          this.$store.dispatch('client/setArchivedStatus', this.active);
        } else {
          this.items = this.allItems;
          this.$store.dispatch('client/setArchivedStatus', 0);
        }
        if (this.location !== null && this.location !== -1) {
          this.$store.dispatch('client/setLocationId', this.location);
          this.items = this.items.filter(
            i => i.intLocationID === this.location,
          );
        } else {
          this.$store.dispatch('client/setLocationId', -1);
        }
      } else {
        this.items = this.allItems;
      }
      this.calculateTableTotals();
    },
  },
  mounted() {
    this.itemsProvider();
  },
};
</script>
