<template>
  <b-form @submit.prevent="handleFormPost">
    <dismissable-alert
      :message="errorMessage"
      :showError="showError"
    ></dismissable-alert>
    <b-overlay :show="isSubmitting">
      <h5>
        {{ mf.name }} › {{ type.name }}
        <b-button
          variant="link"
          size="sm"
          class="px-0 py-0 align-text-bottom"
          @click.prevent="typeDisplayMode = !typeDisplayMode"
        >
          (Change)
        </b-button>
      </h5>

      <sidebar-change-type-and-aliases
        v-if="!typeDisplayMode"
        :selected-type="selectedType"
        :type-alias-ids="typeAliasIds"
        @set-type="handleSelectType"
        @set-type-aliases="updateTypeAliases"
      ></sidebar-change-type-and-aliases>

      <b-form-group
        id="canonical-create-name"
        label="Create a new Canonical Model"
        label-for="canonical-create-name-1"
      >
        <b-input
          type="text"
          v-model="canonicalModelName"
          required
          :formatter="modelFormatter"
        />
      </b-form-group>

      <template v-if="modelAliases.length">
        <p>Model Aliases</p>
        <ul class="text-monospace">
          <li v-for="m in modelAliases" :key="m.id" class="small">
            {{ m }}
          </li>
        </ul>
      </template>

      <b-form-group label="Selected Categories">
        <b-list-group v-if="categories.selected">
          <b-list-group-item
            button
            @click="unselectCategory(c.id)"
            v-for="c in categories.selected"
            :key="c.id"
          >
            <span class="mr-3"><b-icon-check-circle-fill /></span>
            {{ c.text }}
          </b-list-group-item>
        </b-list-group>
        <p class="font-italic small" v-if="selectedCategoryIds.length">
          Click to remove
        </p>
        <typeahead
          ref="catTypeahead"
          label="Add"
          :items="unselectedCategories"
          :disable-append="true"
          @set-query="selectCategoryTypeahead"
          item-label-key="text"
          :min-query-length="0"
        />
        <suggested-categories-picker
          :available-categories="unselectedCategories"
          :compare-value="type.strType"
          @item-click="handleSuggestedCategoryClick"
        />
      </b-form-group>
      <b-form-group>
        <b-button type="submit" variant="success">Create</b-button>
      </b-form-group>
    </b-overlay>
  </b-form>
</template>

<script>
import Typeahead from '@/components/Typeahead';
import { mapActions, mapState, mapGetters } from 'vuex';
import foldToAscii from 'fold-to-ascii';
import SuggestedCategoriesPicker from '@/components/SuggestedCategoriesPicker';
import SidebarChangeTypeAndAliases from '@/components/UnmatchedListings/SidebarChangeTypeAndAliases';
import DismissableAlert from '@/components/Common/DismissableAlert';

export default {
  name: 'SidebarCreateNew',
  components: {
    SuggestedCategoriesPicker,
    Typeahead,
    SidebarChangeTypeAndAliases,
    DismissableAlert,
  },
  props: {
    selectedModels: Array,
    mfId: Number,
    mf: Object,
    type: Object,
  },
  async created() {
    this.handleSelectType(this.type);
  },
  async mounted() {
    await Promise.all([this.populateCategories(), this.loadTypes()]);
    this.setDefaultCanonicalName();
  },
  data() {
    return {
      canonicalModelName: null,
      typeDisplayMode: true,
      selectedType: { strType: '', intID: 0 },
      typeAliasIds: [],
      isSubmitting: false,
      showError: false,
      errorMessage: '',
      categories: {
        filter: '',
        options: [],
        selected: [],
      },
    };
  },
  watch: {
    selectedModels() {
      this.setDefaultCanonicalName();
    },
  },
  methods: {
    handleSuggestedCategoryClick(c) {
      this.selectCategoryTypeahead(c);
    },
    modelFormatter: value => value.toUpperCase(),
    async handleFormPost() {
      this.isSubmitting = true;
      this.showError = false;
      this.errorMessage = '';

      const result = await this.createNewCanonical({
        mfId: this.mfId,
        typeId: this.selectedType.intID,
        canonical: this.canonicalModelName,
        aliases: this.modelAliases,
        categoryIds: this.categories.selected.map(c => c.id),
        typeAliasIds: this.typeAliasIds,
        nameSearch: this.$route.query.nameSearch ?? null,
      });

      if (result.status === 'success') {
        this.$emit('update-table');
      } else {
        this.showError = true;
        this.errorMessage =
          result.response.data.message || 'An unknown error occurred';
      }

      this.isSubmitting = false;
    },
    setDefaultCanonicalName() {
      this.canonicalModelName = this.selectedModels[0].model || '';
    },
    selectCategoryTypeahead(cat) {
      this.categories.selected = [...this.categories.selected, cat];
      this.$refs.catTypeahead.clear();
    },
    unselectCategory(id) {
      this.categories.selected = this.categories.selected.filter(
        c => c.id !== id,
      );
    },
    async populateCategories() {
      if (!this.categoriesLoaded) {
        await this.loadCategories();
      }
      this.categories.options = this.availableCategories.map(cat => {
        const category = cat.strCategory.trim();
        const subcategory = cat.strSubcategory.trim();
        const text = `${category} › ${subcategory}`;
        return {
          id: cat.intID,
          text,
          ...cat,
        };
      });
    },
    handleSelectType(type) {
      if (
        typeof this.selectedType.intID !== 'undefined' &&
        this.selectedType.intID !== 0 &&
        this.selectedType.intID !== type.intID
      ) {
        const prevSelectedType = this.selectedType;
        this.addTypeAlias(prevSelectedType.intID);
      }
      this.selectedType = type === null ? { name: '' } : type;
    },
    addTypeAlias(aliasId) {
      if (!this.typeAliasIds.includes(aliasId)) {
        this.typeAliasIds.push(aliasId);
      }
    },
    updateTypeAliases(aliasIds) {
      this.typeAliasIds = aliasIds;
    },
    ...mapActions('models', {
      loadCategories: 'loadCategories',
      createNewCanonical: 'createNewCanonical',
      loadTypes: 'loadTypes',
    }),
  },
  computed: {
    modelAliases() {
      return this.selectedModels
        .filter(m => m.model !== this.canonicalModelName)
        .map(m =>
          // convert to lowercase, fold accents etc into ascii and collapse multiple spaces into one
          foldToAscii
            .foldMaintaining(m.model.toLowerCase())
            .replace(/\s+/g, ' '),
        );
    },
    selectedCategoryIds() {
      return this.categories.selected.map(s => s.id);
    },
    unselectedCategories() {
      return this.categories.options.filter(o => {
        return !this.selectedCategoryIds.includes(o.id);
      });
    },
    ...mapState('models', {
      availableCategories: state => state.categories,
      categoriesLoaded: state => state.categoriesLoaded,
    }),
    ...mapGetters('models', {
      getTypesSorted: 'getTypesSorted',
      getTypesKeyedById: 'getTypesKeyedById',
    }),
  },
};
</script>
