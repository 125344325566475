import VueRouter from 'vue-router';

import Dashboard from '@/pages/Dashboard';
import UnmatchedListings from '@/pages/UnmatchedListings';
import Login from '@/pages/Login';
import ReportsDetails from '@/pages/ReportsDetails';
import Reports from '@/pages/Reports';

import ModelCategories from '@/pages/ModelCategories';
import ModelDetails from '@/pages/ModelDetails';
import ModelsForMerging from '@/pages/ModelsForMerging';
import Models from '@/pages/Models';
import Tokens from '@/pages/Tokens';
import store from '../store';

const router = new VueRouter({
  mode: 'history',
  routes: [
    { name: 'dashboard', path: '/', component: Dashboard },
    { name: 'tokens', path: '/tokens', component: Tokens },
    { name: 'login', path: '/login', component: Login },
    {
      name: 'reports-details',
      path: '/reports-details',
      component: ReportsDetails,
      props: route => ({
        rep: route.query.rep,
        client: route.query.client,
        startDate: route.query.startDate,
        endDate: route.query.endDate,
        selectedTab: route.query.selectedTab,
        templateId: route.query.templateId,
      }),
    },
    {
      name: 'model-merges',
      path: '/model-merges',
      component: ModelsForMerging,
    },
    {
      name: 'models',
      path: '/models',
      component: Models,
    },
    {
      name: 'modelDetails',
      path: '/modelDetails/:modelId',
      component: ModelDetails,
      props: route => ({
        modelId: parseInt(route.params.modelId),
      }),
    },
    { name: 'unmatched', path: '/unmatched', component: UnmatchedListings },
    { name: 'categories', path: '/categories', component: ModelCategories },
    {
      name: 'reports-kris',
      path: '/reports-kris',
      component: Reports,
      props: route => ({ queryParams: route.query }),
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user');
  if (to.name !== 'login' && !loggedIn) {
    // added to redirect to previous URL after login
    next({ name: 'login', query: { from: to.fullPath } });
  } else {
    next();
  }
});

// Ensures all pending requests are cancelled on route change
router.beforeEach((to, from, next) => {
  store.dispatch('request/cancelPendingRequests');
  next();
});

export default router;
