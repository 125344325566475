<template>
  <typeahead
    v-if="items.length"
    :label="label"
    :selected-item="initSelectedItem"
    :items="initItems"
    item-label-key="strCategory"
    item-french-label-key="strCategoryF"
    @set-query="handleSetItem"
    @clear-query="handleClearItem"
    :min-query-length="0"
    :show-french-label="true"
  />
</template>

<script>
import Typeahead from '@/components/Typeahead';

export default {
  name: 'CategoryTypehead',
  components: { Typeahead },
  props: {
    label: {
      type: String,
      default: 'Category',
    },
    initItems: {
      type: Array,
      default: () => [],
    },
    initSelectedItem: {
      type: Object,
      default: () => ({ name: null, intID: 0 }),
    },
  },
  data() {
    return {
      items: [...this.initItems],
    };
  },
  methods: {
    handleSetItem(item) {
      this.$emit('set-item', item);
    },
    handleClearItem() {
      this.$emit('set-item', null);
    },
  },
};
</script>
