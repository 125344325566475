import api from '@/api';
import { nameSorter, keyById } from '@/common/helperFunctions';

export default {
  namespaced: true,

  state: () => ({
    manufacturers: [],
    models: [],
    types: [],
    categories: [],
    categoriesLoaded: false,
  }),

  getters: {
    getManufacturersKeyedById: state => keyById(state.manufacturers),
    getManufacturerById: (state, getters) => mfId => {
      return getters['getManufacturersKeyedById'][mfId] || null;
    },
    getTypesKeyedById: state => keyById(state.types),
    getTypeById: (state, getters) => typeId => {
      return getters['getTypesKeyedById'][typeId] || { strType: 'N/A' };
    },
    getCategoriesKeyedById: state => keyById(state.categories, 'intID'),
    getCatById: (state, getters) => catId => {
      return getters['getCategoriesKeyedById'][catId] || null;
    },
    getTypesSorted: state => state.types.sort(nameSorter),
    getMfsSorted: state => state.manufacturers.sort(nameSorter),
  },

  actions: {
    async updateCanonical(context, { id, params }) {
      return api.updateCanonical(id, params);
    },
    async createNewCanonical(context, params) {
      return api.createNewCanonical(params);
    },
    async loadCategories({ commit, state }, force = false) {
      if (!state.categoriesLoaded || force) {
        return api.getModelCategories().then(data => {
          commit('setCategories', data.data);
          commit('setCategoriesLoaded', true);
        });
      }
    },
    async loadModel(modelId) {
      return api.getModel(modelId).then(data => {
        return data;
      });
    },
    async loadModels(
      { commit },
      payload = {
        paginationParams: {},
        mfId: null,
        typeId: null,
        nameSearch: null,
      },
    ) {
      const { paginationParams, mfId, typeId, nameSearch } = payload;

      return api
        .getModels(paginationParams, mfId, typeId, nameSearch)
        .then(data => {
          commit('setModels', data.data);
          return new Promise(resolve => {
            resolve(data);
          });
        });
    },
    async loadManufacturers({ commit, state }) {
      if (state.manufacturers.length) {
        return true;
      }
      return api.getManufacturers().then(data => {
        commit('setManufacturers', data);
      });
    },
    async loadTypes({ commit, state }) {
      if (state.types.length) {
        return true;
      }
      return api.getTypes().then(data => {
        commit('setTypes', data);
      });
    },
  },

  mutations: {
    setCategories(state, payload) {
      state.categories = payload;
    },
    setCategoriesLoaded(state, payload) {
      state.categoriesLoaded = payload;
    },
    setModels(state, payload) {
      state.models = payload;
    },
    setManufacturers(state, payload) {
      state.manufacturers = payload;
    },
    setTypes(state, payload) {
      state.types = payload;
    },
  },
};
