<template>
  <section class="ml-4">
    <b-button v-b-modal.create-new>
      <b-icon-plus-square class="mr-2" />
      Create New
    </b-button>

    <b-modal
      id="create-new"
      scrollable
      size="lg"
      title="Create New Canonical Model"
      @ok="handleOk"
    >
      <b-container fluid>
        <model-form
          ref="form"
          :use-own-buttons="false"
          create-or-edit-mode="create"
          @submit="handleFormSubmitted"
        />
      </b-container>
    </b-modal>
  </section>
</template>

<script>
import ModelForm from '@/components/ModelDetails/ModelForm';
export default {
  name: 'CreateNewModelModal',
  components: { ModelForm },
  methods: {
    handleFormSubmitted(model) {
      this.$emit('model-created', model);
    },
    async handleOk(bvModelEvt) {
      bvModelEvt.preventDefault();
      await this.$refs.form.handleSubmit();
    },
  },
};
</script>
