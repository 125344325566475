<template>
  <div>
    <b-row class="mb-3">
      <b-col class="text-right">
        <download-report></download-report>
      </b-col>
    </b-row>
    <b-row class="mr-1 ml-1 mb-3 p-2 bg-dark text-white rounded">
      <b-col
        ><strong>{{ $t('totalsrow.total_rows') }}:</strong>
        {{ formatNumber(tableTotals.totalRows) }}
      </b-col>
      <b-col
        ><strong>{{ $t('totalsrow.total_detail_page_views') }}:</strong>
        {{ formatNumber(tableTotals.detailViewTotal) }}
      </b-col>
      <b-col md="auto"
        ><strong>{{ $t('totalsrow.total_search_page_views') }}:</strong>
        {{ formatNumber(tableTotals.srpViewTotal) }}
      </b-col>
      <b-col
        ><strong>{{ $t('totalsrow.total_email_leads') }}:</strong>
        {{ formatNumber(tableTotals.emailLeadsTotal) }}
      </b-col>
      <b-col
        ><strong>{{ $t('totalsrow.total_phone_clicks') }}:</strong>
        {{ formatNumber(tableTotals.phoneCallLeadsTotal) }}
        <span v-b-tooltip.hover="tooltipRowData" class="float-right"
          ><b-icon-info-circle></b-icon-info-circle
        ></span>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <span v-b-tooltip.hover="tooltipData" class="float-right"
          ><b-icon-info-circle></b-icon-info-circle
        ></span>
      </b-col>
    </b-row>
    <b-table
      responsive
      striped
      hover
      no-provider-sorting
      no-provider-filtering
      sort-icon-left
      :fields="fields"
      :items="itemsProvider"
      :busy="isBusy"
      ref="table"
      show-empty
    >
      <template #empty="scope">
        <p class="text-center font-weight-bold text-danger">
          {{ scope.emptyText }}
        </p>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(strLocationName)="data">
        <span class="text-nowrap">{{ data.value }}</span>
      </template>
      <template #head()="data">
        <span>{{ $t('location.' + data.label) }}</span>
      </template>
      <template #head(intSrpViews)="data">
        <span
          >{{ $t('location.' + data.label) }}
          <b-icon-info-circle id="sprViews"></b-icon-info-circle>
          <b-tooltip target="sprViews" triggers="hover"
            >Number of search page views for this listing.</b-tooltip
          ></span
        >
      </template>
      <template #head(intDetailViews)="data">
        <span
          >{{ $t('location.' + data.label) }}
          <b-icon-info-circle id="detailViews"></b-icon-info-circle>
          <b-tooltip target="detailViews" triggers="hover"
            >Number of detail views for this listing.</b-tooltip
          ></span
        >
      </template>
    </b-table>
  </div>
</template>

<script>
import api from '../../../api/index';
import DownloadReport from '../../DownloadReport';
import { mapState } from 'vuex';

export default {
  name: 'ByLocationDatatable',
  components: { DownloadReport },
  data() {
    return {
      fields: [
        { key: 'strLocationName', label: 'location' },
        {
          key: 'intSrpViews',
          label: 'search_page_views',
          sortable: true,
          formatter: this.formatNumber,
        },
        {
          key: 'intDetailViews',
          label: 'detail_page_views',
          sortable: true,
          formatter: this.formatNumber,
        },
        {
          key: 'intPhoneCallLeads',
          label: 'phone_clicks',
          sortable: true,
          formatter: this.formatNumber,
        },
        {
          key: 'intEmailLeads',
          label: 'emails_leads',
          sortable: true,
        },
        {
          key: 'intAverageImages',
          label: 'average_number_of_images',
          sortable: true,
          formatter: this.roundItem,
        },
      ],
      items: [],
      isBusy: false,
      firstLoad: true,
      tableTotals: {},
      tooltipData: {
        title:
          '<p>Location → Dealer location for the listing item.</p><p>Search Page Views → Number of search page views for this location.</p><p>Detail Page Views → Number of detail page views for this location.</p><p>Phone Clicks → Number of phone clicks for this location.</p><p>Email Leads  → Number of email leads for this location.</p><p>Average # of Images → Insert tooltip text here</p>',
        html: true,
        placement: 'right',
        variant: 'secondary',
      },
      tooltipRowData: {
        title:
          '<p>Total Rows → Total number of report rows for the client and date selected.</p><p>Total Detail Page Views → Summary of the detail page views for the client and date selected.</p><p>Total Search Page Views → Summary of search page views for the client and date selected.</p><p>Total Email Leads → Summary of email leads  for the client and date selected.</p><p>Total Phone Clicks → Summary of phone clicks for the client and date selected.</p>',
        html: true,
        placement: 'right',
        variant: 'secondary',
        customClass: 'mediumToolTip',
      },
    };
  },
  computed: {
    ...mapState('client', ['startDate', 'endDate', 'clientId']),
    rows() {
      return this.items.length;
    },
  },
  methods: {
    roundItem: value => Math.round(value),
    formatNumber: value => parseInt(value).toLocaleString(),
    async itemsProvider() {
      this.isBusy = true;
      const response = await api.getReportsClientByLocation({
        intClientID: this.clientId,
        dtStartDate: this.startDate,
        dtEndDate: this.endDate,
      });

      this.items = response.data;

      this.firstLoad = false;
      this.isBusy = false;
      this.calculateTableTotals();
      return this.items;
    },
    calculateTableTotals() {
      this.tableTotals.totalRows = this.items.length;
      this.tableTotals.detailViewTotal = this.items.reduce(
        (prev, cur) => prev + parseInt(cur.intDetailViews),
        0,
      );
      this.tableTotals.srpViewTotal = this.items.reduce(
        (prev, cur) => prev + parseInt(cur.intSrpViews),
        0,
      );
      this.tableTotals.emailLeadsTotal = this.items.reduce(
        (prev, cur) => prev + parseInt(cur.intEmailLeads),
        0,
      );
      this.tableTotals.phoneCallLeadsTotal = this.items.reduce(
        (prev, cur) => prev + parseInt(cur.intPhoneCallLeads),
        0,
      );
    },
  },
};
</script>
