<template>
  <b-container fluid>
    <h2 class="mb-3">Reports</h2>
    <b-row>
      <b-col cols="3">asdasd</b-col>
      <b-col cols="3">asdasd</b-col>
      <b-col>
        <date-range class="mb-5" @selected="updateDateRange" />
      </b-col>
      <b-col cols="3">asdadasdasdasd</b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card-group deck class="mb-5">
          <b-card
            class="clickable"
            @click="selectReportCard(cardId)"
            v-for="cardId in [0, 1, 2, 3]"
            :class="{ focussed: selectedReportCard === cardId }"
            :key="cardId"
          >
            <b-card-text class="text-center" />
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          :fields="exampleByLocationDataTableFields"
          :items="exampleByLocationDataTableItems"
        />
      </b-col>
    </b-row>
    <b-row class="py-5">
      <b-col>
        <b-table
          :fields="exampleListingsTableFields"
          :items="exampleListingsTableItems"
          responsive
        />
      </b-col>
    </b-row>
    <b-row class="py-5">
      <b-col>
        <b-table
          :fields="exampleImpressionsLeadsFields"
          :items="exampleImpressionsLeadsItems"
        >
          <template #thead-top>
            <b-tr>
              <b-th class="border-bottom-0" />
              <b-th colspan="2" class="bg-success border-bottom-0">
                Impressions
              </b-th>
              <b-th colspan="6" class="bg-warning border-bottom-0">
                Leads
              </b-th>
            </b-tr>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table :items="getTabularData" outlined />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <apexchart
          width="100%"
          height="300"
          type="bar"
          :options="chartState.options"
          :series="chartState.series"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from '@/api';
import DateRange from '@/components/DateRange.vue';

import {
  computed,
  ref,
  inject,
  watch,
  onBeforeMount,
} from '@vue/composition-api';

export default {
  components: {
    DateRange,
  },
  name: 'Reports',
  props: ['queryParams'],
  setup(props, { root: { $router, $route } }) {
    // STATE
    const store = inject('store');

    const dateRange = ref({});

    // Test data for apex charts
    const reports = [
      {
        name: 'Series 1',
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        series: [30, 40, 45, 50, 49, 60, 70, 91],
      },
      {
        name: 'Series 2',
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        series: [110, 60, 90, 10, 26, 99, 15, 42],
      },
      {
        name: 'Series 3',
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        series: [69, 42, 16, 162, 75, 44, 70, 99],
      },
      {
        name: 'Series 4',
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        series: [110, 60, 90, 10, 26, 99, 15, 42],
      },
    ];

    // Table fields (columns)
    const booleanFormatter = val => (val ? 'Y' : 'N');

    const exampleListingsTableFields = [
      { key: 'intId', label: 'id', sortable: true },
      { key: 'strLocationName', label: 'Location', sortable: true },
      { key: 'strTypeName', label: 'Type', sortable: true },
      { key: 'strManufacturerName', label: 'Mfr. Name', sortable: true },
      { key: 'strModelName', label: 'Model', sortable: true },
      { key: 'strModelRawName', label: 'Model Raw', sortable: true },
      {
        key: 'intPrice',
        label: 'Price',
        sortable: true,
        formatter: price => {
          // need to use USD to omit the currency name
          // this does not affect the value
          return new Intl.NumberFormat('en-us', {
            style: 'currency',
            currency: 'USD',
          }).format(price);
        },
      },
      { key: 'intImages', label: 'Images', sortable: true },
      {
        key: 'bFeatured',
        label: 'Featured',
        sortable: true,
        formatter: booleanFormatter,
      },
      { key: 'strStockNumber', label: 'Stock #', sortable: true },
      { key: 'strSerial', label: 'Serial', sortable: true },
      {
        key: 'bArchived',
        label: 'Archived',
        sortable: true,
        formatter: booleanFormatter,
      },
      { key: 'intDetailViews', label: 'Detail', sortable: true },
      { key: 'intSrpViews', label: 'SRP', sortable: true },
      { key: 'intEmailViews', label: 'Email', sortable: true },
      { key: 'intPhoneCallViews', label: 'Phone Call', sortable: true },
      { key: 'intPrintableViews', label: 'Printable', sortable: true },
      { key: 'intWatchThisViews', label: 'Watch This', sortable: true },
      { key: 'dblAverageViews', label: 'Avg. Views', sortable: true },
      { key: 'dtBeginDate', label: 'Begin', sortable: true },
    ];

    const exampleByLocationDataTableFields = [
      { key: 'strLocation', label: 'Location', sortable: true },
      { key: 'dblAverageOfPrice', label: 'Avg. of Price' },
      { key: 'intAverageImages', label: 'Avg. of Images' },
      { key: 'intPhoneCallViews', label: 'Phone Call Views' },
      { key: 'intEmailViews', label: 'Email Views' },
      { key: 'intSrpViews', label: 'SRP Views' },
      { key: 'intDetailViews', label: 'Detail Views' },
      { key: 'intPrintableViews', label: 'Printable Views' },
      { key: 'intWatchThisViews', label: 'Watch This Views' },
      { key: 'dblAverageViews', label: 'Avg. Views' },
    ];

    const exampleImpressionsLeadsFields = [
      { key: 'dtDate', label: 'Date', sortable: true, thClass: 'border-top-0' },
      {
        key: 'intDetailViews',
        label: 'Detail Views',
        thClass: 'bg-success-brighter border-top-0',
      },
      {
        key: 'intSrpViews',
        label: 'SRP Views',
        thClass: 'bg-success-brighter border-top-0',
      },
      {
        key: 'intEmailViews',
        label: 'Email Views',
        thClass: 'bg-warning-brighter border-top-0',
      },
      {
        key: 'intPhoneCallViews',
        label: 'Phone Call Views',
        thClass: 'bg-warning-brighter border-top-0',
      },
      {
        key: 'intPrintableViews',
        label: 'Printable Views',
        thClass: 'bg-warning-brighter border-top-0',
      },
      {
        key: 'intWatchThisViews',
        label: 'Watch This Views',
        thClass: 'bg-warning-brighter border-top-0',
      },
      {
        key: 'dblAverageViews',
        label: 'Avg. Views',
        thClass: 'bg-warning-brighter border-top-0',
      },
      {
        key: 'intTotalLeads',
        thClass: 'bg-warning-brighter border-top-0',
        label: 'Total Leads',
        formatter: (_, __, i) => {
          return Object.keys(i)
            .slice(3, -1)
            .reduce((acc, cur) => {
              acc += parseInt(i[cur]);

              return acc;
            }, 0);
        },
      },
    ];

    // Refs for data to be loaded from api on mount
    const exampleListingsTableItems = ref([]);
    const exampleByLocationDataTableItems = ref([]);
    const exampleImpressionsLeadsItems = ref([]);

    // METHODS
    const setSelectedReport = idx =>
      store.dispatch('reports/setSelectedReport', idx);

    const selectReportCard = id => {
      if (selectedReportCard.value === id) return;

      setSelectedReport(id);

      $router.push({
        query: {
          reportCard: id,
        },
      });
    };

    const updateDateRange = evt => (dateRange.value = evt);

    // COMPUTED PROPERTIES
    const selectedReportCard = computed(
      () => store.getters['reports/getSelectedReport'],
    );
    const getCurrentReport = computed(() => reports[selectedReportCard.value]);

    const chartState = computed(() => ({
      options: {
        chart: {
          id: 'vuechart-example',
        },
        xaxis: {
          categories: getCurrentReport.value.categories,
        },
      },
      series: [
        {
          name: getCurrentReport.value.name,
          data: getCurrentReport.value.series,
        },
      ],
    }));

    const getTabularData = computed(() => {
      return chartState.value.options.xaxis.categories.reduce((acc, cur) => {
        acc = [
          ...acc,
          {
            year: cur,
            count:
              chartState.value.series[0].data[
                chartState.value.options.xaxis.categories.indexOf(cur)
              ],
          },
        ];

        return acc;
      }, []);
    });

    // Lifecycle Hooks
    onBeforeMount(async () => {
      exampleListingsTableItems.value = (
        await api.getReportByName('listing-stats')
      ).data;
      exampleByLocationDataTableItems.value = (
        await api.getReportByName('listing-locations')
      ).data;
      exampleImpressionsLeadsItems.value = (
        await api.getReportByName('listing-impressions-leads')
      ).data;

      setSelectedReport($route.query?.reportCard);
    });

    // Watches
    watch(
      () => props.queryParams,
      (val, oldValue) => {
        if (oldValue.reportCard !== val.reportCard) {
          setSelectedReport(val.reportCard);
        }
      },
    );

    return {
      chartState,
      exampleImpressionsLeadsFields,
      exampleImpressionsLeadsItems,
      exampleListingsTableFields,
      exampleListingsTableItems,
      exampleByLocationDataTableFields,
      exampleByLocationDataTableItems,
      selectReportCard,
      selectedReportCard,
      getTabularData,
      updateDateRange,
    };
  },
};
</script>

<style lang="scss">
.clickable:hover {
  cursor: pointer;
}
.focussed {
  border: none;
  box-shadow: inset 0px 0px 0px 3px lighten($secondary, 20%),
    0px 5px 15px rgba(0, 0, 0, 0.35);
  transition: box-shadow 200ms linear;
}
.bg-success-brighter {
  background-color: lighten($success, 30%);
}
.bg-warning-brighter {
  background-color: lighten($warning, 30%);
}
</style>
