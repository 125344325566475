<template>
  <section class="ml-4">
    <b-alert :variant="variant" v-model="showError" dismissible>
      <b><span v-html="message"></span></b>
    </b-alert>
  </section>
</template>

<script>
export default {
  name: 'DismissableAlert',
  props: {
    message: {
      type: String,
      default: 'Successfully created',
    },
    variant: {
      type: String,
      default: 'danger',
    },
    showError: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style>
.alert-danger a {
  color: #721c24;
  text-decoration: underline;
}
</style>
