<template>
  <div>
    <div :id="popoverId">
      <b-link :href="publicUrl" target="_blank" rel="external" v-html="id" />
      <div class="small text-muted" v-if="isArchived" style="line-height: 1.2">
        <b-icon icon="x-circle" variant="danger" title="Archived" />
        Archived
      </div>
      <div class="small text-muted" v-if="isIgnored" style="line-height: 1.2">
        <b-icon-eye-slash />
        Ignored
      </div>
    </div>
    <b-popover :target="popoverId" placement="right" triggers="hover">
      <template v-slot:title> Listing {{ id }} </template>
      <dl class="mt-2 mb-0">
        <dt>Listing Tools</dt>
        <dd>
          <ul class="mb-0 pb-0">
            <li>
              <b-link
                :href="publicUrl"
                target="_blank"
                rel="external"
                @click="trackGAEvent(labels.view)"
              >
                {{ labels.view }}
                <b-icon-box-arrow-up-right />
              </b-link>
            </li>
            <li>
              <b-link
                :href="ipsEditUrl"
                target="_blank"
                rel="external"
                @click="trackGAEvent(labels.edit)"
              >
                {{ labels.edit }}
                <b-icon-box-arrow-up-right />
              </b-link>
            </li>
            <li>
              <b-link button @click="emitIgnore" v-if="!isIgnored">
                {{ labels.ignore }}
              </b-link>
              <b-link button @click="emitRestore" v-else>
                {{ labels.restore }}
              </b-link>
            </li>
          </ul>
        </dd>
        <dt>Created</dt>
        <dd>{{ dateFormat(listing.dtCreated) }}</dd>
        <dt>Last Updated</dt>
        <dd>
          {{ dateFormat(listing.dtLastModifiedDate) }}
          ({{ listing.strLastUserName }})
        </dd>
      </dl>
    </b-popover>
  </div>
</template>

<script>
import { trackEvent } from '@/common/tracking';

export default {
  name: 'ListingIDCellContents',
  data: () => ({
    labels: {
      view: 'View on AgDealer.com',
      edit: 'Edit Listing in IPS',
      ignore: 'Ignore this listing',
      restore: 'Restore this listing',
    },
  }),
  props: {
    listing: Object,
  },
  computed: {
    id() {
      return this.listing.intID;
    },
    isArchived() {
      return this.listing.blnIsActive === false;
    },
    isIgnored() {
      return this.listing.blnIsIgnored === true;
    },
    publicUrl() {
      return `https://www.agdealer.com/${this.id}`;
    },
    ipsEditUrl() {
      return `https://www.agdealer.com/admin/listings/listing-edit.cfm?listingid=${this.id}&ClientID=${this.listing.intClientID}&ListingPage=listing`;
    },
    popoverId() {
      return `listing-${this.id}-id-popover`;
    },
  },
  methods: {
    dateFormat(date = null) {
      return null !== date ? new Date(date).toLocaleString('en-CA') : 'Never';
    },
    emitIgnore() {
      this.trackGAEvent(this.labels.ignore);
      this.$emit('ignore-listing');
    },
    emitRestore() {
      this.trackGAEvent(this.labels.restore);
      this.$emit('restore-listing');
    },
    trackGAEvent(name) {
      trackEvent('Unmatched ID popup', 'Click', name);
    },
  },
};
</script>
