<template>
  <div v-click-outside="hideDatePicker">
    <b-input-group class="input-container" @click.capture="toggleDatePicker">
      <b-input-group-prepend class="input-icon-container">
        <b-icon icon="calendar" class="input-icon" />
      </b-input-group-prepend>
      <b-form-input
        class="input"
        ref="input"
        :value="getFormattedRange"
        @change.native="handleInputChange($event)"
      />
    </b-input-group>
    <div
      class="date-range-picker"
      v-show="state.datePickerOpen"
      ref="datepickerPopup"
    >
      <b-calendar
        v-model="state.from.value"
        :date-info-fn="getSelectedDateClass"
        :locale="locale"
        :max="state.max.value"
        hide-header
        @selected="emitDateRange"
      />
      <b-calendar
        v-model="state.to.value"
        :date-info-fn="getSelectedDateClass"
        :date-disabled-fn="getToDateDisabled"
        :locale="locale"
        :max="state.max.value"
        hide-header
        @selected="emitDateRange"
      />
    </div>
  </div>
</template>

<script>
import dateFormat from 'dateformat';
import { computed, reactive, watch, onUpdated } from '@vue/composition-api';

const getTodayAndAdjacentDays = () => {
  return {
    tomorrow: new Date().setDate(new Date().getDate() + 1),
    yesterday: new Date().setDate(new Date().getDate() - 1),
  };
};

export default {
  emits: ['selected'],
  props: {
    locale: {
      type: String,
      default: 'en-CA',
    },
    dateRangeSeparator: {
      type: String,
      default: ' to ',
    },
    from: {
      type: String,
      default: (() => {
        const now = new Date();
        const defaultDate = new Date(now.setDate(now.getDate() - 7));

        return new Intl.DateTimeFormat('en-CA').format(defaultDate);
      })(),
    },
    to: {
      type: String,
      default: (() => {
        const now = new Date();
        const defaultDate = new Date(now.setDate(now.getDate() - 1));

        return new Intl.DateTimeFormat('en-CA').format(defaultDate);
      })(),
    },
    max: {
      type: Number,
      //Vue doesn't like when we create a new Date() object here because it's not a factory function
      //So instead we return a number and create Date object in props
      default: () => {
        const { yesterday } = getTodayAndAdjacentDays();
        return yesterday;
      },
    },
    dateFormatter: {
      type: Function,
      default: date => {
        return dateFormat(date, 'yyyy-mm-dd', true);
      },
    },
    rangeRegex: {
      type: RegExp,
      default: () => /(\d{4}-\d{1,2}-\d{1,2})([^\d]+(\d{4}-\d{1,2}-\d{1,2}))?/,
    },
  },
  setup(props, context) {
    // This could be expressed simpler in its current state but I
    // don't know where this is going to go during prototyping
    const state = reactive({
      datePickerOpen: false,
      from: {
        value: props.from,
      },
      to: {
        value: props.to,
      },
      max: {
        value: new Date(props.max),
      },
    });

    if (context.root.$route.query.startDate) {
      state.from.value = context.root.$route.query.startDate;
    }
    if (context.root.$route.query.endDate) {
      state.to.value = context.root.$route.query.endDate;
    }

    // METHODS

    // Bootstrap calendar requires yyyy-mm-dd format internally
    const _internalFormatter = date => dateFormat(date, 'yyyy-mm-dd', true);

    const emitDateRange = () =>
      context.emit('selected', { from: state.from, to: state.to });

    const handleInputChange = evt => {
      let newDateRange = evt.target.value;
      let splitRange = newDateRange.match(props.rangeRegex);

      if (splitRange) {
        if (splitRange[1] && splitRange[3]) {
          if (splitRange[3] < splitRange[1]) {
            [splitRange[3], splitRange[1]] = [splitRange[1], splitRange[3]];
          }

          state.from.value = _internalFormatter(new Date(splitRange[1]));

          let toDate = new Date(splitRange[3]);
          const { yesterday } = getTodayAndAdjacentDays();
          const yesterdayDate = new Date(yesterday);

          if (toDate > yesterdayDate) {
            state.to.value = _internalFormatter(yesterdayDate);
          } else {
            state.to.value = _internalFormatter(new Date(splitRange[3]));
          }
        } else if (splitRange[1]) {
          state.from.value = state.to.value = _internalFormatter(
            new Date(splitRange[1]),
          );
        }
      }
      evt.target.value = getFormattedRange.value;

      emitDateRange();
    };

    const hideDatePicker = () => (state.datePickerOpen = false);

    const getSelectedDateClass = (_, date) => {
      const from = new Date(state.from.value);
      const to = new Date(state.to.value);

      return date >= from && date <= to.setDate(to.getDate() + 1)
        ? 'table-primary'
        : '';
    };

    const getToDateDisabled = (_, date) => {
      const from = new Date(state.from.value);

      return date < from;
    };

    const toggleDatePicker = () => {
      state.datePickerOpen = !state.datePickerOpen;
    };

    // COMPUTED

    const getFormattedRange = computed(() => {
      const from = props.dateFormatter(state.from.value);
      const to = props.dateFormatter(state.to.value);

      return from + props.dateRangeSeparator + to;
    });

    // WATCHES

    /*
          Whenever "from" changes, we need to make sure that it isn't changing to a date beyond "to".
          If it is, we should maintain the distance between dates by recalculating "to".
         */
    watch(
      () => state.from.value,
      (newFromDate, oldFromDate) => {
        if (newFromDate === oldFromDate) return;

        const newFrom = new Date(newFromDate);
        const to = new Date(state.to.value);

        if (newFrom > to) {
          const oldFrom = new Date(oldFromDate);
          const diffDays = Math.ceil(
            1 + (to.getTime() - oldFrom.getTime()) / (1000 * 3600 * 24),
          );

          state.to.value = props.dateFormatter(
            new Date(newFrom.setDate(newFrom.getDate() + diffDays)),
          );
        }
      },
    );

    // LIFECYCLE HOOKS

    onUpdated(() => {
      if (!state.datePickerOpen) return;

      const datePickerPopup = context.refs.datepickerPopup;
      const datePickerPopupBounds = datePickerPopup.getBoundingClientRect();
      const inputBounds = context.refs.input.$el.getBoundingClientRect();

      const viewportWidth =
        window.innerWidth || document.documentElement.clientWidth;

      const isScreenWideEnoughToJustifyMove =
        viewportWidth > datePickerPopupBounds.width * 1.2;
      const shouldMoveToLeft =
        inputBounds.x + datePickerPopupBounds.width > viewportWidth;

      if (shouldMoveToLeft && isScreenWideEnoughToJustifyMove) {
        datePickerPopup.style.left = `${
          context.refs.input.$el.offsetLeft -
          datePickerPopupBounds.width +
          inputBounds.width +
          16
        }px`;
      } else {
        datePickerPopup.style.left = `${
          context.refs.input.$el.offsetLeft - 16
        }px`;
      }
    });

    return {
      state,
      emitDateRange,
      getFormattedRange,
      getSelectedDateClass,
      getToDateDisabled,
      handleInputChange,
      hideDatePicker,
      toggleDatePicker,
    };
  },
};
</script>

<style lang="scss" scoped>
.date-range-picker {
  position: absolute;
  background-color: $white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2em;
  padding: 1.25em;
  box-shadow: 0 0.5em 15px rgba(0, 0, 0, 0.25),
    inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  z-index: $zindex-dropdown;
}

.input {
  border-left: 0;

  &[readonly] {
    background-color: $input-bg;
  }
}

.input-container {
  cursor: pointer;

  &:hover > * {
    border-color: $secondary;
    box-shadow: $box-shadow-sm;
  }
}

.input-icon {
  display: inline-block;
  justify-self: center;
  align-self: center;
  width: 100%;
}

.input-icon-container {
  border: 1px solid $input-border-color;
  border-right: 0;
  border-radius: $input-border-radius 0 0 $input-border-radius;
  width: 32px;
}
</style>
