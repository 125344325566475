import Vue from 'vue';
import Vuex from 'vuex';
import models from './modules/models';
import reports from './modules/reports';
import user from './modules/user';
import unmatchedModels from './modules/unmatchedModels';
import request from './axiosCancelRequests';
import client from './modules/client';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    models,
    reports,
    unmatchedModels,
    user,
    request,
    client,
  },
});
