<template>
  <div>
    <engagement v-if="summaryLoaded" :leads="leads"></engagement>
    <current-status v-if="summaryLoaded" :data="data"></current-status>
    <online-views v-if="summaryLoaded" :views="views"></online-views>
    <b-img
      center
      class="mw-100"
      height="auto"
      v-show="this.logo"
      :src="this.logo"
    ></b-img>
  </div>
</template>

<script>
import api from '../../api/index';
import { mapState } from 'vuex';
import CurrentStatus from '@/components/Dashboard/CurrentStatus';
import OnlineViews from '@/components/Dashboard/OnlineViews';
import Engagement from '@/components/Dashboard/Engagement';

export default {
  components: {
    CurrentStatus,
    OnlineViews,
    Engagement,
  },
  data() {
    return {
      summaryLoaded: false,
      data: {},
      views: {},
      leads: {},
      logo: '',
    };
  },
  computed: {
    ...mapState('client', [
      'startDate',
      'endDate',
      'clientId',
      'clientLogoUrl',
    ]),
  },
  methods: {
    async getSummary() {
      const response = await api.getReportsBySummary({
        intClientID: this.clientId,
        dtStartDate: this.startDate,
        dtEndDate: this.endDate,
      });
      this.data = response.data[0];
      this.views = {
        impressions: response.counts ? response.counts.intImpressionsTotal : 0,
        detailViews: response.counts ? response.counts.intDetailViewTotal : 0,
        srp: response.counts ? response.counts.intSrpViewTotal : 0,
      };
      this.leads = {
        totalLeads: response.counts ? response.counts.intLeadsInquiryTotal : 0,
        emailLeads: response.counts ? response.counts.intEmailViewTotal : 0,
        phoneLeads: response.counts ? response.counts.intPhoneViewTotal : 0,
      };

      this.logo = response.logo;

      this.summaryLoaded = true;
      await this.$store.dispatch('client/setLogo', response.logo);
    },
  },
  mounted() {
    this.getSummary();
  },
};
</script>
