<template>
  <b-container>
    <b-row>
      <b-col>
        <h1>Personal Access Tokens</h1>
        <p>
          Use these tokens with a client like Postman. The token value will only
          be visible once when it is created, and is not retrievable after
          creation.
        </p>
        <p>
          To make an authenticated request, the token should be included in the
          <code>Authorization</code> header as a <code>Bearer</code> token.
        </p>
        <details>
          <summary>Example:</summary>
          <code>
            <pre>
GET https://agd-admin.docker.localhost/api/user
Accept: application/json
Authorization: Bearer 1|abcd123
            </pre>
          </code>
        </details>
        <hr />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h5>My Access Tokens</h5>
        <p v-if="!accessTokens.length">You have no access tokens yet!</p>
        <b-list-group v-else>
          <b-list-group-item
            v-for="(token, i) in accessTokens"
            :key="i"
            class="flex-column align-items-start"
          >
            <div class="d-flex w-100 justify-content-between">
              <div>
                <strong>{{ token.name }}</strong>
                <b-form-group
                  class="mt-2"
                  v-if="token.plainTextToken"
                  label="New token:"
                  description="Only shown once, be sure to copy this value!"
                >
                  <b-input-group>
                    <input
                      type="text"
                      class="form-control text-monospace"
                      :size="token.plainTextToken.length"
                      readonly
                      :value="token.plainTextToken"
                    />
                    <b-input-group-append>
                      <b-button
                        v-clipboard="() => token.plainTextToken"
                        v-clipboard:success="handleTokenCopied"
                      >
                        <b-icon-clipboard-check v-if="tokenCopied" />
                        <b-icon-clipboard v-else />
                      </b-button>
                      <span class="align-middle ml-2" v-if="tokenCopied">
                        Copied!
                      </span>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <div class="text-muted small">
                  Created: {{ formatDate(token.created_at) }}
                </div>
                <div class="text-muted small">
                  Last Used: {{ formatDate(token.last_used_at) }}
                </div>
              </div>
              <b-button size="sm" @click="handleRevokeToken(token.id)"
                >Revoke</b-button
              >
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col cols="4">
        <h5>Create New Access Token</h5>
        <b-form inline @submit.prevent="handleCreateToken">
          <b-input-group prepend="Name:">
            <b-input type="text" name="name" v-model="newTokenName" size="64" />
          </b-input-group>
          <b-button type="submit" :disabled="!newTokenName.length">
            Create
          </b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue';
import Clipboard from 'v-clipboard';
Vue.use(Clipboard);

import api from '@/api';

export default {
  name: 'Tokens',
  data() {
    return {
      newTokenName: '',
      accessTokens: [],
      tokenCopied: false,
    };
  },
  methods: {
    async handleRevokeToken(id) {
      const result = await api.revokeToken(id);
      console.log(result);
      if (result.deleted) {
        await this.getTokens();
      }
    },
    handleTokenCopied() {
      this.tokenCopied = true;
      window.setTimeout(() => {
        this.tokenCopied = false;
      }, 5000);
    },
    async handleCreateToken() {
      const result = await api.createToken(this.newTokenName);
      const newToken = {
        ...result.accessToken,
        plainTextToken: result.plainTextToken,
      };
      this.accessTokens = [newToken, ...this.accessTokens];
      this.newTokenName = '';
    },
    formatDate(date = null) {
      return null !== date ? new Date(date).toLocaleString('en-CA') : 'Never';
    },
    async getTokens() {
      this.accessTokens = await api.getTokens();
    },
  },
  async mounted() {
    await this.getTokens();
  },
};
</script>
