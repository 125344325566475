<template>
  <div class="text-center mb-lg-2 border border-dark rounded">
    <b-row class="bg-primary text-white font-weight-bold pt-2 pb-2 m-0"
      ><b-col
        >{{ $t('onlineviews.title')
        }}<span v-b-tooltip.hover="tooltipData" class="float-right"
          ><b-icon-info-circle></b-icon-info-circle></span></b-col
    ></b-row>
    <b-row class="bg-light pt-3 pb-3 m-0">
      <b-col>
        <p>
          {{ $t('onlineviews.detail_page_views') }}:
          <strong>{{ formatNumber(views.detailViews) }}</strong>
        </p>
        <p>
          {{ $t('onlineviews.search_page_views') }}:
          <strong>{{ formatNumber(views.srp) }}</strong>
        </p>
        <p class="mb-0">
          {{ $t('onlineviews.total') }}:
          <strong>{{ formatNumber(views.impressions) }}</strong>
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    views: { required: true },
  },
  data() {
    return {
      title: 'Online Views',
      tooltipData: {
        title:
          '<p>Detail Page Views → Total detail page views.</p><p>Search Page Views → Total search page views.</p><p>Total → Sum of the detail page views and search page views.</p>',
        html: true,
        placement: 'right',
        variant: 'secondary',
      },
    };
  },
  methods: {
    formatNumber: value => parseInt(value).toLocaleString(),
  },
};
</script>
