export default {
  namespaced: true,
  state: {
    clientId: '',
    clientName: '',
    clientLogoUrl: '',
    startDate: '',
    endDate: '',
    selectedLanguage: '',
    locationId: 0,
    archivedStatus: 0,
    requestParams: {},
    selectedTimeFrame: 0,
    selectedTypeID: -1,
    templateId: '',
  },
  mutations: {
    SET_DATE(state, client) {
      state.clientId = client.clientId;
      state.clientName = client.clientName;
      state.startDate = client.startDate;
      state.endDate = client.endDate;
      state.selectedLanguage = client.selectedLanguage;
      state.requestParams = client.requestParams;
      state.archived = client.archived;
      state.selectedTimeFrame = client.selectedTimeFrame;
      state.selectedTypeID = client.selectedTypeID;
      state.templateId = client.templateId ? client.templateId : 1;
    },
    SET_LOGO(state, clientLogoUrl) {
      state.clientLogoUrl = clientLogoUrl;
    },
    SET_LOCATION_ID(state, locationId) {
      state.locationId = locationId;
    },
    SET_ARCHIVED_STATUS(state, archivedStatus) {
      state.archivedStatus = archivedStatus;
    },
    SET_TYPE_ID(state, typeId) {
      state.selectedTypeID = typeId;
    },
    SET_SELECTED_TIMEFRAME(state, selectedTimeFrame) {
      state.selectedTimeFrame = selectedTimeFrame;
    },
  },
  actions: {
    setDate({ commit }, client) {
      commit('SET_DATE', client);
    },
    setLogo({ commit }, clientLogoUrl) {
      commit('SET_LOGO', clientLogoUrl);
    },
    setLocationId({ commit }, locationId) {
      commit('SET_LOCATION_ID', locationId);
    },
    setArchivedStatus({ commit }, archivedStatus) {
      commit('SET_ARCHIVED_STATUS', archivedStatus);
    },
    setTypeId({ commit }, typeId) {
      commit('SET_TYPE_ID', typeId);
    },
    setSelectedTimeFrame({ commit }, selectedTimeFrame) {
      commit('SET_SELECTED_TIMEFRAME', selectedTimeFrame);
    },
  },
  getters: {
    getDates: state => {
      return state.client;
    },
    getLogo: state => {
      return state.client.clientLogoUrl;
    },
  },
};
