export default {
  namespaced: true,

  state: () => ({
    selectedReport: 0,
  }),

  getters: {
    getSelectedReport: state => {
      return state.selectedReport;
    },
  },

  mutations: {
    setSelectedReport(state, idx) {
      state.selectedReport = parseInt(idx);
    },
  },

  actions: {
    setSelectedReport({ commit }, idx) {
      commit('setSelectedReport', idx);
    },
  },
};
