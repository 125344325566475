<template>
  <b-container fluid="true">
    <b-row>
      <b-col>
        <create-new-sub-categories-modal
          :categories="uniqueCategories"
          @submit="loadCategorySubCategoryMappings(true)"
        ></create-new-sub-categories-modal>
      </b-col>
      <b-col>
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Type to Search"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <auto-dismissable-alert
        message="Subcategory successfully deleted"
        :dismissCountDown="dismissCountDown"
      >
      </auto-dismissable-alert>
    </b-row>
    <b-row>
      <b-col class="pt-3">
        <b-table
          :fields="fields"
          :items="items"
          :busy.sync="isBusy"
          :filter="filter"
          ref="dtable"
          id="dtable"
          show-empty
          striped
          hover
        >
          <template #cell(strCategory)="data">
            {{ data.value }}
            <div class="text-muted small">({{ data.item.strCategoryF }})</div>
          </template>
          <template #cell(strSubcategory)="data">
            {{ data.value }}
            <div class="text-muted small">
              ({{ data.item.strSubcategoryF }})
            </div>
          </template>
          <template #cell(models_count)="data">
            <b-button
              variant="danger"
              @click="showDeleteModal(data.item.intID, data.value)"
            >
              Delete
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CreateNewSubCategoriesModal from '@/components/SubCategories/CreateNewSubCategoriesModal';
import AutoDismissableAlert from '@/components/Common/AutoDismissableAlert';
import api from '@/api';

export default {
  name: 'ModelCategories',
  components: {
    CreateNewSubCategoriesModal,
    AutoDismissableAlert,
  },
  data() {
    return {
      isBusy: false,
      items: [],
      fields: [
        {
          key: 'strCategory',
          label: 'Category',
        },
        {
          key: 'strSubcategory',
          label: 'Sub Category',
        },
        {
          key: 'models_count',
          label: '',
        },
      ],
      filter: null,
      dismissCountDown: 0,
    };
  },
  async mounted() {
    await this.loadCategorySubCategoryMappings();
  },
  computed: {
    ...mapState('models', {
      categories: state => state.categories,
    }),
    uniqueCategories() {
      const key = 'strCategory';

      return [
        ...new Map(this.categories.map(item => [item[key], item])).values(),
      ];
    },
  },
  methods: {
    ...mapActions('models', {
      loadCategories: 'loadCategories',
    }),
    async loadCategorySubCategoryMappings(force = false) {
      await this.loadCategories(force);
      this.items = this.categories;
    },
    async showDeleteModal(id, count) {
      await this.$bvModal
        .msgBoxConfirm(
          'This action will effect ' +
            count +
            ' entries. Are you sure you wish to delete this sub-category?',
        )
        .then(value => {
          if (value === true) {
            api
              .deleteSubCategory(id)
              .then(res => {
                if (res.status === 'success') {
                  this.dismissCountDown = 5;
                  this.loadCategorySubCategoryMappings(true);
                }
              })
              .catch(err => {
                window.alert(
                  err.response.data.message || 'An unknown error occurred',
                );
              });
          }
        });
    },
  },
};
</script>
