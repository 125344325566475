<template>
  <b-container fluid="true">
    <header class="d-flex align-items-start">
      <h2>Models</h2>
      <create-new-model-modal @model-created="handleModelCreated" />
    </header>
    <b-row>
      <b-col cols="12">
        <model-datatable-filters
          :initial-name-search="form.nameSearch"
          :selected-type="selectedType"
          :selected-manufacturer="selectedManufacturer"
          :selected-category="selectedCategory"
          :initial-exact-match="parseInt(exactMatch)"
          :disable-input="!renderTable"
          @mf-clear="handleClearManufacturer"
          @mf-set="handleSetManufacturer"
          @type-clear="handleClearType"
          @type-set="handleSetType"
          @cat-clear="handleClearSelectedCategory"
          @cat-set="handleSetSelectedCategory"
          @name-set="handleModelNameSearchFilter"
          @name-clear="handleModelNameSearchClear"
          @exact-match-set="handleSetExactMatch"
          @cat-map-set="handleSetCategories"
        />
      </b-col>
    </b-row>
    <b-row class="pt-3">
      <b-col
        xs="12"
        lg="6"
        class="d-flex justify-content-lg-center justify-content-md-start"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          ref="topPaginator"
        />
      </b-col>
    </b-row>
    <b-table
      v-if="renderTable"
      :fields="fields"
      :items="itemsProvider"
      :filter="tableFilter"
      :busy.sync="isBusy"
      :current-page="currentPage"
      :per-page="perPage"
      ref="dtable"
      id="dtable"
      show-empty
      striped
      hover
      @refreshed="handleTableRefresh"
    >
      <!-- default head style -->
      <template #head()="data">
        {{ data.label }}
        <div class="small text-muted" v-html="data.field.subLabel" />
      </template>
      <template #cell(strManufacturer)="data">
        <span :title="`MF ID: ${data.item.intManufacturerID}`">
          {{ data.value }}
        </span>
      </template>
      <template #cell(strType)="data">
        <span :title="`Type ID: ${data.item.intManufacturerID}`">
          {{ data.value }}
        </span>
        <details
          class="small"
          v-if="data.item.type_aliases && data.item.type_aliases.length"
          :open="data.item.type_aliases.length <= 3"
        >
          <summary>Type Aliases ({{ data.item.type_aliases.length }})</summary>
          <ul class="mb-0 pl-4">
            <li
              v-for="(alias, key) in data.item.type_aliases"
              :key="key"
              :title="`Type Alias ID: ${alias.intTypeID}`"
            >
              {{ getTypeById(alias.intTypeID).strType }}
              <small>({{ alias.intTypeID }})</small>
            </li>
          </ul>
        </details>
      </template>
      <template #cell(intListingsTotal)="data" class="align-top">
        {{ data.value }}
        <span class="small text-muted ml-2 text-nowrap">
          ({{ data.item.intListingsActive }} /
          {{ data.item.intListingsArchived }})
        </span>
      </template>
      <template #cell(strName)="data">
        <b-link
          :to="{ name: 'modelDetails', params: { modelId: data.item.intID } }"
          :title="`Model ID: ${data.item.intID}`"
        >
          {{ data.value }}
        </b-link>
        <details
          class="small"
          v-if="data.item.alias_patterns && data.item.alias_patterns.length > 3"
        >
          <summary>
            Name Aliases ({{ data.item.alias_patterns.length }})
          </summary>
          <ul class="mb-0 pl-4 small">
            <li
              v-for="(alias, key) in data.item.alias_patterns.filter(
                p => p.pattern !== data.item.strNameAsciiLower,
              )"
              :key="key"
            >
              <code>{{ alias.pattern }}</code>
            </li>
          </ul>
        </details>
        <ul
          class="mb-0 pl-4 small"
          v-else-if="
            data.item.alias_patterns && data.item.alias_patterns.length <= 3
          "
        >
          <li v-for="(alias, key) in data.item.alias_patterns" :key="key">
            <code>{{ alias.pattern }}</code>
          </li>
        </ul>
      </template>
      <template #cell(alias_patterns)="data">
        <ul class="mb-0 pl-4">
          <li v-for="(alias, key) in data.item.alias_patterns" :key="key">
            <code v-html="alias.pattern" class="small" />
          </li>
        </ul>
      </template>
      <template #cell(category)="data">
        <ul class="mb-0 pl-4">
          <li
            v-for="(cat, key) in data.item.categories"
            :key="key"
            :title="`Cat ID: ${cat.id}`"
          >
            <span :title="cat.fr.category">{{ cat.en.category }}</span>
            <span class="mx-1">›</span>
            <span :title="cat.fr.subcategory">{{ cat.en.subcategory }}</span>
          </li>
        </ul>
      </template>
      <template v-slot:empty="scope">
        <h4>{{ scope.emptyText }}</h4>
      </template>
    </b-table>

    <b-row>
      <b-col class="d-flex justify-content-end pt-3">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import api from '@/api';
import CreateNewModelModal from '@/components/Models/CreateNewModelModal';
import ModelDatatableFilters from '../components/ModelMerge/DatatableFilters';
import { config } from '@/common/config';
import { keyById } from '@/common/helperFunctions';
import axios from 'axios';

export default {
  name: 'Models',
  components: {
    CreateNewModelModal,
    ModelDatatableFilters,
  },
  data() {
    return {
      renderTable: false,
      query: {
        mfId: null,
        typeId: null,
        catId: null,
        nameSearch: null,
      },
      form: {
        nameSearch: null,
      },
      fields: [
        { key: 'strManufacturer', label: 'Make' },
        { key: 'strType', label: 'Canon. Type', subLabel: 'Type Aliases' },
        {
          key: 'intListingsTotal',
          label: '# Listings',
          subLabel: '(Active/Archived)',
        },
        { key: 'strName', label: 'Model', subLabel: 'Name Aliases' },
        { key: 'category', label: 'Categories' },
      ],
      selectedManufacturer: { name: null },
      selectedType: { name: null },
      selectedCategory: { name: null },
      categories: [],
      isBusy: false,
      rows: 0,
      perPage: 25,
      currentPage: 1,
      exactMatch: 0,
    };
  },
  async created() {
    await this.loadMakesTypes();
    if (this.$route.query.page) {
      this.currentPage = this.$route.query.page;
    }
    this.query = {
      ...this.query,
      ...this.$route.query,
    };
    const { mfId, typeId, nameSearch, exactMatch } = this.query;
    const noItem = { name: null };
    this.selectedManufacturer = this.getManufacturerById(mfId) || noItem;
    this.selectedType = this.getTypeById(typeId) || noItem;
    this.form.nameSearch = nameSearch || null;
    this.exactMatch = exactMatch || 0;
  },
  computed: {
    ...mapState('models', {
      manufacturers: state => state.manufacturers,
      models: state => state.models,
      types: state => state.types,
    }),
    ...mapGetters('models', {
      getTypesSorted: 'getTypesSorted',
      getTypeById: 'getTypeById',
      getManufacturerById: 'getManufacturerById',
      getMfsSorted: 'getMfsSorted',
    }),
    mfsWithModels() {
      return this.getMfsSorted;
      // .filter(mf => mf.models_count > 0)
    },
    activeTypes() {
      return this.getTypesSorted;
      // .filter(
      //   t => t.alias_models_count + t.canonical_models_count > 0,
      // );
    },
    tableFilter() {
      const filter = {};
      if (this.selectedManufacturer) {
        filter['intManufacturerID'] = this.selectedManufacturer.id;
      }
      if (this.selectedType) {
        filter['intTypeID'] = this.selectedType.id;
      }
      if (this.selectedCategory) {
        filter['intCategoryID'] = this.selectedCategory.id;
      }
      if (this.query.nameSearch) {
        filter['strNameAsciiLower'] = this.query.nameSearch.toLowerCase();
      }
      return filter;
    },
    validNameSearch() {
      if (this.form.nameSearch === null || this.form.nameSearch === '') {
        return null;
      }
      const validRegex = config.modelName.regex;
      return validRegex.test(this.form.nameSearch);
    },
  },
  methods: {
    ...mapActions('models', {
      loadManufacturers: 'loadManufacturers',
      loadModels: 'loadModels',
      loadTypes: 'loadTypes',
    }),
    handleModelCreated(newModel) {
      console.log(newModel);
      this.$router.push({
        name: 'modelDetails',
        params: { modelId: newModel.intID },
      });
    },
    async loadMakesTypes() {
      await Promise.all([this.loadManufacturers(), this.loadTypes()]);
    },
    handleTableRefresh() {
      if (this.$route.query.page)
        this.currentPage = parseInt(this.$route.query.page);
    },
    handleModelNameSearchClear() {
      this.form.nameSearch = null;
      this.exactMatch = 0;
      this.query = { ...this.query, nameSearch: null, exactMatch: null };

      this.refreshTableAndResetPagination();
    },
    handleModelNameSearchFilter(nameSearch) {
      this.form.nameSearch = nameSearch;
      this.query = {
        ...this.query,
        nameSearch,
        exactMatch: this.exactMatch ? this.exactMatch : null,
      };

      this.refreshTableAndResetPagination();
    },
    handleSetExactMatch(match) {
      this.exactMatch = match;
    },
    handleSetCategories(categories) {
      this.categories = categories;
    },
    handleClearManufacturer() {
      this.query = { ...this.query, mfId: null };
      this.selectedManufacturer = { name: null };

      this.refreshTableAndResetPagination();
    },
    handleSetManufacturer(mf) {
      this.query = { ...this.query, mfId: mf.id };
      this.selectedManufacturer = mf;

      this.refreshTableAndResetPagination();
    },
    handleClearType() {
      this.query = { ...this.query, typeId: null };
      this.selectedType = { name: null };

      this.refreshTableAndResetPagination();
    },
    handleSetType(type) {
      this.query = { ...this.query, typeId: type.id };
      this.selectedType = type;

      this.refreshTableAndResetPagination();
    },
    handleSetSelectedCategory(cat) {
      this.query = { ...this.query, catId: cat.id };
      this.selectedCategory = cat;

      this.refreshTableAndResetPagination();
    },
    handleClearSelectedCategory() {
      this.query = { ...this.query, catId: null };
      this.selectedCategory = { name: null };

      this.refreshTableAndResetPagination();
    },
    handleSetMfType(mf, type) {
      this.handleSetManufacturer(mf);
      this.handleSetType(type);

      this.refreshTableAndResetPagination();
    },
    refreshTableAndResetPagination() {
      this.resetPagination();
      this.refreshTable();
    },
    resetPagination() {
      this.currentPage = 1;
    },
    refreshTable() {
      this.$refs.dtable.refresh();
    },
    async itemsProvider(ctx) {
      this.isBusy = true;
      await this.loadMakesTypes();

      const page = this.$route.query.page || ctx.currentPage;
      const exactMatch = this.exactMatch;

      const params = {
        size: ctx.perPage,
        page,
        exactMatch,
      };

      try {
        const models = await api.getModels(
          ctx.filter,
          ['strManufacturer', 'strType', 'strModel'],
          params,
          ['listingsAll'],
        );

        this.rows = models.meta.total;
        this.isBusy = false;

        return models.data;
      } catch (err) {
        this.isBusy = false;
        this.rows = 0;

        if (axios.isCancel(err)) {
          this.refreshTable();
        }

        return [];
      }
    },
  },
  watch: {
    query: {
      handler(query) {
        Object.keys(query).forEach(key => {
          if (query[key] === undefined || query[key] === null) {
            delete query[key];
          }
        });
        const route = { query };
        this.$router.push(route).catch(() => {});
      },
      deep: true,
    },
    categories: {
      handler(categories) {
        let catsById = keyById(categories, 'intID');

        if (this.query.catId) {
          this.selectedCategory = catsById[this.query.catId];
        }
        this.renderTable = true;
      },
      deep: true,
    },
    // Refresh the table whenever route get changed
    $route: 'refreshTable',
    currentPage(page) {
      this.$router
        .push({
          query: { page },
        })
        .catch(() => {});
    },
  },
};
</script>
