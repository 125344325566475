<template>
  <div>
    <b-row class="mb-3">
      <b-col class="text-right">
        <download-report></download-report>
      </b-col>
    </b-row>
    <b-row class="mr-1 ml-1 mb-3 p-2 bg-dark text-white rounded">
      <b-col
        ><strong>{{ $t('totalsrow.total_email_leads') }}:</strong>
        {{ formatNumber(tableTotals.totalRows) }}
      </b-col>
    </b-row>
    <b-table
      responsive
      striped
      hover
      no-provider-sorting
      no-provider-filtering
      sort-icon-left
      :fields="fields"
      :items="items"
      :busy="isBusy"
      ref="table"
      show-empty
      sticky-header="1000px"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #empty="scope">
        <p class="text-center font-weight-bold text-danger">
          {{ scope.emptyText }}
        </p>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(strLocationName)="data">
        <span class="text-nowrap">{{ data.value }}</span>
      </template>
      <template #head()="data">
        <span>{{ $t('email_leads.' + data.label) }}</span>
      </template>
      <template #head(intSrpViews)="data">
        <span
          >{{ $t('location.' + data.label) }}
          <b-icon-info-circle id="sprViews"></b-icon-info-circle>
          <b-tooltip target="sprViews" triggers="hover"
            >Number of search page views for this listing.</b-tooltip
          ></span
        >
      </template>
      <template #head(intDetailViews)="data">
        <span
          >{{ $t('location.' + data.label) }}
          <b-icon-info-circle id="detailViews"></b-icon-info-circle>
          <b-tooltip target="detailViews" triggers="hover"
            >Number of detail views for this listing.</b-tooltip
          ></span
        >
      </template>
    </b-table>
    <b-row>
      <b-col>
        <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from '../../../api/index';
import DownloadReport from '../../DownloadReport';
import { mapState } from 'vuex';

export default {
  name: 'ByEmailLeadsDatatable',
  components: { DownloadReport },
  data() {
    return {
      fields: [
        { key: 'dtDate', label: 'Lead_Received_Date', sortable: true},
        { key: 'strLeadName', label: 'Lead_Name',sortable: true},
        { key: 'strLeadEmailFrom', label: 'Lead_Email_From',sortable: true },
        { key: 'strLeadPhone', label: 'Lead_Phone',sortable: true},
        { key: 'strMessage', label: 'Lead_Message',sortable: true },
        { key: 'strStockNumber', label: 'Stock_Number',sortable: true },
        { key: 'strEquipmentType', label: 'Equipment_Type',sortable: true },
        { key: 'strManufacturer', label: 'Manufacturer',sortable: true },
        { key: 'strModel', label: 'Model',sortable: true },
        { key: 'strEquipmentYear', label: 'Equipment_Year',sortable: true },
        { key: 'strEquipmentPrice', label: 'Equipment_Price',sortable: true },
        { key: 'strEquipmentCurrency', label: 'Equipment_Currency',sortable: true },
        { key: 'intAgdealerNumber', label: 'Agdealer_Number',sortable: true },
        { key: 'strLeadEmailTo', label: 'Lead_Email_To',sortable: true },
        { key: 'strContactMethod', label: 'Lead_Contact_Preference',sortable: true },
      ],
      options: {
        sortBy: 'dtDate', // Specify the column to sort by initially
        sortDesc: true // Specify the sorting order (true for descending, false for ascending)
      },
      perPage: 100,
      currentPage: 1,
      items: [],
      isBusy: false,
      firstLoad: true,
      tableTotals: {},
      tooltipData: {
        title:
          '<p>Location → Dealer location for the listing item.</p><p>Search Page Views → Number of search page views for this location.</p><p>Detail Page Views → Number of detail page views for this location.</p><p>Phone Clicks → Number of phone clicks for this location.</p><p>Email Leads  → Number of email leads for this location.</p><p>Average # of Images → Insert tooltip text here</p>',
        html: true,
        placement: 'right',
        variant: 'secondary',
      },
      tooltipRowData: {
        title:
          '<p>Total Rows → Total number of report rows for the client and date selected.</p><p>Total Detail Page Views → Summary of the detail page views for the client and date selected.</p><p>Total Search Page Views → Summary of search page views for the client and date selected.</p><p>Total Email Leads → Summary of email leads  for the client and date selected.</p><p>Total Phone Clicks → Summary of phone clicks for the client and date selected.</p>',
        html: true,
        placement: 'right',
        variant: 'secondary',
        customClass: 'mediumToolTip',
      },
    };
  },
  computed: {
    ...mapState('client', ['startDate', 'endDate', 'clientId']),
    rows() {
      return this.items.length;
    },
  },
  methods: {
    roundItem: value => Math.round(value),
    formatNumber: value => parseInt(value).toLocaleString(),
    async itemsProvider() {
      this.isBusy = true;
      const response = await api.getReportsClientByEmailLeads({
        intClientID: this.clientId,
        dtStartDate: this.startDate,
        dtEndDate: this.endDate,
      });

      this.items = response.data;

      this.firstLoad = false;
      this.isBusy = false;
      this.calculateTableTotals();
      return this.items;
    },
    calculateTableTotals() {
      this.tableTotals.totalRows = this.items.length;
    },
  },
  mounted(){
    this.itemsProvider();
  },
};
</script>
