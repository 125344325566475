import api from '@/api';

export default {
  namespaced: true,

  state: () => ({
    user: null,
  }),

  mutations: {
    setUser(state, payload) {
      state.user = payload;
      localStorage.setItem('user', JSON.stringify(payload));
    },
    clearUser(state) {
      state.user = null;
      localStorage.removeItem('user');
      location.reload();
    },
  },

  actions: {
    async getUser({ commit }) {
      try {
        const userDetails = await api.getUser();
        commit('setUser', userDetails.user);
      } catch (e) {
        console.warn('User is not logged in');
      }
    },
    async processLogin({ commit }, credentials) {
      const userDetails = await api.processLogin(credentials);
      commit('setUser', userDetails.user);
    },
    async processLogout({ commit }) {
      await api.processLogout();
      commit('clearUser');
    },
  },
};
