import qs from 'qs';
import axios from 'axios';
import store from '@/store';
import router from '@/routes';

axios.defaults.withCredentials = true;

const agdApi = axios.create({
  baseURL: process.env.VUE_APP_AGD_ADMIN_API_BASE,
  withCredentials: true,
});

agdApi.interceptors.response.use(
  response => response,
  error => {
    if (axios.isCancel(error)) {
      console.log('Cancelled all the running requests');
    } else {
      if (error.response.status === 401) {
        store.dispatch('user/processLogout');
      } else if (error.response.status === 403) {
        window.alert('User not authorized to perform this action!');
        router.replace({ name: 'dashboard' });
      }
    }
    return Promise.reject(error);
  },
);

// Cancel Token Request Interceptor
agdApi.interceptors.request.use(
  config => {
    //  Generate cancel token source
    let source = axios.CancelToken.source();

    // Set cancel token on this request
    config.cancelToken = source.token;

    // Add to vuex to make cancellation available from anywhere
    store.commit('request/addCancelToken', source);

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

const indexSerializer = params => {
  const serialParams = params;
  if (params.filter && Object.keys(params.filter).length) {
    serialParams['filter'] = params.filter;
  }
  if (params.sort && params.sort.length) {
    // remove any "empty" elements and then join them with a comma (if > 1)
    serialParams['sort'] = params.sort
      .filter(el => el != null && el !== '')
      .join(',');
  }
  return qs.stringify(serialParams, { encode: false });
};

export default {
  async processLogin(credentials) {
    await agdApi.get('sanctum/csrf-cookie');
    return await agdApi.post('api/login', credentials).then(res => res.data);
  },
  async processLogout() {
    return await agdApi.post('api/logout');
  },
  async getUser() {
    return await agdApi.get('api/user').then(res => res.data);
  },
  async getTokens() {
    return await agdApi.get('api/user/tokens').then(res => res.data);
  },
  async createToken(name) {
    return await agdApi
      .post('api/user/tokens', {
        name,
      })
      .then(res => res.data);
  },
  async revokeToken(tokenId) {
    return await agdApi
      .delete(`api/user/tokens/${tokenId}`)
      .then(res => res.data);
  },
  async ignoreListing(listingId) {
    if (!Array.isArray(listingId)) {
      listingId = [listingId];
    }

    return await agdApi
      .post('api/model-listings/ignore-listings', {
        listingIds: listingId,
      })
      .then(res => res.data);
  },
  async restoreListing(listingId) {
    if (!Array.isArray(listingId)) {
      listingId = [listingId];
    }

    return await agdApi
      .post('api/model-listings/restore-listings', {
        listingIds: listingId,
      })
      .then(res => res.data);
  },
  async getUnmatchedListings(filter = {}, sort = [], pagination = {}) {
    const opts = {
      params: {
        filter,
        sort,
        ...pagination,
      },
      paramsSerializer: indexSerializer,
    };
    return agdApi
      .get('api/model-listings/unmatched', opts)
      .then(res => res.data);
  },
  async getListingsByModelId(modelId, pagination) {
    const opts = {
      params: {
        ...pagination,
      },
    };
    return agdApi
      .get(`api/model-listings/${modelId}`, opts)
      .then(res => res.data);
  },
  async getModel(modelId) {
    return agdApi.get(`api/models/${modelId}`).then(res => res.data.data);
  },
  async getModels(filter = {}, sort = [], pagination = {}, counts = []) {
    const endpoint = 'api/models';
    return this.getModelsBase(endpoint, filter, sort, pagination, counts);
  },
  async getModelsMerging(filter = {}, sort = [], pagination = {}, counts = []) {
    const endpoint = 'api/models/list/likely-merges';
    return this.getModelsBase(endpoint, filter, sort, pagination, counts);
  },
  async getModelsBase(
    endpoint = 'api/models',
    filter = {},
    sort = [],
    pagination = {},
    counts = [],
  ) {
    const opts = {
      params: {
        filter,
        sort,
        ...pagination,
        counts,
      },
      paramsSerializer: indexSerializer,
    };
    return agdApi.get(endpoint, opts).then(res => res.data);
  },
  async getUnmatchedFacetsTypes() {
    return agdApi
      .get('api/model-listings/unmatched-facets-type')
      .then(res => res.data.data);
  },
  async getUnmatchedFacetsClients() {
    return agdApi
      .get('api/model-listings/unmatched-facets-client')
      .then(res => res.data.data);
  },
  async getUnmatchedFacetsManufacturers() {
    return agdApi
      .get('api/model-listings/unmatched-facets-mf')
      .then(res => res.data.data);
  },
  async getUnmatchedFacetsReps() {
    return agdApi
      .get('api/model-listings/unmatched-facets-reps')
      .then(res => res.data.data);
  },
  async getManufacturers() {
    return agdApi.get('api/manufacturers').then(res => res.data.data);
  },
  async getTypes() {
    return agdApi.get('api/types').then(res => res.data.data);
  },
  async getModelCategories() {
    return agdApi
      .get('api/model-categories')
      .then(res => res.data)
      .catch(err => {
        console.log(err);
        return err;
      });
  },
  async deleteModel(modelId) {
    return agdApi
      .delete(`api/models/${modelId}`)
      .then(res => res.data)
      .catch(err => {
        console.error(err);
        return err;
      });
  },
  async mergeModels(targetId, sourceIds) {
    return agdApi
      .post(`api/model-merge/by-ids`, { targetId, sourceIds })
      .then(res => res.data);
  },
  async updateCanonical(
    modelId,
    {
      mfId = null,
      typeId = null,
      canonical = null,
      aliases = null,
      categoryIds = null,
      typeAliasIds = null,
    },
  ) {
    let params = {
      mfId,
      typeId,
      canonical,
      aliases,
      categoryIds,
      typeAliasIds,
    };
    return agdApi.put(`api/models/${modelId}`, params).then(res => res.data);
  },
  async createNewCanonical({
    mfId,
    typeId,
    canonical,
    aliases = [],
    categoryIds = [],
    typeAliasIds = [],
    nameSearch = null,
  }) {
    return agdApi
      .post('api/models', {
        mfId,
        typeId,
        canonical,
        aliases,
        categoryIds,
        typeAliasIds,
        nameSearch,
      })
      .then(res => res.data)
      .catch(err => {
        console.error(err);
        return err;
      });
  },
  async getReportsBySummary(params) {
    return this.getReports(params, 'by-summary');
  },
  async getTopFiveListingsCollection(params) {
    return this.getReports(params, 'top-five');
  },
  async getReportsClientByListing(params) {
    return this.getReports(params, 'by-listing');
  },
  async getReportsClientByEmailLeads(params) {
    return this.getReports(params, 'by-email-leads');
  },
  async getReportsClientByLocation(params) {
    return this.getReports(params, 'by-location');
  },
  async getReportsClientByDate(params) {
    return this.getReports(params, 'by-date');
  },
  async getReportsClientSummary(params) {
    return this.getReports(params, 'summary');
  },
  async getReportsClientByType(params) {
    return this.getReports(params, 'by-type');
  },
  async getReports(params, endpoint) {
    const opts = {
      params,
      paramsSerializer: indexSerializer,
    };
    return agdApi
      .get(`api/reports/client/${endpoint}`, opts)
      .then(res => res.data)
      .catch(err => {
        console.error(err);
        return err;
      });
  },
  async getReportsCsvByTypeSpecified(params) {
    //For some reason responseType doesn't work correctly if passed as part of params object
    const opts = {
      params,
      responseType: 'arraybuffer',
      paramsSerializer: indexSerializer,
    };
    return agdApi
      .get(`api/reports/export-csv/by-${params.chartType}`, opts)
      .catch(err => {
        console.error(err);
        return err;
      });
  },
  async getClientsReps() {
    return agdApi
      .get('api/reps-clients')
      .then(res => res.data)
      .catch(err => {
        console.error(err);
        return err;
      });
  },
  async getReportByName(reportSlug) {
    return agdApi
      .get(`api/reports/${reportSlug}`)
      .then(res => res.data)
      .catch(err => {
        console.log(err);
        return err;
      });
  },
  async createNewModelSubCategory({
    category,
    categoryF,
    subCategory,
    subCategoryF,
  }) {
    return agdApi
      .post('api/model-categories', {
        category,
        categoryF,
        subCategory,
        subCategoryF,
      })
      .then(res => res.data);
  },
  async deleteSubCategory(subCategoryId) {
    return agdApi
      .delete(`api/model-categories/${subCategoryId}`)
      .then(res => res.data)
      .catch(error => error);
  },
  async updateModelSubCategory(id, modelIds, operation) {
    return agdApi
      .put(`api/model-categories/${id}`, {
        modelIds,
        operation,
      })
      .then(res => res.data);
  },
  async deleteSubcategoriesFromModel(subCategoryIds, modelIds) {
    return agdApi
      .post(`api/model-categories/deleteSubcategoriesFromModel`, {
        subCategoryIds,
        modelIds,
      })
      .then(res => res.data)
      .catch(error => error);
  },
};
