<template>
  <div>
    <b-container fluid="true" v-if="user">
      <h2>Welcome back, {{ user.strFirstName }}!</h2>

      <b-row>
        <b-col md="6">
          <b-card>
            <template #header>
              <h4 class="mb-0">User Details</h4>
            </template>
            <dl class="row">
              <template v-for="(val, key) in userDetails">
                <dt class="col-sm-4" :key="key">{{ key }}</dt>
                <dd class="col-sm-8" :key="val">{{ val }}</dd>
              </template>
            </dl>

            <details>
              <summary>See full user details</summary>
              <code>
                <pre>{{ user }}</pre>
              </code>
            </details>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Dashboard',
  created() {
    if (!this.user) {
      this.$router.push('');
    }
  },
  computed: {
    ...mapState('user', {
      user: state => state.user,
    }),
    userDetails() {
      if (this.user) {
        return {
          Name: `${this.user.strFirstName} ${this.user.strLastName}`,
          Username: `${this.user.strUserName}`,
        };
      } else {
        return null;
      }
    },
  },
};
</script>

<style></style>
