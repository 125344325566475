<template>
  <section class="ml-4">
    <b-alert
      :show="countDown"
      dismissible
      fade
      :variant="variant"
      @dismiss-count-down="countDownChanged"
      class="mt-2"
    >
      <b>{{ message }}</b>
    </b-alert>
  </section>
</template>

<script>
export default {
  name: 'AutoDismissableAlert',
  props: {
    message: {
      type: String,
      required: 'Successfully created',
    },
    variant: {
      type: String,
      default: 'success',
    },
    dismissCountDown: {
      type: Number,
      default: 0,
    },
    dismissSecs: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      countDown: this.dismissCountDown,
    };
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.countDown = dismissCountDown;
    },
  },
  watch: {
    dismissCountDown(newVal) {
      this.countDown = newVal;
    },
  },
};
</script>
