<template>
  <section class="ml-4">
    <b-button v-b-modal.create-new :disabled="!categories.length">
      <b-icon-plus-square class="mr-2" />
      Create New
    </b-button>

    <auto-dismissable-alert
      message="Subcategory successfully created"
      :dismissCountDown="dismissCountDown"
    ></auto-dismissable-alert>

    <b-modal
      ref="subcat-modal"
      id="create-new"
      scrollable
      size="lg"
      title="Create New SubCategory"
      hide-footer
    >
      <b-container fluid>
        <sub-categories-form
          ref="form"
          :categories="categories"
          create-or-edit-mode="create"
          @submit="handleFormSubmitted"
          @cancel="handleCancel"
        />
      </b-container>
    </b-modal>
  </section>
</template>

<script>
import SubCategoriesForm from './SubCategoriesForm';
import AutoDismissableAlert from '@/components/Common/AutoDismissableAlert';

export default {
  name: 'CreateNewSubCategoriesModal',
  components: {
    SubCategoriesForm,
    AutoDismissableAlert,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dismissCountDown: 0,
    };
  },
  methods: {
    handleFormSubmitted() {
      this.dismissCountDown = 5;
      this.handleCancel();
      this.$emit('submit');
    },
    handleCancel() {
      this.$refs['subcat-modal'].hide();
    },
  },
};
</script>
