<template>
  <b-form @submit.prevent="submitUpdateCanonical">
    <b-form-group
      id="input-group-1"
      label="Add as aliases to an existing canonical"
      label-for="input-1"
    >
      <typeahead
        ref="typeahead"
        :items="canonicals"
        item-label-key="strName"
        :selected-item="selected"
        @set-query="setSelectedCanonical"
        @clear-query="setSelectedCanonical(null)"
        :min-query-length="0"
        :disable-append="true"
      >
      </typeahead>
    </b-form-group>
    <b-card title="Details" v-if="selected" class="mb-3">
      <dl class="small mb-0">
        <dt>Canonical</dt>
        <dd>{{ selected.name }}</dd>

        <template v-if="selectedModelsLowercase.length">
          <dt>New Aliases</dt>
          <dd>
            <ul class="pl-3 mb-0 text-monospace small">
              <li v-for="(m, i) in selectedModelsLowercase" :key="i">
                {{ m }}
              </li>
            </ul>
          </dd>
        </template>

        <template
          v-if="selected.alias_patterns && selected.alias_patterns.length"
        >
          <dt>Existing Aliases</dt>
          <dd>
            <ul class="pl-3 mb-0 text-monospace small">
              <li v-for="p in selected.alias_patterns" :key="p.id">
                {{ p.pattern }}
              </li>
            </ul>
          </dd>
        </template>

        <dt>Categories</dt>
        <dd>
          <ul v-if="selected.categories" class="pl-3 mb-0">
            <li v-for="c in selected.categories" :key="c.id">
              {{ c.en.category }} › {{ c.en.subcategory }}
            </li>
          </ul>
          <span v-else>N/A</span>
        </dd>
      </dl>
    </b-card>

    <b-button type="submit" variant="primary" v-if="selected">
      Select
    </b-button>
  </b-form>
</template>

<script>
import Typeahead from '@/components/Typeahead';
import { mapActions } from 'vuex';
import foldToAscii from 'fold-to-ascii';

export default {
  name: 'SidebarAddToExisting',
  components: {
    Typeahead,
  },
  props: {
    canonicals: Array,
    selectedModels: Array,
  },
  data() {
    return {
      selected: null,
    };
  },
  watch: {
    selectedModels() {
      this.$refs.typeahead.clear();
    },
  },
  computed: {
    selectedModelsLowercase() {
      // convert to lowercase, fold accents etc into ascii and collapse multiple spaces into one
      return this.selectedModels.map(m =>
        foldToAscii.foldReplacing(m.model.toLowerCase()).replace(/\s+/g, ' '),
      );
    },
    formParams() {
      return {
        aliases: [
          ...this.selected.alias_patterns.map(p => p.pattern),
          ...this.selectedModelsLowercase,
        ],
      };
    },
  },
  methods: {
    ...mapActions('models', {
      updateCanonical: 'updateCanonical',
    }),
    setSelectedCanonical(canonical) {
      this.selected = canonical;
    },
    async submitUpdateCanonical() {
      const result = await this.updateCanonical({
        id: this.selected.intID,
        params: this.formParams,
      });
      if (result.status === 'success') {
        this.$emit('update-table');
      }
    },
  },
};
</script>
