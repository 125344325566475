<template>
  <b-dropdown :text="$t('downloadreport.download_report')">
    <b-dropdown-item @click="csvDownloader('csv')">{{
      $t('downloadreport.csv')
    }}</b-dropdown-item>
    <b-dropdown-item @click="csvDownloader('xlsx')">{{
      $t('downloadreport.excel')
    }}</b-dropdown-item>
  </b-dropdown>
</template>


<script>
import api from '../api/index';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      fileExtension: 'csv',
    };
  },
  computed: {
    ...mapState('client', [
      'requestParams',
      'locationId',
      'archivedStatus',
      'selectedTimeFrame',
      'selectedTypeID',
    ]),
  },
  methods: {
    async downloadReportFile(response) {
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      //Get content-disposition header containing filename, then trim "attachment; filename=" part
      const contentDispositionHeader = response.headers['content-disposition'];
      const filename = contentDispositionHeader
        .replace('attachment; filename=', '')
        .replaceAll('"', '');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },
    async csvDownloader(fileExtension) {
      this.requestParams.strFileExtension = fileExtension;
      this.requestParams.intLocationID = this.locationId;
      this.requestParams.intArchivedStatus = this.archivedStatus;
      this.requestParams.intSelectedTimeFrame = this.selectedTimeFrame;
      this.requestParams.intSelectedTypeID = this.selectedTypeID;

      if (this.requestParams.chartType === 'listing') {
        this.requestParams.headerStrings = encodeURIComponent(
          JSON.stringify(this.$t('alllistings')),
        );
      } else if (this.requestParams.chartType === 'location') {
        this.requestParams.headerStrings = encodeURIComponent(
          JSON.stringify(this.$t('location')),
        );
      } else if (this.requestParams.chartType === 'date') {
        this.requestParams.headerStrings = encodeURIComponent(
          JSON.stringify(this.$t('date')),
        );
      } else if (this.requestParams.chartType === 'type') {
        this.requestParams.headerStrings = encodeURIComponent(
          JSON.stringify(this.$t('type')),
        );
      }
      else if (this.requestParams.chartType === 'email-leads') {
        this.requestParams.headerStrings = encodeURIComponent(
            JSON.stringify(this.$t('email_leads')),
        );
      }

      await api
        .getReportsCsvByTypeSpecified(this.requestParams)
        .then(response => this.downloadReportFile(response));
    },
  },
};
</script>
