<template>
  <b-container fluid="true">
    <h2>Models Likely to be Merged</h2>
    <b-row>
      <b-col :cols="selectedParentModel ? 9 : 12">
        <model-datatable-filters
          :initial-name-search="form.nameSearch"
          :selected-type="selectedType"
          :selected-manufacturer="selectedManufacturer"
          :selected-category="selectedCategory"
          :initial-exact-match="parseInt(exactMatch)"
          :disable-input="!renderTable"
          @mf-clear="handleClearManufacturer"
          @mf-set="handleSetManufacturer"
          @type-clear="handleClearType"
          @type-set="handleSetType"
          @cat-clear="handleClearSelectedCategory"
          @cat-set="handleSetSelectedCategory"
          @name-set="handleModelNameSearchFilter"
          @name-clear="handleModelNameSearchClear"
          @exact-match-set="handleSetExactMatch"
          @cat-map-set="handleSetCategories"
        />
        <model-merge-datatable
          v-if="renderTable"
          :selected-manufacturer="selectedManufacturer"
          :selected-type="selectedType"
          :selected-category="selectedCategory"
          :name-filter="form.nameSearch"
          :exact-match="parseInt(exactMatch)"
          :selected-parent-model="selectedParentModel"
          :selected-child-model-ids="selectedChildModelIds"
          @mf-set="handleSetManufacturer"
          @type-set="handleSetType"
          @merge-click-parent="handleSelectParentModel"
          @merge-click-child="handleToggleChildModel"
          ref="datatable"
        />
      </b-col>
      <b-col cols="3" class="small" v-if="selectedParentModel">
        <merge-to-parent-sidebar
          :parent="selectedParentModel"
          :children="selectedChildModels"
          @click-parent="handleSelectParentModel(selectedParentModel)"
          @click-child="handleToggleChildModel"
          @merge-success="handleExecuteMerge"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import MergeToParentSidebar from '../components/ModelMerge/MergeToParentSidebar';
import ModelDatatableFilters from '../components/ModelMerge/DatatableFilters';
import ModelMergeDatatable from '../components/ModelMerge/Datatable';
import { keyById } from '@/common/helperFunctions';

export default {
  name: 'ModelsForMerging',
  components: {
    ModelMergeDatatable,
    ModelDatatableFilters,
    MergeToParentSidebar,
  },
  data() {
    return {
      renderTable: false,
      query: {
        mfId: null,
        typeId: null,
        nameSearch: null,
      },
      form: {
        nameSearch: null,
      },
      selectedManufacturer: { name: null },
      selectedType: { name: null },
      selectedCategory: { name: null },
      categories: [],
      selectedParentModel: null,
      selectedChildModels: [],
      newModel: {
        model: null,
        basis: null,
      },
      exactMatch: 0,
    };
  },
  async created() {
    await this.loadMakesTypes();
    this.query = {
      ...this.query,
      ...this.$route.query,
    };
    const { mfId, typeId, nameSearch, exactMatch } = this.query;
    const noItem = { name: null };
    this.selectedManufacturer = this.getManufacturerById(mfId) || noItem;
    this.selectedType = this.getTypeById(typeId) || noItem;
    this.form.nameSearch = nameSearch || null;
    this.exactMatch = exactMatch || 0;
  },
  computed: {
    ...mapGetters('models', {
      getManufacturerById: 'getManufacturerById',
      getTypeById: 'getTypeById',
    }),
    selectedChildModelIds() {
      return this.selectedChildModels.map(scm => scm.intID);
    },
    currentQueryRoute() {
      const query = { ...this.query };
      Object.keys(query).forEach(key => {
        if (query[key] === undefined || query[key] === null) {
          delete query[key];
        }
      });
      return { query };
    },
  },
  methods: {
    ...mapActions('models', {
      loadManufacturers: 'loadManufacturers',
      loadTypes: 'loadTypes',
    }),
    async handleExecuteMerge(merged) {
      this.selectedParentModel = null;
      this.selectedChildModels = [];
      console.log(merged);
      this.$refs.datatable.refreshTable();
    },
    handleSelectNewModel(parent) {
      // take some of the parent's properties to use as the basis for the new model
      const { _mf, _type, strName } = parent;
      this.newModel.model = { _mf, _type, strName };
      // then set the parent as a child item
      this.newModel.basis = parent;
      this.selectedChildModels = [parent];
    },
    handleSelectParentModel(model) {
      if (
        !this.selectedParentModel ||
        this.selectedParentModel.intID !== model.intID
      ) {
        this.selectedParentModel = model;
      } else {
        this.selectedParentModel = null;
        this.selectedChildModels = [];
      }
    },
    handleToggleChildModel(model) {
      if (this.selectedChildModelIds.includes(model.intID)) {
        this.selectedChildModels = this.selectedChildModels.filter(
          scm => scm.intID !== model.intID,
        );
      } else {
        this.selectedChildModels = [...this.selectedChildModels, model];
      }
    },
    async loadMakesTypes() {
      await Promise.all([this.loadManufacturers(), this.loadTypes()]);
    },
    handleSetExactMatch(match) {
      this.exactMatch = match;
    },
    handleModelNameSearchClear() {
      this.form.nameSearch = null;
      this.exactMatch = 0;
      this.query = {
        ...this.query,
        nameSearch: null,
        page: 1,
        exactMatch: null,
      };
    },
    handleModelNameSearchFilter(nameSearch) {
      this.form.nameSearch = nameSearch;
      this.query = {
        ...this.query,
        nameSearch,
        exactMatch: this.exactMatch ? this.exactMatch : null,
        page: 1,
      };
    },
    handleClearManufacturer() {
      this.query = { ...this.query, mfId: null, page: 1 };
      this.selectedManufacturer = { name: null };
    },
    handleSetManufacturer(mf) {
      this.query = { ...this.query, mfId: mf.id, page: 1 };
      this.selectedManufacturer = mf;
    },
    handleClearType() {
      this.query = { ...this.query, typeId: null, page: 1 };
      this.selectedType = { name: null };
    },
    handleSetType(type) {
      this.query = { ...this.query, typeId: type.id, page: 1 };
      this.selectedType = type;
    },
    handleSetSelectedCategory(cat) {
      this.query = { ...this.query, catId: cat.id };
      this.selectedCategory = cat;
    },
    handleClearSelectedCategory() {
      this.query = { ...this.query, catId: null };
      this.selectedCategory = { name: null };
    },
    handleSetCategories(categories) {
      this.categories = categories;
    },
  },
  watch: {
    query: {
      handler(query) {
        Object.keys(query).forEach(key => {
          if (query[key] === undefined || query[key] === null) {
            delete query[key];
          }
        });
        const route = { query };
        this.$router.push(route).catch(() => {});
      },
      deep: true,
    },
    categories: {
      handler(categories) {
        let catsById = keyById(categories, 'intID');

        if (this.query.catId) {
          this.selectedCategory = catsById[this.query.catId];
        }
        this.renderTable = true;
      },
      deep: true,
    },
  },
};
</script>
