<template>
  <div>
    <b-row class="text-light bg-dark p-2 font-weight-bold" align-v="center">
      <b-col class="align-middle text-center" align-v="center">{{
        clientName
      }}</b-col>
    </b-row>
    <div class="container">
      <iframe
        class="preview"
        :src="`${this.url}/mailable/dealer-reports-monthly/${clientId}/${startDate}/${endDate}/${templateId}`"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'Preview',
  computed: {
    ...mapState('client', [
      'clientName',
      'startDate',
      'endDate',
      'clientId',
      'selectedLanguage',
      'templateId',
    ]),
  },
  data() {
    return {
      url:
        window.location.origin == 'https://admin.agdealer.com'
          ? '//agdealer.com'
          : '//dev.agdealer.com',
    };
  },
};
</script>

<style lang="scss">
.preview {
  box-shadow: 2px 2px 8px;
  border-radius: 5px;
  width: 100%;
  height: 2000px;
  margin-top: 10px;
}
</style>
