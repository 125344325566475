<template>
  <section>
    <b-row>
      <b-col class="d-flex justify-content-left">
        <b-button
          v-b-modal.create-new
          v-b-tooltip.hover
          title="Select one or more rows with matching Categories"
          :disabled="isSelectedRowsValid"
          style="height: 40px"
        >
          <b-icon-plus-square class="mr-2" />
          Modify Subcategory
        </b-button>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-pagination
          v-if="renderPagination"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
        />
      </b-col>
    </b-row>
    <b-modal
      ref="add-subcat-modal"
      id="create-new"
      scrollable
      size="lg"
      title="Modify Subcategory"
      hide-footer
    >
      <b-container fluid>
        <add-sub-category-modal
          ref="form"
          :selectedRows="selectedItems"
          :currentCategories="filterSelectedRowCategories()"
          create-or-edit-mode="create"
          @submit="handleFormSubmitted"
          @cancel="handleCancel"
        />
      </b-container>
    </b-modal>
    <b-table
      v-if="renderTable"
      :fields="fields"
      :items="itemsProvider"
      :filter="tableFilter"
      :busy.sync="isBusy"
      :current-page="currentPage"
      :per-page="perPage"
      :selectable="true"
      select-mode="range"
      ref="modelsDataTable"
      @row-selected="onTableRowsSelected"
      show-empty
      striped
      hover
      class="small"
    >
      <!-- default head style -->
      <template #head()="data">
        {{ data.label }}
        <div class="small text-muted" v-html="data.field.subLabel" />
      </template>
      <template #cell(strManufacturer)="data">
        <b-link
          @click.prevent="$emit('mf-set', data.item._mf)"
          :title="`MF ID: ${data.item.intManufacturerID}`"
          v-html="data.value"
        />
      </template>
      <template #cell(strType)="data">
        <a
          href="#"
          :title="`Type ID: ${data.item.intTypeID}`"
          @click.prevent="$emit('type-set', data.item._type)"
        >
          {{ data.value }}
        </a>
        <div
          v-if="data.item.type_aliases && data.item.type_aliases.length"
          class="mt-3 small"
        >
          <div class="font-weight-bold">Type Aliases:</div>
          <ul class="mb-0 pl-4">
            <li
              v-for="(alias, key) in data.item.type_aliases"
              :key="key"
              :title="`Type Alias ID: ${alias.intTypeID}`"
            >
              {{ alias._type.strType || 'N/A' }}
            </li>
          </ul>
        </div>
      </template>
      <template #cell(intListingsTotal)="data" class="align-top text-nowrap">
        {{ data.value }}
        <span class="small text-muted ml-1 text-nowrap">
          ({{ data.item.intListingsActive }} /
          {{ data.item.intListingsArchived }})
        </span>
      </template>
      <template #cell(strName)="data">
        <b-link
          :to="{ name: 'modelDetails', params: { modelId: data.item.intID } }"
          :title="`Model ID: ${data.item.intID}`"
        >
          {{ data.value }}
        </b-link>
        <ul class="mb-0 pl-3">
          <li v-for="(alias, key) in data.item.alias_patterns" :key="key">
            <code v-html="alias.pattern" />
          </li>
        </ul>
      </template>
      <template #cell(category)="data">
        <ul class="mb-0 pl-4">
          <li
            v-for="(cat, key) in data.item.categories"
            :key="key"
            :title="`Cat ID: ${cat.id}`"
          >
            <span :title="cat.fr.category">{{ cat.en.category }}</span>
            <span class="mx-1">›</span>
            <span :title="cat.fr.subcategory">{{ cat.en.subcategory }}</span>
          </li>
        </ul>
      </template>
      <template #cell(merge)="data">
        <datatable-merge-buttons
          :current-item="data.item"
          :parent="selectedParentModel"
          :child-ids="selectedChildModelIds"
          @click-parent="$emit('merge-click-parent', data.item)"
          @click-child="$emit('merge-click-child', data.item)"
          @click-new="$emit('merge-click-new', data.item)"
        />
      </template>
      <template v-slot:empty="scope">
        <h4>{{ scope.emptyText }}</h4>
      </template>
    </b-table>
    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-pagination
          v-if="renderPagination"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import api from '@/api';
import DatatableMergeButtons from './DatatableMergeButtons';
import AddSubCategoryModal from './AddSubCategoryModal';
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';

const noItem = { name: null };
export default {
  name: 'Datatable',
  components: {
    DatatableMergeButtons,
    AddSubCategoryModal,
  },
  props: {
    selectedManufacturer: { type: Object, default: noItem },
    selectedType: { type: Object, default: noItem },
    selectedCategory: { type: Object, default: noItem },
    nameFilter: { type: String, default: '' },
    selectedParentModel: Object,
    selectedChildModelIds: Array,
    exactMatch: { type: Number, default: 0 },
  },
  data() {
    return {
      renderTable: false,
      renderPagination: false,
      isBusy: false,
      rows: 0,
      perPage: 50,
      currentPage: 1,
      selectedItems: [],
      fields: [
        { key: 'strManufacturer', label: 'Make' },
        { key: 'strType', label: 'Canon. Type', subLabel: 'Type Aliases' },
        {
          key: 'intListingsTotal',
          label: '# Listings',
          subLabel: '(Active/Arch)',
        },
        { key: 'strName', label: 'Model', subLabel: 'Name Aliases' },
        { key: 'category', label: 'Categories' },
        { key: 'merge', label: 'Merge' },
      ],
    };
  },
  async mounted() {
    if (this.$route.query.page) {
      this.currentPage = parseInt(this.$route.query.page);
    }
    this.renderTable = true;
  },
  watch: {
    $route: 'refreshTable',
    currentPage(page) {
      const route = { ...this.$route };
      route.query = {
        ...route.query,
        page,
      };
      this.$router.push(route).catch(() => {});
    },
  },
  computed: {
    ...mapGetters('models', {
      getManufacturersKeyedById: 'getManufacturersKeyedById',
      getTypesKeyedById: 'getTypesKeyedById',
      getTypesSorted: 'getTypesSorted',
      getMfsSorted: 'getMfsSorted',
      getManufacturerById: 'getManufacturerById',
      getTypeById: 'getTypeById',
    }),
    tableFilter() {
      const filter = {};
      if (this.selectedManufacturer) {
        filter['intManufacturerID'] = this.selectedManufacturer.id;
      }
      if (this.selectedType) {
        filter['intTypeID'] = this.selectedType.id;
      }
      if (this.selectedCategory) {
        filter['intCategoryID'] = this.selectedCategory.id;
      }
      if (this.nameFilter) {
        filter['strNameAsciiLower'] = this.nameFilter.toLowerCase();
      }
      return filter;
    },
    isSelectedRowsValid() {
      let selCategoriesObj = {};

      this.selectedItems.forEach(item => {
        const categories = item.categories;
        const modelId = item.intID;

        categories.forEach(cat => {
          selCategoriesObj[modelId] = {
            ...selCategoriesObj[modelId],
            [cat.id]: true,
          };
        });
      });

      return (
        this.selectedItems.length === 0 || !this.allAreEqual(selCategoriesObj)
      );
    },
  },
  methods: {
    ...mapActions('models', {
      loadManufacturers: 'loadManufacturers',
      loadModels: 'loadModels',
      loadTypes: 'loadTypes',
    }),
    allAreEqual(obj) {
      return Object.values(obj).every((value, _index, arr) => {
        return JSON.stringify(value) === JSON.stringify(arr[0]);
      });
    },
    refreshTable() {
      this.$refs.modelsDataTable && this.$refs.modelsDataTable.refresh();
    },
    async itemsProvider(ctx) {
      this.isBusy = true;
      await Promise.all([this.loadManufacturers(), this.loadTypes()]);

      const page = ctx.currentPage;
      const exactMatch = this.exactMatch;

      const paginationParams = {
        size: ctx.perPage,
        page,
        exactMatch,
      };

      try {
        // const models = await api.getModels(
        const models = await api.getModelsMerging(
          ctx.filter,
          ['-intInstances', 'strManufacturer', 'strModel'],
          paginationParams,
          ['listingsAll'],
        );
        models.data = models.data.map(m => {
          return {
            ...m,
            _type: this.getTypeById(m.intTypeID) || null,
            _mf: this.getManufacturerById(m.intManufacturerID) || null,
            type_aliases: m.type_aliases.map(ta => {
              return {
                ...ta,
                _type: this.getTypeById(ta.intTypeID),
              };
            }),
          };
        });

        this.currentPage = page;
        this.rows = models.meta.total;
        this.isBusy = false;
        this.renderPagination = true;

        return models.data;
      } catch (err) {
        this.rows = 0;
        this.currentPage = 1;
        this.isBusy = false;

        if (axios.isCancel(err)) {
          this.refreshTable();
        }

        return [];
      }
    },
    onTableRowsSelected(items) {
      this.selectedItems = items.map(item => {
        const {
          intID,
          strName,
          strManufacturer,
          strType,
          categories,
          intListingsTotal,
        } = item;

        return {
          intID,
          strName,
          strManufacturer,
          strType,
          categories,
          intListingsTotal,
        };
      });
    },
    handleFormSubmitted() {
      this.handleCancel();
      this.$refs.modelsDataTable.refresh();
    },
    handleCancel() {
      this.$refs['add-subcat-modal'].hide();
    },
    filterSelectedRowCategories() {
      let selectedCatName = [];

      this.selectedItems.forEach(item => {
        item.categories.forEach(cat => {
          selectedCatName[cat.id] =
            cat.strCategory + ' › ' + cat.strSubcategory;
        });
      });

      return selectedCatName.filter(item => item);
    },
  },
};
</script>
