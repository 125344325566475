<template>
  <div>
    <b-row>
      <b-col>
        <b-input-group class="mb-4 group-by">
          <template #prepend>
            <b-input-group-text>{{ $t('date.group_by') }}</b-input-group-text>
          </template>
          <b-form-select v-model="selectedTimeFrame" @change="timeFrameUpdated">
            <b-form-select-option value="0">{{
              $t('date.day')
            }}</b-form-select-option>
            <b-form-select-option value="1">{{
              $t('date.week')
            }}</b-form-select-option>
            <b-form-select-option value="2">{{
              $t('date.month')
            }}</b-form-select-option>
          </b-form-select>
        </b-input-group>
      </b-col>
      <b-col class="text-right">
        <download-report></download-report>
      </b-col>
    </b-row>
    <b-row class="mr-1 ml-1 mb-3 p-2 bg-dark text-white rounded">
      <b-col
        >{{ $t('totalsrow.total_rows') }}:
        <strong>{{ formatNumber(tableTotals.totalRows) }}</strong></b-col
      >
      <b-col
        >{{ $t('totalsrow.total_detail_page_views') }}:
        <strong>{{ formatNumber(tableTotals.detailViewTotal) }}</strong></b-col
      >
      <b-col
        >{{ $t('totalsrow.total_search_page_views') }}:
        <strong>{{ formatNumber(tableTotals.srpViewTotal) }}</strong></b-col
      >
    <!--  <b-col
        >{{ $t('totalsrow.total_activity') }}:
        <strong>{{ formatNumber(tableTotals.actionCountTotal) }}</strong></b-col
      > -->
      <b-col
        >{{ $t('totalsrow.listing_count_total') }}:
        <strong>{{ formatNumber(tableTotals.listingCountTotal) }}</strong>
        <span v-b-tooltip.hover="tooltipRowData" class="float-right"
          ><b-icon-info-circle></b-icon-info-circle></span
      ></b-col>
    </b-row>
    <b-row>
      <b-col>
        <span v-b-tooltip.hover="tooltipData" class="float-right"
          ><b-icon-info-circle></b-icon-info-circle
        ></span>
      </b-col>
    </b-row>
    <b-table
      responsive
      striped
      hover
      no-provider-sorting
      no-provider-filtering
      sort-icon-left
      :fields="fields"
      :items="items"
      :busy="isBusy"
      ref="table"
      show-empty
    >
      <template #empty="scope">
        <p class="text-center font-weight-bold text-danger">
          {{ scope.emptyText }}
        </p>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(dtDate)="data">
        <span
          v-if="selectedTimeFrame === '0'"
          :title="data.value"
          class="text-nowrap"
        >
          {{ formatDate(data.value, 'mediumDate') }}<br /><span
            class="font-weight-lighter"
            >{{ formatDate(data.value, 'dddd') }}</span
          >
        </span>
        <span v-else-if="selectedTimeFrame === '1'" class="text-nowrap">{{
          formatDate(data.value, 'mediumDate')
        }}</span>
        <span v-else-if="selectedTimeFrame === '2'">{{
          formatDate(data.value, 'mmmm')
        }}</span>
      </template>
      <template #head()="data">
        <span>{{ $t('date.' + data.label) }}</span>
      </template>
      <template #head(intSrpViewCount)="data">
        <span
          >{{ $t('date.' + data.label) }}
          <b-icon-info-circle id="sprViews"></b-icon-info-circle>
          <b-tooltip target="sprViews" triggers="hover"
            >Number of search page views for this listing.</b-tooltip
          ></span
        >
      </template>
      <template #head(intDetailViewCount)="data">
        <span
          >{{ $t('date.' + data.label) }}
          <b-icon-info-circle id="detailViews"></b-icon-info-circle>
          <b-tooltip target="detailViews" triggers="hover"
            >Number of detail views for this listing.</b-tooltip
          ></span
        >
      </template>
    </b-table>
  </div>
</template>

<script>
import dateFormat from 'dateformat';
import api from '../../../api/index';
import DownloadReport from '../../DownloadReport';
import { mapState } from 'vuex';

export default {
  name: 'ByDateDatatable',
  components: { DownloadReport },
  data() {
    return {
      headerLabels: ['day', 'week', 'month'],
      items: [],
      allItems: [],
      isBusy: false,
      firstLoad: true,
      tableTotals: {},
      selectedTimeFrame: '0',
      tooltipData: {
        title:
          '<p>Date → Insert tooltip text here</p><p>Search Page Views → Number of search page views on AgDealer for this date.</p><p>Detail Page Views → Number of detail page views on AgDealer for this date.</p><p>Email Leads → Number of email leads for this date.</p><p>Phone Clicks → Number of phone clicks for this date.</p><p>Listing Count → Number of listings for this date.</p>',
        html: true,
        placement: 'right',
        variant: 'secondary',
        customClass: 'mediumToolTip',
      },
      tooltipRowData: {
        title:
          '<p>Total Rows → Total number of report rows for the client and date selected.</p><p>Total Detail Views → Summary of the AgDealer detail page views for the client and date selected.</p><p>Total Search Page Views → Summary of search page views for the client and date selected.</p><p>Listing Count Total → Summary of the Listing Count for the client and date selected.</p>',
        html: true,
        placement: 'right',
        variant: 'secondary',
        customClass: 'mediumToolTip',
      },
    };
  },
  computed: {
    ...mapState('client', ['startDate', 'endDate', 'clientId']),
    rows() {
      return this.items.length;
    },
    fields() {
      return [
        { key: 'dtDate', label: this.dateHeader(), sortable: true },
        {
          key: 'intSrpViewCount',
          label: 'search_page_views',
          sortable: true,
          formatter: this.formatNumber,
        },
        {
          key: 'intDetailViewCount',
          label: 'detail_page_views',
          sortable: true,
          formatter: this.formatNumber,
        },
        {
          key: 'intListingInquiryCount',
          label: 'email_leads',
          sortable: true,
          formatter: this.formatNumber,
        },
        {
          key: 'intRetrievePhoneCount',
          label: 'phone_clicks',
          sortable: true,
        },
        {
          key: 'intListingCount',
          label: 'listing_count',
          sortable: true,
          formatter: this.formatNumber,
        },
      ];
    },
  },
  methods: {
    dateHeader() {
      return this.headerLabels[this.selectedTimeFrame];
    },
    formatNumber: value => parseInt(value).toLocaleString(),
    formatDate(date, format) {
      return dateFormat(date, format, true);
    },
    async itemsProvider() {
      this.isBusy = true;
      const response = await api.getReportsClientByDate({
        intClientID: this.clientId,
        dtStartDate: this.startDate,
        dtEndDate: this.endDate,
      });

      this.items = this.allItems[0] = response.data;

      this.firstLoad = false;
      this.isBusy = false;
      this.createWeeklyItems();
      this.calculateTableTotals();
      this.createMonthlyItems();
      return this.items;
    },
    calculateTableTotals() {
      this.tableTotals.totalRows = this.items.length;
      this.tableTotals.srpViewTotal = this.items.reduce(
        (prev, cur) => prev + parseInt(cur.intSrpViewCount),
        0,
      );
      this.tableTotals.listingCountTotal = this.items.reduce(
        (prev, cur) => prev + parseInt(cur.intListingCount),
        0,
      );
      this.tableTotals.detailViewTotal = this.items.reduce(
        (prev, cur) => prev + parseInt(cur.intDetailViewCount),
        0,
      );
    },
    updateCount(obj, el) {
      obj.intSrpViewCount += el.intSrpViewCount;
      obj.intDetailViewCount += el.intDetailViewCount;
      obj.intListingInquiryCount += el.intListingInquiryCount;
      obj.intRetrievePhoneCount += el.intRetrievePhoneCount;
      obj.intListingCount += el.intListingCount;
      obj.intAverageViews += el.intAverageViews;
    },
    createWeeklyItems() {
      let weekItems = [];
      let week = this.countObject();
      let num = 0;
      this.allItems[0].forEach(el => {
        let utc = new Date(el.dtDate);
        let d = new Date(utc.getTime() + utc.getTimezoneOffset() * 60000);
        if (d.getUTCDay() === 0) {
          week = this.countObject();
          num++;
        }
        if (d.getUTCDay() === 6 && week.dtDate === '') {
          week.dtDate = el.dtDate;
        } else {
          week.dtDate = new Date(d.setDate(d.getUTCDate() - d.getUTCDay()));
        }
        this.updateCount(week, el);
        weekItems[num] = week;
      });
      this.allItems[1] = weekItems;
    },
    createMonthlyItems() {
      let monthItems = [];
      let month = this.countObject();
      let num = 0;
      let currentMonth = null;
      this.allItems[0].forEach(el => {
        let d = new Date(el.dtDate);
        if (currentMonth != d.getMonth() && currentMonth != null) {
          num++;
          month = this.countObject();
        }
        if (month.dtDate === '') month.dtDate = el.dtDate;
        this.updateCount(month, el);
        currentMonth = d.getMonth();
        monthItems[num] = month;
      });
      this.allItems[2] = monthItems;
    },
    timeFrameUpdated() {
      this.items = this.allItems[this.selectedTimeFrame];
      this.$store.dispatch(
        'client/setSelectedTimeFrame',
        parseInt(this.selectedTimeFrame),
      );
    },
    countObject() {
      return {
        dtDate: '',
        intSrpViewCount: 0,
        intDetailViewCount: 0,
        intListingInquiryCount: 0,
        intRetrievePhoneCount: 0,
        intActionCount: 0,
        intListingCount: 0,
        intAverageViews: 0,
      };
    },
  },
  mounted() {
    this.itemsProvider();
  },
};
</script>

<style lang="scss" scoped>
.group-by {
  width: 250px;
}
</style>
