<template>
  <div v-if="visible">
    <label>Suggested Categories</label>
    <b-list-group>
      <b-list-group-item
        button
        v-for="c in suggestedCategories"
        :key="c.id"
        @click="handleClick(c)"
      >
        <span class="mr-3"><b-icon-circle /></span>
        <span :title="`score: ${c._score}`">{{ c.text }}</span>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import stringSimilarity from 'string-similarity';

export default {
  name: 'SuggestedCategoriesPicker',
  props: {
    availableCategories: {
      type: Array,
      required: true,
    },
    compareValue: {
      type: String,
      default: '',
    },
    minScore: {
      type: Number,
      default: 0.33,
    },
    maxItems: {
      type: Number,
      default: 3,
    },
  },
  methods: {
    handleClick(c) {
      this.$emit('item-click', c);
    },
  },
  computed: {
    visible() {
      switch (this.compareValue) {
        case '':
        case 'N/A':
          return false;
      }
      return this.suggestedCategories.length > 0;
    },
    scoredCategories() {
      return this.availableCategories.map(c => {
        const base = {
          ...c,
          _score: 1,
        };
        if (this.compareValue !== '') {
          base._score = stringSimilarity.compareTwoStrings(
            this.compareValue,
            base.text,
          );
        }
        return base;
      });
    },
    sortedCategories() {
      return [...this.scoredCategories].sort((a, b) => {
        if (a._score > b._score) return -1;
        if (a._score < b._score) return 1;
        return 0;
      });
    },
    suggestedCategories() {
      return this.sortedCategories
        .filter(c => c._score > this.minScore)
        .slice(0, this.maxItems);
    },
  },
};
</script>
