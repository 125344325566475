<template>
  <div id="app">
    <b-navbar toggleable="lg" type="dark" variant="primary">
      <b-navbar-brand href="#">AgDealer Admin</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="user">
          <b-nav-item :to="{ name: 'dashboard' }">Dashboard</b-nav-item>
          <b-nav-item :to="{ name: 'models' }">Models</b-nav-item>
          <b-nav-item :to="{ name: 'model-merges' }">
            Models for Merging
          </b-nav-item>
          <b-nav-item :to="{ name: 'unmatched' }">Unmatched</b-nav-item>
          <b-nav-item :to="{ name: 'categories' }">Categories</b-nav-item>
          <b-nav-item-dropdown text="Reports" right>
            <b-dropdown-item :to="{ name: 'reports-details' }">
              Reports Details
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right v-if="user">
            <!-- Using 'button-content' slot -->
            <template v-slot:button-content>
              <b-icon-person />
              {{ user.strUserName }}
            </template>
            <b-dropdown-item :to="{ name: 'tokens' }">
              Access Tokens
            </b-dropdown-item>
            <b-dropdown-item button @click="logOut">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item right v-else to="/login">Log In</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-container fluid="true" class="mt-5 px-4">
      <router-view></router-view>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'App',
  computed: {
    ...mapState('user', {
      user: state => state.user,
    }),
  },
  methods: {
    ...mapActions('user', {
      processLogout: 'processLogout',
      getUser: 'getUser',
    }),
    async logOut() {
      await this.processLogout();
      await this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style>
#app {
  height: 100%;
}
</style>
