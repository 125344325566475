import axios from 'axios';
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import VueCompositionAPI from '@vue/composition-api';
import VueGtag from 'vue-gtag';
import VueRouter from 'vue-router';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import { config } from '@/common/config';
import { provide } from '@vue/composition-api';
import VueI18n from 'vue-i18n';
import en from './locales/en.json';
import fr from './locales/fr.json';

import clickOutside from './directives/click-outside';
import makeMirageServer from './__mirage/mirage-server';
import router from '@/routes';
import store from '@/store';
import App from './App.vue';

if (process.env.NODE_ENV === 'development') {
  makeMirageServer();
}

axios.defaults.withCredentials = true;

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueRouter);
Vue.use(VueApexCharts);
Vue.use(VueCompositionAPI);

Vue.use(VueI18n);

Vue.component('apexchart', VueApexCharts);

Vue.use(
  VueGtag,
  {
    config: {
      id: config.googleAnalyticsUA,
    },
    enabled: config.googleAnalyticsEnabled,
  },
  router,
);

const i18n = new VueI18n({
  locale: 'en',
  messages: { en, fr },
});

// Used to detect if a user has clicked outside of the element
// https://www.lavalite.org/blog/detect-click-outside-an-element-in-vuejs
Vue.directive('click-outside', clickOutside);

let $vm = new Vue({
  render: h => h(App),
  // use provide method to allow for clean use of vuex with composition api
  setup() {
    provide('store', store);
  },
  i18n,
  router,
  store,
  created() {
    const userInfo = localStorage.getItem('user');
    if (userInfo) {
      const userData = JSON.parse(userInfo);
      this.$store.commit('user/setUser', userData);
    }
  },
}).$mount('#app');

if (!window.vm) {
  window.vm = $vm;
}
