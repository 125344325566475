<template>
  <b-form
    @submit.stop.prevent="showConfirmation"
    @reset.stop.prevent="handleCancel"
  >
    <b-overlay :show="isSubmitting">
      <b-container>
        <b-row>
          <b-col xs="12" md="12" lg="12">
            <strong>Selected items:</strong>
            <ul id="selected-rows">
              <li v-for="item in selectedRows" :key="item.intID">
                {{ item.strManufacturer }} › {{ item.strType }} ›
                {{ item.strName }}
              </li>
            </ul>
          </b-col>
        </b-row>

        <b-row class="mb-3" v-if="operation !== 'Delete'">
          <b-col xs="12" md="12" lg="12">
            <strong>Current Categories:</strong>
            <ul id="selected-rows-categories">
              <li v-for="(item, index) in currentCategories" :key="index">
                {{ item }}
              </li>
            </ul>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col xs="12" md="12" lg="12">
            <strong>Select action to perform:</strong>
            <b-form-group label="" v-slot="{ ariaDescribedby }">
              <b-form-radio
                v-model="operation"
                :aria-describedby="ariaDescribedby"
                name="operation-type"
                value="Add"
                >Add
              </b-form-radio>
              <b-form-radio
                v-model="operation"
                :aria-describedby="ariaDescribedby"
                name="operation-type"
                value="Replace"
                >Replace
              </b-form-radio>
              <b-form-radio
                v-model="operation"
                :aria-describedby="ariaDescribedby"
                name="operation-type"
                value="Delete"
                >Delete
              </b-form-radio>
            </b-form-group>
            <div class="mt-3 text-muted">
              <span v-if="operation === 'Add'"
                ><small
                  >Note: This adds the new subcategory to current
                  subcategories</small
                ></span
              >
              <span v-if="operation === 'Replace'"
                ><small
                  >Note: This replace the current subcategories with new
                  category</small
                ></span
              >
              <span v-if="operation === 'Delete'"
                ><small
                  >Note: This deletes the selected subcategories</small
                ></span
              >
            </div>
          </b-col>
        </b-row>

        <b-row class="mb-3" v-if="operation === 'Delete'">
          <b-col xs="12" md="12" lg="12">
            <strong
              >Select which one you want to delete from current
              categories:</strong
            >
            <b-form-group label="" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="categoriesToDelete"
                :options="currentCategories"
                :aria-describedby="ariaDescribedby"
                name="categories-to-delete"
                stacked
              ></b-form-checkbox-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="operation !== 'Delete'">
          <b-col xs="12" md="12" lg="12">
            <typeahead
              label="New Category"
              :items="categories"
              :selectedItem="selectedCategory"
              :show-item-id="true"
              item-id-key="intID"
              item-label-key="text"
              :disable-input="false"
              @set-query="handleSetCategory"
              @clear-query="handleClearCategory"
              :min-query-length="0"
            />
          </b-col>
        </b-row>
        <b-row
          class="mb-3 error"
          v-if="catAlreadyExists && this.operation === 'Add'"
        >
          <b-col xs="12" md="12" lg="12">
            <strong>New category already exists in current categories.</strong>
          </b-col>
        </b-row>
        <div class="mt-3 mx-auto" style="width: 200px">
          <b-button
            @click.prevent.stop="showConfirmation"
            :disabled="showSaveButton()"
            class="mr-2"
          >
            Submit
          </b-button>
          <b-button type="reset" variant="danger">Cancel</b-button>
        </div>
      </b-container>
    </b-overlay>
  </b-form>
</template>

<script>
import Typeahead from '@/components/Typeahead';
import api from '@/api';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AddSubCategoryModal',
  components: {
    Typeahead,
  },
  data() {
    return {
      isSubmitting: false,
      categories: [],
      selectedCategory: { name: null },
      operation: 'Add',
      catAlreadyExists: false,
      categoriesToDelete: [],
    };
  },
  props: {
    createOrEditMode: {
      type: String,
      default: 'edit',
      validator: value => ['edit', 'create'].indexOf(value) !== -1,
    },
    selectedRows: {
      type: Array,
      required: true,
    },
    currentCategories: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.populateCategories();
  },
  computed: {
    ...mapState('models', {
      availableCategories: state => state.categories,
      categoriesLoaded: state => state.categoriesLoaded,
    }),
  },
  methods: {
    ...mapActions('models', {
      loadCategories: 'loadCategories',
    }),
    async populateCategories() {
      if (!this.categoriesLoaded) {
        await this.loadCategories();
      }
      this.categories = this.availableCategories.map(cat => {
        const category = cat.strCategory.trim();
        const subcategory = cat.strSubcategory.trim();
        const text = `${category} › ${subcategory}`;
        return {
          id: cat.intID,
          text,
          ...cat,
        };
      });

      this.$emit('cat-map-set', this.categories);
    },
    handleClearCategory() {
      this.selectedCategory = { name: null };
    },
    handleSetCategory(cat) {
      this.selectedCategory = cat;
    },
    resetData() {
      this.selectedCategory = { name: '' };
    },
    handleCancel() {
      this.resetData();
      this.$emit('cancel');
    },
    listingsImpacted() {
      let count = 0;

      this.selectedRows.forEach(item => {
        count += item.intListingsTotal;
      });

      return count;
    },
    async showConfirmation() {
      await this.$bvModal
        .msgBoxConfirm(
          'This action will effect ' +
            this.listingsImpacted() +
            ' listings. Are you sure you wish to add this sub-category?',
        )
        .then(value => {
          if (value === true) {
            this.handleSubmit();
          }
        });
    },
    showSaveButton() {
      let selectedCategoryText = this.selectedCategory.text;
      this.catAlreadyExists =
        this.currentCategories.includes(selectedCategoryText);

      return (
        (typeof selectedCategoryText === 'undefined' &&
          this.operation !== 'Delete') ||
        (this.catAlreadyExists && this.operation === 'Add') ||
        (this.categoriesToDelete.length === 0 && this.operation === 'Delete')
      );
    },
    async handleSubmit() {
      this.isSubmitting = true;

      try {
        let response = { status: 'unsubmitted' };
        if (this.operation !== 'Delete') {
          response = await api.updateModelSubCategory(
            this.selectedCategory.id,
            this.selectedRows.map(item => item.intID),
            this.operation,
          );
        } else {
          let deletedSubcatIds = this.selectedRows[0].categories.map(item => {
            if (
              this.categoriesToDelete.includes(
                item.strCategory + ' › ' + item.strSubcategory,
              )
            ) {
              return item.id;
            }
          });

          response = await api.deleteSubcategoriesFromModel(
            deletedSubcatIds.filter(item => item),
            this.selectedRows.map(item => item.intID),
          );
        }

        if (response.status === 'success') {
          this.$emit('submit');
          this.resetData();
        }
      } catch (err) {
        let message = err.response.data.message || 'An unknown error occurred';

        if (typeof message === 'object') {
          message = JSON.stringify(err.response.data.message);
        }

        window.alert(message);
      }

      this.isSubmitting = false;
    },
  },
};
</script>
