<template>
  <div>
    <p class="bg-light text-dark mb-0 p-2 text-center font-weight-bold">
      {{ $t('locationtotals.title') }}
      <span v-b-tooltip.hover="tooltipData" class=""
        ><b-icon-info-circle></b-icon-info-circle
      ></span>
    </p>
    <b-table
      responsive
      striped
      hover
      :fields="fields"
      :items="itemsProvider"
      class="font-12"
      show-empty
    >
      <template #empty="scope">
        <p class="text-center font-weight-bold text-danger">
          {{ scope.emptyText }}
        </p>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #head()="data">
        <span>{{ $t('locationtotals.' + data.label) }}</span>
      </template>
    </b-table>
  </div>
</template>

<script>
import api from '../../api/index';
import { mapState } from 'vuex';

export default {
  name: 'LocationTotals',
  data() {
    return {
      fields: [
        { key: 'strLocationName', label: 'location' },
        {
          key: 'intSrpViews',
          label: 'search_page_views',
          formatter: this.formatNumber,
        },
        {
          key: 'intDetailViews',
          label: 'detail_page_views',
          formatter: this.formatNumber,
        },
        {
          key: 'intEmailLeads',
          label: 'email_leads',
          formatter: this.formatNumber,
        },
        {
          key: 'intPhoneCallLeads',
          label: 'phone_clicks',
          formatter: this.formatNumber,
        },
        { key: 'intTotalImages', label: 'images' },
      ],
      items: [],
      isBusy: false,
      tooltipData: {
        title:
          '<p>Location → Dealer location for the listing item.</p><p>Search Page Views → Number of search page views for this location.</p><p>Detail Page Views → Number of detail page views for this location.</p><p>Email Leads → Number of email leads for this location.</p><p>Phone Clicks → Number of phone clicks for this location.</p><p>Images → Number of images for the listings for this location.</p>',
        html: true,
        placement: 'top',
        variant: 'secondary',
        customClass: 'mediumToolTip',
      },
    };
  },
  computed: {
    ...mapState('client', ['startDate', 'endDate', 'clientId']),
  },
  methods: {
    formatNumber: value => parseInt(value).toLocaleString(),
    async itemsProvider() {
      this.isBusy = true;
      const response = await api.getReportsClientByLocation({
        intClientID: this.clientId,
        dtStartDate: this.startDate,
        dtEndDate: this.endDate,
      });
      this.items = response.data;
      this.isBusy = false;
      return this.items;
    },
  },
};
</script>
