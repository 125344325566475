<template>
  <b-button @click="showDeleteModal" variant="danger" :class="classes">
    Delete
  </b-button>
</template>

<script>
import api from '@/api';

export default {
  name: 'ModelDelete',
  props: {
    model: Object,
    classes: [String, Array],
  },
  methods: {
    async showDeleteModal() {
      await this.$bvModal
        .msgBoxConfirm('Are you sure you wish to delete this Model?')
        .then(value => {
          if (value === true) {
            // OK
            console.log('deleting model: ', this.model.intID);
            const response = api.deleteModel(this.model.intID);
            this.$emit('model-deleted', response);
          } else if (value === false) {
            // cancel
            this.$emit('cancelled');
          }
        })
        .catch(err => {
          this.$emit('error', err);
        });
    },
  },
};
</script>
