<template>
  <div>
    <p class="bg-light text-dark mb-0 p-3 text-center font-weight-bold">
      {{ $t('dashboardchart.title') }}
      <span v-b-tooltip.hover="tooltipData" class=""
        ><b-icon-info-circle></b-icon-info-circle
      ></span>
      <b-form-select
        class="chart-select w-25"
        v-model="selectedChart"
        @change="updateChart"
      >
        <b-form-select-option value="dailyViews">{{
          $t('dashboardchart.daily_views')
        }}</b-form-select-option>
        <b-form-select-option value="srpViews">{{
          $t('dashboardchart.srp_views')
        }}</b-form-select-option>
        <b-form-select-option value="emailLeads">{{
          $t('dashboardchart.email_leads')
        }}</b-form-select-option>
        <b-form-select-option value="phoneLeads">{{
          $t('dashboardchart.phone_clicks')
        }}</b-form-select-option>
      </b-form-select>
    </p>
    <apexchart
      height="250"
      type="bar"
      :options="options"
      :series="series"
      ref="dashboardChart"
    ></apexchart>
  </div>
</template>

<script>
import api from '../../api/index';
import dateFormat from 'dateformat';
import { mapState } from 'vuex';
import en from '../../locales/en-apex.json';
import fr from '../../locales/fr-apex.json';

export default {
  name: 'DailyDetailViews',
  data() {
    return {
      names: {
        dailyViews: this.$i18n.t('dashboardchart.daily_views'),
        srpViews: this.$i18n.t('dashboardchart.srp_views'),
        emailLeads: this.$i18n.t('dashboardchart.email_leads'),
        phoneLeads: this.$i18n.t('dashboardchart.phone_clicks'),
      },
      selectedChart: 'dailyViews',
      options: {
        chart: {
          id: 'daily-detail-views',
          locales: [en, fr],
          defaultLocale: localStorage.reportsLanguage,
          toolbar: {
            tools: {
              download: false,
            },
          },
        },
        xaxis: {
          categories: [],
          tickPlacement: 'on',
          type: 'datetime',
        },
        dataLabels: {
          enabled: false,
        },
      },
      series: [
        {
          name: 'Daily Detail Views',
          data: [],
        },
      ],
      chartData: {},
      chartDates: [],
      tooltipData: {
        title:
          '<p>Daily Views → Displays the number of detail views per day in the graph.</p><p>Search Page Views → Displays the number of search page views per day in the graph.</p><p>Email Leads → Displays the number of email leads per day in the graph.</p><p>Phone Clicks → Displays the number of phone clicks per day in the graph.</p>',
        html: true,
        placement: 'right',
        variant: 'secondary',
        customClass: 'mediumToolTip',
      },
    };
  },
  computed: {
    ...mapState('client', [
      'startDate',
      'endDate',
      'clientId',
      'selectedLanguage',
    ]),
  },
  methods: {
    async fetchChartData() {
      const response = await api.getReportsClientByDate({
        intClientID: this.clientId,
        dtStartDate: this.startDate,
        dtEndDate: this.endDate,
      });
      this.buildChartData(response.data);
    },
    buildChartData(data) {
      this.chartData.dailyViews = data.map(d => d.intDetailViewCount);
      this.chartData.srpViews = data.map(d => d.intSrpViewCount);
      this.chartData.emailLeads = data.map(d => d.intListingInquiryCount);
      this.chartData.phoneLeads = data.map(d => d.intRetrievePhoneCount);
      this.chartDates = data.map(d => dateFormat(d.dtDate, 'isoDate', true));
      this.updateChart();
    },
    updateChart() {
      this.series = [
        {
          name: this.names[this.selectedChart],
          data: this.chartData[this.selectedChart],
        },
      ];
      this.options = {
        ...this.options,
        ...{
          xaxis: {
            categories: this.chartDates,
          },
        },
      };
    },
  },
  mounted() {
    this.fetchChartData();
  },
  watch: {
    '$store.state.client.selectedLanguage': function () {
      this.$refs.dashboardChart.setLocale(this.selectedLanguage);
      this.updateChart();
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  position: relative;
}
.chart-select {
  position: absolute;
  right: 5px;
  top: 8px;
}
</style>
