<template>
  <b-form-group
    class="typeahead"
    v-click-outside="hideSuggestions"
    :disabled="disableInput"
  >
    <b-input-group :prepend="label">
      <b-form-input
        v-model="query"
        ref="input"
        @focus="showSuggestions"
        autocomplete="off"
        :type="fieldType"
      />
      <template v-slot:append>
        <b-button v-if="!disableAppend" @click.prevent="clear">{{
          $t('typeahead.clear')
        }}</b-button>
      </template>
    </b-input-group>
    <div v-if="suggestionsAreVisible" class="suggestions">
      <b-list-group>
        <b-list-group-item
          v-for="(item, key) in suggestions"
          :key="key"
          href="#"
          @click="setQuery(item)"
          v-html="getItemHtml(item)"
        />
      </b-list-group>
    </div>
    <div class="text-muted small" v-if="showItemId && selectedItem[itemIdKey]">
      ID: {{ selectedItem[itemIdKey] }}
    </div>
    <div
      class="text-muted small"
      v-if="showFrenchLabel && selectedItem[itemFrenchLabelKey]"
    >
      fr: {{ selectedItem[itemFrenchLabelKey] }}
    </div>
  </b-form-group>
</template>

<script>
export default {
  name: 'Typeahead',
  events: ['setQuery', 'clearQuery'],
  props: {
    label: {
      type: String,
      default: null,
    },
    disableAppend: {
      type: Boolean,
      default: false,
    },
    disableInput: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    fieldType: {
      type: String,
      default: 'text',
    },
    /**
     * Generally the item label should be the 'name' property,
     * but in the event that this isn't the case, we have this
     * optional prop to specify something else (ie. name_f, title, etc).
     */
    itemLabelKey: {
      type: String,
      default: 'name',
    },
    itemIdKey: {
      type: String,
      default: 'id',
    },
    itemFrenchLabelKey: {
      type: String,
      default: 'name',
    },
    showItemId: {
      type: Boolean,
      default: false,
    },
    showFrenchLabel: {
      type: Boolean,
      default: false,
    },
    /**
     * What number of characters should be reached before the
     * suggestions become visible and start filtering?
     */
    minQueryLength: {
      type: Number,
      default: 2,
    },
    selectedItem: {
      type: Object,
      required: false,
    },
    labelFormatter: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      suggestionsVisible: false,
      query: '',
    };
  },
  methods: {
    getItemHtml(item) {
      if (typeof this.labelFormatter === 'function') {
        return this.labelFormatter(item);
      }
      let content = item[this.itemLabelKey] || '';
      if (this.showItemId) {
        content += `<span class="ml-2 small text-muted text-nowrap">(ID: ${
          item[this.itemIdKey]
        })</span>`;
      }
      if (this.showFrenchLabel) {
        content += `<span class="ml-2 small text-muted text-nowrap">(fr: ${
          item[this.itemFrenchLabelKey]
        })</span>`;
      }
      return content;
    },
    clear() {
      this.query = '';
      this.$emit('clear-query');
      this.hideSuggestions();
    },
    hideSuggestions() {
      this.suggestionsVisible = false;
    },
    showSuggestions() {
      this.suggestionsVisible = true;
    },
    setQuery(item) {
      this.query = item[this.itemLabelKey] || '';
      this.$emit('set-query', item);
      this.hideSuggestions();
    },
  },
  mounted() {
    this.query = this.selectedItemQuery;
  },
  computed: {
    selectedItemQuery() {
      const si = this.selectedItem;
      return si && typeof si[this.itemLabelKey] !== 'undefined'
        ? si[this.itemLabelKey] || ''
        : '';
    },
    suggestions() {
      if (this.query.length < this.minQueryLength) return [];
      return this.items.filter(item => {
        return (
          item[this.itemLabelKey]
            .toLowerCase()
            .indexOf(this.query.toLowerCase()) > -1
        );
      });
    },
    suggestionsAreVisible() {
      return (
        this.suggestionsVisible && this.query.length >= this.minQueryLength
      );
    },
  },
  watch: {
    selectedItem: {
      handler() {
        this.query = this.selectedItemQuery;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.typeahead {
  position: relative;
}
.suggestions {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 9999;
  background-color: #efefef;
  max-height: 250px;
  overflow-y: auto;
  border-bottom: 10px solid #efefef;
  border-radius: 0 0 10px 10px;
}
</style>
