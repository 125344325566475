<template>
  <b-form-group label="Sidebar Type and Type Aliases">
    <type-typeahead
      :init-selected-item="selectedType"
      :init-items="getTypesSorted"
      @set-item="setType"
    />

    <details class="mb-2" open>
      <summary>
        Type Aliases
        <small class="ml-2 text-muted">
          Additional types for which this model should be matched
        </small>
      </summary>
      <type-alias-selector
        :available-aliases="getTypesKeyedById"
        :current-alias-ids="typeAliasIds"
        :parent-alias-id="selectedType.intID || 0"
        @change="setTypeAliases"
      />
    </details>
  </b-form-group>
</template>

<script>
import TypeTypeahead from '@/components/Typeaheads/TypeTypeahead';
import TypeAliasSelector from '@/components/TypeAliasSelector';
import { mapGetters } from 'vuex';

export default {
  name: 'SidebarChangeTypeAndAliases',
  components: {
    TypeTypeahead,
    TypeAliasSelector,
  },
  props: {
    selectedType: Object,
    typeAliasIds: Array,
  },
  computed: {
    ...mapGetters('models', {
      getTypesSorted: 'getTypesSorted',
      getTypesKeyedById: 'getTypesKeyedById',
    }),
  },
  methods: {
    setType(type) {
      this.$emit('set-type', type);
    },
    setTypeAliases(aliasIds) {
      this.$emit('set-type-aliases', aliasIds);
    },
  },
};
</script>
