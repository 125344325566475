<template>
  <b-container fluid="true">
    <b-row>
      <b-col>
        <b-form-radio-group
          id="btn-radios-1"
          v-model="langSelected"
          :options="langOptions"
          v-on:change="this.updateLanguage"
          name="radios-btn-default"
          button-variant="outline-primary"
          buttons
          size="sm"
          class="float-right"
        ></b-form-radio-group>
      </b-col>
    </b-row>
    <h2 class="mb-3">{{ $t('reportsDetails.title') }}</h2>
    <b-row class="mt-3 mb-3">
      <b-col>
        <typeahead
          :label="$t('reportsDetails.rep')"
          :selectedItem="selectedRep"
          :items="reps"
          :min-query-length="0"
          :show-item-id="true"
          item-id-key="intRepID"
          @set-query="setRep"
          @clear-query="clearRep"
          class="mb-0"
          :itemLabelKey="'strFullName'"
        />
      </b-col>
      <b-col>
        <typeahead
          :label="$t('reportsDetails.client')"
          :selectedItem="selectedClient"
          :items="clients"
          :min-query-length="0"
          :show-item-id="true"
          item-id-key="intClientID"
          @set-query="setClient"
          @clear-query="clearClient"
          class="mb-0"
          :itemLabelKey="'strClientName'"
        />
      </b-col>
      <b-col>
        <date-range class="mb-5" @selected="updateDateRange" />
      </b-col>
      <b-col>
        <b-button :disabled="disabled" @click="chartSubmit">{{
          $t('reportsDetails.submit')
        }}</b-button>
      </b-col>
    </b-row>
    <div v-if="isBusy && firstLoad" class="text-center">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-if="!firstLoad">
      <b-row>
        <b-col>
          <b-nav class="mb-3" tabs>
            <b-nav-item
              :active="currentChart === 'dashboard'"
              @click="updateChartType('dashboard')"
              >{{ $t('reportsDetails.dashboard') }}</b-nav-item
            >
            <b-nav-item
              :active="currentChart === 'listing'"
              @click="updateChartType('listing')"
              >{{ $t('reportsDetails.listings') }}</b-nav-item
            >
            <b-nav-item
              :active="currentChart === 'location'"
              @click="updateChartType('location')"
              >{{ $t('reportsDetails.location') }}</b-nav-item
            >
            <!--            <b-tab-->
            <!--              @click="updateChartType('location')"-->
            <!--              title="By Location (chart)"-->
            <!--            ></b-tab>-->
         <b-nav-item
              :active="currentChart === 'date'"
              @click="updateChartType('date')"
              >{{ $t('reportsDetails.date') }}</b-nav-item
            >
            <!--      <b-nav-item
                 :active="currentChart === 'type'"
                 @click="updateChartType('type')"
                 >By Type</b-nav-item
               > -->
            <b-nav-item
                :active="currentChart === 'email-leads'"
                @click="updateChartType('email-leads')"
            >{{ $t('reportsDetails.emailleads') }}</b-nav-item
            >
            <b-nav-item
              :active="currentChart === 'preview'"
              @click="updateChartType('preview')"
              >Preview</b-nav-item
            >
          </b-nav>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <newdashboard
            v-if="currentChart == 'dashboard'"
            :key="reloadChart"
          ></newdashboard>
          <preview
            v-if="currentChart == 'preview'"
            :key="reloadChart"
          ></preview>

          <by-listing-datatable
            v-else-if="currentChart === 'listing'"
            :key="reloadChart"
          />
          <by-location-datatable
            v-else-if="currentChart === 'location'"
            :key="reloadChart"
          />
       <by-date-datatable
            v-else-if="currentChart === 'date'"
            :key="reloadChart"
          />

          <by-email-leads-datatable
              v-else-if="currentChart === 'email-leads'"
              :key="reloadChart"
          />
          <!--        <by-type-datatable
                 v-else-if="currentChart === 'type'"
                 :key="reloadChart"
               /> -->
          <by-type-datatable
            v-else-if="currentChart === 'preview'"
            :key="reloadChart"
          />
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import api from '@/api';
import Typeahead from '@/components/Typeahead';
import ByListingDatatable from '../components/Reports/ClientStats/ByListingDatatable';
import ByDateDatatable from '../components/Reports/ClientStats/ByDateDatatable';
import ByLocationDatatable from '../components/Reports/ClientStats/ByLocationDatatable';
import ByEmailLeadsDatatable from '../components/Reports/ClientStats/ByEmailLeadsDatatable';

//import ByTypeDatatable from '../components/Reports/ClientStats/ByTypeDatatable';
import Preview from '../components/Preview';
import DateRange from '@/components/DateRange.vue';
import dateFormat from 'dateformat';
import Newdashboard from '@/components/NewDashboard';

const DEFAULT_SELECTED_REP = { name: null };
const DEFAULT_SELECTED_CLIENT = { name: null };

window.addEventListener('popstate', () => {
  window.popStateDetected = true;
});

export default {
  name: 'ReportsDetails',
  components: {
    ByListingDatatable,
    ByLocationDatatable,
    ByDateDatatable,
    ByEmailLeadsDatatable,
   // ByTypeDatatable,
    Typeahead,
    DateRange,
    Newdashboard,
    Preview,
  },
  data() {
    return {
      firstLoad: true,
      isBusy: false,
      disabled: false,
      reps: [],
      clientsFullList: [],
      clients: [],
      filter: null,
      currentChart: 'dashboard',
      intID: 9,
      selectedRep: DEFAULT_SELECTED_REP,
      selectedClient: DEFAULT_SELECTED_CLIENT,
      date: '',
      startDate: this.alterDate(new Date(), -7),
      endDate: this.alterDate(new Date(), -1),
      reloadChart: 0,
      langOptions: [
        { text: 'EN', value: 'en' },
        { text: 'FR', value: 'fr' },
      ],
      langSelected: '',
    };
  },
  methods: {
    dateMedium(date) {
      return dateFormat(date, 'yyyy-mm-dd', true);
    },
    refreshTable() {
      this.$refs.table.refresh();
    },
    updateUrl() {
      this.$router
        .push({
          query: {
            rep: this.selectedRep.intRepID,
            client: this.selectedClient.intClientID,
            startDate: this.startDate,
            endDate: this.endDate,
            selectedLanguage: this.langSelected,
            selectedTab: this.currentChart,
            templateId: this.templateId,
          },
        })
        .catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes(
              'Avoided redundant navigation to current location',
            )
          ) {
            console.log(err);
          }
        });
    },
    setClientStore() {
      this.$store.dispatch('client/setDate', {
        clientId: this.selectedClient.intClientID,
        clientName: this.selectedClient.strClientName,
        startDate: this.startDate,
        endDate: this.endDate,
        selectedLanguage: this.langSelected,
        requestParams: this.requestParams(),
        templateId: this.templateId,
      });
    },
    chartSubmit() {
      this.updateChartType(this.currentChart);
      this.updateUrl();
    },
    updateChartType: function (chartType) {
      this.currentChart = chartType;
      this.firstLoad = false;
      this.disabled = true;
      this.setClientStore();
      this.forceRerender();
      this.updateUrl();
    },
    updateLanguage() {
      this.firstLoad = false;
      this.disabled = true;
      this.setClientStore();
      this.forceRerender();
      this.updateUrl();
    },
    toSlug(value) {
      // convert any contiguous non alphanumeric chars to a single dash
      // and then remove any leading/trailing dashes
      return value.replace(/[^a-z0-9]+/gi, '-').replace(/^-+|-+$/g, '');
    },
    requestParams() {
      return {
        page: this.currentPage,
        perPage: this.perPage,
        intClientID: this.selectedClient.intClientID,
        dtStartDate: this.startDate,
        dtEndDate: this.endDate,
        selectedLanguage: this.langSelected,
        locationId: this.locationId,
        archivedStatus: this.archivedStatus,
        chartType: this.currentChart,
        templateId: this.templateId,
      };
    },
    resetTable() {
      this.items = [];
    },
    setRep(rep) {
      this.selectedRep = rep;
      this.clients = this.clientsFullList.filter(
        c => c.intRepID === this.selectedRep.intRepID,
      );
      this.disabled = false;
    },
    clearRep() {
      this.selectedRep = DEFAULT_SELECTED_REP;
      this.clients = this.clientsFullList;
      this.clearClient();
    },
    setClient(client) {
      if (this.selectedRep.name === null) {
        this.selectedRep = this.reps.filter(
          r => r.intRepID === client.intRepID,
        )[0];
      }
      this.selectedClient = client;
      this.disabled = false;
    },
    clearClient() {
      this.selectedClient = DEFAULT_SELECTED_CLIENT;
      this.disabled = false;
      this.clearRep();
      this.resetTable();
    },
    async getClientsReps() {
      let clientsReps = await api.getClientsReps();
      this.reps = Object.values(clientsReps.reps).sort((a, b) => {
        return `${a.strFullName}`
          .trim()
          .localeCompare(`${b.strFullName}`.trim());
      });
      this.clientsFullList = this.clients = Object.values(
        clientsReps.clients,
      ).sort((a, b) => {
        return `${a.strClientName}`
          .trim()
          .localeCompare(`${b.strClientName}`.trim());
      });
      this.urlCheck();
    },
    alterDate(date, days) {
      let copy = new Date(Number(date));
      copy.setDate(date.getDate() + days);
      copy.setHours(0, 0, 0, 0);
      return this.dateMedium(copy);
    },
    updateDateRange(e) {
      this.startDate = e.from.value;
      this.endDate = e.to.value;
      this.disabled = false;
    },
    forceRerender() {
      this.reloadChart += 1;
    },
    urlCheck() {
      if (this.$attrs.rep) {
        this.selectedRep = this.reps.filter(
          r => r.intRepID === parseInt(this.$attrs.rep),
        )[0];
      }
      if (this.$attrs.client) {
        let selectedClient = this.clientsFullList.filter(
          c => c.intClientID === parseInt(this.$attrs.client),
        );
        this.selectedClient = selectedClient[0];
      }
      if (this.$attrs.startDate) this.startDate = this.$attrs.startDate;
      if (this.$attrs.endDate) this.endDate = this.$attrs.endDate;
      if (this.$attrs.selectedTab) this.currentChart = this.$attrs.selectedTab;
      if (this.$attrs.templateId) this.templateId = this.$attrs.templateId;
      if (
        this.$attrs.rep &&
        this.$attrs.client &&
        this.$attrs.startDate &&
        this.$attrs.endDate
      ) {
        this.chartSubmit();
      }
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  created() {
    this.getClientsReps();
  },
  mounted() {
    if (localStorage.reportsLanguage) {
      this.langSelected = localStorage.reportsLanguage;
    } else {
      this.langSelected = this.$i18n.locale;
    }
  },
  watch: {
    langSelected(newLang) {
      localStorage.reportsLanguage = newLang;
      this.$i18n.locale = newLang;
      this.setClientStore();
    },
    $route(to) {
      if (window.popStateDetected === true) {
        this.$router.go(to.fillPath);
        window.popStateDetected = false;
      }
    },
  },
};
</script>
<style lang="scss">
.listingID {
  background-color: #ff856c;
}

.section {
  background-color: #eee5cf;
}

.wideToolTip,
.wideToolTip .tooltip-inner {
  width: 400px;
  max-width: 400px;
}

.mediumToolTip,
.mediumToolTip .tooltip-inner {
  width: 300px;
  max-width: 300px;
}
</style>
