export default {
  namespaced: true,

  state: () => ({
    cancelTokens: [],
  }),

  getters: {
    cancelTokens: state => state.cancelTokens,
  },

  mutations: {
    addCancelToken(state, token) {
      state.cancelTokens.push(token);
    },
    clearCancelTokens(state) {
      state.cancelTokens = [];
    },
  },

  actions: {
    cancelPendingRequests({ commit, state }) {
      // Cancel all request where a token exists
      state.cancelTokens.forEach(request => {
        if (request.cancel) {
          request.cancel();
        }
      });

      // Reset the cancelTokens store
      commit('clearCancelTokens');
    },
  },
};
