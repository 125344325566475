<template>
  <div class="text-center mb-lg-2 mt-2 border border-dark rounded">
    <b-row class="bg-primary text-white font-weight-bold pt-2 pb-2 m-0"
      ><b-col
        >{{ $t('engagement.title') }}
        <span v-b-tooltip.hover="tooltipData" class="float-right"
          ><b-icon-info-circle></b-icon-info-circle></span></b-col
    ></b-row>
    <b-row class="bg-light pt-3 pb-3 m-0">
      <b-col>
        <p>
          {{ $t('engagement.total') }}:
          <strong>{{ formatNumber(leads.totalLeads) }}</strong>
        </p>
        <p>
          {{ $t('engagement.email_leads') }}:
          <a :href="emailLeadsLink">
          <strong>{{ formatNumber(leads.emailLeads) }}</strong>
          </a>
        </p>
        <p class="mb-0">
          {{ $t('engagement.phone_clicks') }}:
          <strong>{{ formatNumber(leads.phoneLeads) }}</strong>
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    leads: { required: true },
  },
  data() {
    return {
      tooltipData: {
        title:
          '<p>Total → Sum of the email leads and phone clicks.</p><p>Email Leads → Number of email leads.</p><p>Phone Clicks → Number of phone clicks.</p>',
        html: true,
        placement: 'right',
        variant: 'secondary',
      },
      emailLeadsLink : '',
    };
  },
  methods: {
    formatNumber: value => parseInt(value).toLocaleString(),
    changeURL(){
      const currentURL = window.location.href;
      const emailLeadsURL = currentURL.replace(/selectedTab=[^&]*/, 'selectedTab=email-leads');
      this.emailLeadsLink = emailLeadsURL;
    }
  },
mounted(){
  this.changeURL();
},
};
</script>
