import { createServer, Model } from 'miragejs';
import seedReports from './seeds/reports';

export default ({ environment = 'development' } = {}) => {
  return createServer({
    environment,
    models: {
      report: Model.extend({
        slug: String,
        name: String,
        data: Array,
      }),
    },
    seeds(server) {
      seedReports(server);
    },
    routes() {
      // For some reason namespace doesn't seem to apply to passthrough
      const apiBase = process.env.VUE_APP_AGD_ADMIN_API_BASE;

      this.namespace = apiBase;

      this.get(`${apiBase}/api/reports/:slug`, (schema, request) => {
        const { slug } = request.params;

        return schema.db.reports.findBy({ slug });
      });

      this.passthrough(`${apiBase}/**`);
      this.passthrough(
        `${apiBase}/api/reports/client/by-listing`,
        `${apiBase}/api/reports/client/by-location`,
        `${apiBase}/api/reports/client/by-date`,
        `${apiBase}/api/reports/client/by-summary`,
        `${apiBase}/api/reports/client/summary`,
        `${apiBase}/api/reports/client/top-five`,
      );
    },
  });
};
