<template>
  <div>
    <p class="bg-light text-dark mb-0 p-2 text-center font-weight-bold">
      {{ $t('topfive.title') }}
      <span v-b-tooltip.hover="tooltipData" class=""
        ><b-icon-info-circle></b-icon-info-circle
      ></span>
    </p>
    <b-table
      responsive
      striped
      hover
      :fields="fields"
      :items="itemsProvider"
      class="font-12"
      show-empty
    >
      <template #empty="scope">
        <p class="text-center font-weight-bold text-danger">
          {{ scope.emptyText }}
        </p>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(intListingID)="data">
        <a
          v-if="!data.item.blnArchive"
          :href="`https://www.agdealer.com/${data.value}`"
          target="_blank"
          rel="external"
          >{{ data.value }}</a
        >
        <a v-else>{{ data.value }}</a>
      </template>
      <template #head()="data">
        <span>{{ $t('topfive.' + data.label) }}</span>
      </template>
      <template #head(intSrpViews)="data">
        <span
          >{{ $t('topfive.' + data.label) }}
          <b-icon-info-circle id="sprViews"></b-icon-info-circle>
          <b-tooltip target="sprViews" triggers="hover"
            >Number of search page views for this listing.</b-tooltip
          ></span
        >
      </template>
      <template #head(intDetailViews)="data">
        <span
          >{{ $t('topfive.' + data.label) }}
          <b-icon-info-circle id="detailViews"></b-icon-info-circle>
          <b-tooltip target="detailViews" triggers="hover"
            >Number of detail views for this listing.</b-tooltip
          ></span
        >
      </template>
    </b-table>
  </div>
</template>

<script>
import api from '../../api/index';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      fields: [
        { key: 'intListingID', label: 'agDealer_#' },
        {
          key: 'intStockNumber',
          label: 'stock_#'
        },
        { key: 'intYear', label: 'year', formatter: this.formatNumber },
        { key: 'strLocationName', label: 'location_name' },
        { key: 'strTypeName', label: 'type_name' },
        { key: 'strManufacturerName', label: 'manufacturer_name' },
        { key: 'strModelName', label: 'model_name' },
        {
          key: 'intDetailViews',
          label: 'detail_page_views',
          formatter: this.formatCount,
        },
        {
          key: 'intSrpViews',
          label: 'search_page_views',
          formatter: this.formatCount,
        },
        {
          key: 'intEmailLeads',
          label: 'email_leads',
          formatter: this.formatCount,
        },
        {
          key: 'intPhoneCallLeads',
          label: 'phone_clicks',
          formatter: this.formatCount,
        },
        {
          key: 'intDaysListed',
          label: 'days_listed',
          formatter: this.formatCount,
        },
      ],
      tooltipData: {
        title:
          '<p>AgDealer # → AgDealer listing number.</p><p>Stock # → Dealer’s stock number.</p><p>Year → Year of the listing item.</p><p>Location Name → Dealer location for the listing item.</p><p>Type Name → Type of the listing item.</p><p>Manufacturer Name → Manufacturer for the listing item.</p><p>Model Name → Model name of the listing item.</p><p>Detail Page Views → Number of detail views for this listing.</p><p>Search Page Views → Number of search page views for this listing.</p><p>Email Leads → Number of email leads for this listing.</p><p>Phone Clicks → Number of phone clicks for this listing.</p><p>Days Listed → Total days listed for this listing.</p>',
        html: true,
        placement: 'right',
        variant: 'secondary',
        customClass: 'mediumToolTip',
      },
    };
  },
  methods: {
    formatNumber: value => (value ? parseInt(value) : ''),
    formatCount: value => (value ? parseInt(value).toLocaleString() : 0),
    async itemsProvider() {
      const items = await api.getReportsClientByListing({
        intClientID: this.clientId,
        dtStartDate: this.startDate,
        dtEndDate: this.endDate,
        pageNumber: 1,
        perPage: 5,
        selectedLanguage: this.selectedLanguage,
      });

      return items.data;
    },
  },
  computed: {
    ...mapState('client', [
      'startDate',
      'endDate',
      'clientId',
      'selectedLanguage',
    ]),
  },
};
</script>
