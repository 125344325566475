<template>
  <typeahead
    :label="label"
    :selectedItem="selectedItem"
    :items="items"
    :item-id-key="itemIdKey"
    :disable-input="disableInput"
    @set-query="handleSetItem"
    @clear-query="handleClearItem"
    :min-query-length="0"
    :show-item-id="true"
  />
</template>

<script>
import Typeahead from '@/components/Typeahead';
import { mapGetters } from 'vuex';

export default {
  name: 'TypeTypeahead',
  components: { Typeahead },
  props: {
    label: {
      type: String,
      default: 'Type',
    },
    initItems: {
      type: Array,
      default: null,
    },
    initSelectedItem: {
      type: Object,
      default: () => ({ name: null }),
    },
    itemIdKey: {
      type: String,
      default: 'intID',
    },
    disableInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: this.initItems,
      selectedItem: this.initSelectedItem,
    };
  },
  created() {
    if (null === this.items) {
      this.items = this.getTypesSorted;
    }
  },
  computed: {
    ...mapGetters('models', {
      getTypesKeyedById: 'getTypesKeyedById',
      getTypesSorted: 'getTypesSorted',
    }),
  },
  methods: {
    handleSetItem(item) {
      this.$emit('set-item', item);
    },
    handleClearItem() {
      this.$emit('set-item', null);
    },
  },
};
</script>
