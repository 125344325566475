import api from '@/api';
import { nameSorter, keyById } from '@/common/helperFunctions';

export default {
  namespaced: true,

  state: () => ({
    manufacturers: [],
    types: [],
    clients: [],
    reps: [],
  }),

  getters: {
    getManufacturersKeyedById: state => keyById(state.manufacturers),
    getManufacturerById: (state, getters) => mfId => {
      return getters['getManufacturersKeyedById'][mfId] || null;
    },
    getTypesKeyedById: state => keyById(state.types),
    getTypeById: (state, getters) => typeId => {
      return getters['getTypesKeyedById'][typeId] || null;
    },
    getClientsKeyedById: state => keyById(state.clients),
    getClientById: (state, getters) => clientId => {
      return getters['getClientsKeyedById'][clientId] || null;
    },
    getRepsKeyedById: state => keyById(state.reps),
    getRepById: (state, getters) => repId => {
      return getters['getRepsKeyedById'][repId] || null;
    },
    getTypesSorted: state => state.types.sort(nameSorter),
    getMfsSorted: state => state.manufacturers.sort(nameSorter),
    getClientsSorted: state => state.clients.sort(nameSorter),
    getRepsSorted: state => state.reps.sort(nameSorter),
  },

  actions: {
    async loadManufacturers({ commit, state }) {
      return state.manufacturers.length
        ? true
        : api.getUnmatchedFacetsManufacturers().then(data => {
            commit('setManufacturers', data);
          });
    },
    async loadTypes({ commit, state }) {
      return state.types.length
        ? true
        : api.getUnmatchedFacetsTypes().then(data => {
            commit('setTypes', data);
          });
    },
    async loadClients({ commit, state }) {
      return state.clients.length
        ? true
        : api.getUnmatchedFacetsClients().then(data => {
            commit('setClients', data);
          });
    },
    async loadReps({ commit, state }) {
      return state.reps.length
        ? true
        : api.getUnmatchedFacetsReps().then(data => {
            commit('setReps', data);
          });
    },
  },

  mutations: {
    setManufacturers(state, payload) {
      state.manufacturers = payload;
    },
    setTypes(state, payload) {
      state.types = payload;
    },
    setClients(state, payload) {
      state.clients = payload;
    },
    setReps(state, payload) {
      state.reps = payload;
    },
  },
};
